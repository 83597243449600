import React, { useState } from "react";
// import { Col, Row } from "react-bootstrap";
import { JoinButtonstyle } from "./styles";
import { Modal } from "react-bootstrap";

const ConfirmationBox = ({
  onChangeVisible,
  isVisible,
  auctionData,
  onJoinAuction,
}) => {
  const handleClose = () => onChangeVisible(false);
  const [couponCode, setCouponCode] = useState("");
  const IsTokenAvailable = localStorage.getItem("Token");
  const handleJoinAuction = () => {
    onJoinAuction(couponCode);
    handleClose();
  };
  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="congratulationspop"
    >
      <JoinButtonstyle>
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          <div className="container text-center cong-inner-content outer-wrapper">
            <p>
              Are you sure you want to join Auction &nbsp;
              <span style={{ color: "#53BA64" }}>
                <b>{auctionData?.name} </b>
              </span>
            </p>

            <div className="row justify-content-center mb-3">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Use coupon code here..."
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
              </div>
            </div>

            {/* {IsTokenAvailable && (
              <div className="row justify-content-center mb-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Use coupon code here..."
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                </div>
              </div>
            )} */}

            <div className="join-auction">
              <button
                type="button"
                className="btn btn-primary btn-filled no-btn"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary btn-filled yes-btn"
                data-bs-dismiss="modal"
                // onClick={onJoinAuction}
                onClick={handleJoinAuction}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </JoinButtonstyle>
    </Modal>
  );
};

export default ConfirmationBox;
