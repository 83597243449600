import styled from 'styled-components';


export const CareersStyle = styled.section`

     .about-wapper{
          background-color: #E7EAF0;
     }


`;