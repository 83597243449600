import styled from "styled-components";

export const NFTStyle = styled.section`
  .nft-main-wapper {
    background-color: #fff;

    .page-title-wapper {
      background-color: #edf9fe;
      padding: 2rem 0px;
    }
  }
`;
