import styled from 'styled-components';


export const FormsSidebarStyle = styled.section`

     .form-sidebar-wapper{
          .card{
               border-radius: 0;
               background-color: #1E5CAC;
               border: 0;
               .card-body{
                    padding: 40px;
                    padding-right:0px;
                    padding-bottom: 0px;
                    border: 0;
                    .card-title{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 900;
                         font-size: 34px;
                         line-height: 43px;
                         color: #53A9FF;
                         padding-right: 30px;
                    }
                    .card-text{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-size: 32px;
                         line-height: 40px;
                         color: #FFFFFF;
                         margin-top: 3rem;
                         padding-right: 20px;
                         
                         strong{
                              font-weight: 900;
                         }
                    }
                    .card-img{
                         width: 100%;
                    }
               }
          }
     }


`;