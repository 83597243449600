import React, { useCallback, useEffect, useState } from "react";
import { DashboardAuctionsCardStyle } from "./DashboardAuctionsCardStyle";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Button, Col, Row } from "react-bootstrap";
import socket from "../../../../socket";
import ExportApi from "../../../../Api/ExportApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const DashboardAuctionsCard = ({ userId }) => {
  const [data, setData] = useState();
  const [duration, setDuration] = useState();
  const [isLoading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(true)
  const [joinUser, setJoinUser] = useState()
  const profile = useSelector((state) => state?.APIData?.Profiledata?.due_payment)

  const Navigate = useNavigate();

  useEffect(() => {
    socket.on("liveAuctionData", (val) => {
      const users = val?.joinedUsers;
      setJoinUser(users.find(id => id == userId));
        setData(val);
        setDuration(val.duration)
    });
    return () => {
      socket.disconnect()
    }
  }, []);

  const onCheckHandler = () => {
    Navigate("/addfunds", {
      state: {
        amount: profile[0].winning_price,
        auction_id: profile[0].auction_id,
        currency_name: profile[0].currency_name,
        currencySymbol: profile[0].custom_currency_symbol,
        vat_price: profile[0].vat_price,
        total_price: profile[0].total_price,
      },
    })
  }
  
  const remainingTimeLeft = () => {
    const minutes = Math.floor(data?.dropTimer / 60);
    const seconds = data?.dropTimer % 60;
    return playing ? `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}` : "00:00";
  };

  const handleBuy = useCallback(() => {
    setLoading(true)
    ExportApi.CheckToken()
    .then((resp) => {
      if(resp.status != 200) {
        socket.disconnect()
        localStorage.removeItem("Token");
        localStorage.removeItem("User_ID");
        window.dispatchEvent(new Event("EventLength"));
        window.location.reload()
        Navigate("/login");
      }
  else {
    socket.emit("sendBuyData", {
      targetSocketId: socket.id,
      userID: userId,
      winning_price: data?.Price_display,
      discount: data?.Discount_sec,
      time: new Date(),
      massage: "Connected",
      getWinner: 1,
      Price: data?.Price,
      totalNumberOfItem: data?.totalNumberOfItem,
      duration: data?.dropTimer,
      auctionId: data?.auctionId,
      totalDuration: data?.duration,
    });
    setPlaying(false)

//handle settimeout to deal with screen freez issue 
    setTimeout(() => {
     window.location.reload(false);
    }, 2000);
  }
})
.catch((err) => console.log(err));
  }, [data]);

  return (
    <>
      <DashboardAuctionsCardStyle>
        <div className="dashboard-auctions-card-wapper">
          <div className="dashboard-auctions-card-title">
            <Icon icon="ic:round-circle" />
            Live Auction
          </div>
          <div className="dashboard-auctions-card">
            <Row>
              <Col md={12} lg={6}>
                <div className="dashboard-auctions-card-image">
                  <img
                    className="dashboard-auctions-card-img"
                    src={`https://yolop.net/admin/public/images/auctions/${data?.auctionImages}`}
                    loading="lazy"
                  />
                </div>
                <div className="dashboard-auctions-card">
                  <Row className="mid-section">
                    <Row className="dashboard-auctions-title-inner">
                      <div className="dashboard-auctions-title">
                      <Col md={6} lg={6}><span>{"Name"}</span> : </Col>
                      
                      <Col md={6} lg={6}>
                      {data?.auctionName}
                      </Col>
                      </div>
                    </Row>
                    <Row  className="dashboard-auctions-title-inner">
                      <div className="dashboard-auctions-title">
                      <Col md={6} lg={6}>
                      <span>{"Starting Bid"} : </span>
                        </Col>
          
                      <Col md={6} lg={6}>
                        <strong>{data?.auctionCurrency} {data?.Price}</strong>
                        </Col>
                      
                      </div>
                    </Row>
                  </Row>
                  <Row className="mid-section">
                    <Row className="dashboard-auctions-title-inner">
                      <div className="dashboard-auctions-title">
                      <Col md={6} lg={6}><span> {"Items on Offer"} </span> : </Col>
                      <Col md={6} lg={6}>{data?.totalNumberOfItem}</Col>
                      </div>
                    </Row>
                    <Row className="dashboard-auctions-title-inner">
                      <div className="dashboard-auctions-title">
                      <Col md={6} lg={6}><span> {"Items Left "} </span> : </Col>
                      <Col md={6} lg={6}>{data?.getTotalWinner ? data?.totalNumberOfItem-data?.getTotalWinner : data?.totalNumberOfItem}</Col>
                      </div>
                    </Row>
                  </Row>
                </div>
              </Col>

              <Col>
                <div className="dashboard-auctions-card-graph">
                  <div className="card-graph-inner">
                    <p>Auction Ending in </p>

                   { duration && <CountdownCircleTimer
                      isPlaying={playing}
                      duration={duration}
                      initialRemainingTime={data.dropTimer}
                      trailColor={ data?.auctionColor ? data?.auctionColor : "#EDF9FE"  }
                      colors={"#d3d3d3" }
                    >
                      {remainingTimeLeft}
                    </CountdownCircleTimer>}
                  </div>
                </div>
                <div className="dashboard-auctions-card-time ">
                  <Row>
                    <Col>
                      <div className="current-price mt-2">
                        <div className="current-price-content">
                          <span>Current Price</span>
                          <h2>
                            <span className=" auction-price">
                            {data?.auctionCurrency} {data?.Price_display.toFixed(2)}
                            </span>
                          </h2>
                        </div>
                        <div className="current-price-icon">
                          <Icon icon="bi:arrow-down" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="discount-price mt-2">
                        <div className="current-price-content">
                          <span>Discount</span>
                          <h2>{Math.floor(data?.Discount_sec)}% </h2>
                        </div>
                        <div className="current-price-icon">
                          <Icon icon="bi:arrow-up" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="dashboard-auctions-card-button mt-2">
                {joinUser && profile?.length <= 0 &&
                 <Button 
                  onClick={!isLoading ? handleBuy : null} 
                  variant="primary"
                  disabled={isLoading}
                  >
                   {!isLoading ? 'BUY NOW' : 'Loading…'}
                  </Button>}
                      
                   {profile?.length > 0 && <p className="pending-payment">To participate in the upcoming auction, please ensure that the payment for the previous auction is settled. Kindly
                    <span onClick={onCheckHandler}> Click here </span>
                    to clear your outstanding dues.</p>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </DashboardAuctionsCardStyle>

      {/* <CompleteAuction winingBid={auctionPrice} /> */}
    </>
  );
};

export default DashboardAuctionsCard;
