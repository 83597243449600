import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.css";
const PageNotFound = () => {
  return (
    <div className="main">
      <div className="omtfound">
        <h1> 404</h1>
      </div>
      <h2 className="line">Oops! Page not Found</h2>
      <p className="lineTo">
        The page you are loooking for might have been removed had
        <br />
        its name changed or it temporarilty unavailable.
      </p>
      <div className="btnlink">
        <Link to="/" className="">
          <Button>Go To Home</Button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
