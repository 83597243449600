import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container, Row, Col} from "react-bootstrap";
import {ReferEarnHomeStyle} from './ReferEarnHomeStyle';
import PageTitle from '../../Components/common/PageHeading/PageHeading';
import MetaTags from "../../Components/common/MetaTags";

function ReferEarnHome() {
  return (
    <ReferEarnHomeStyle>
      <MetaTags
      title={'Yolop Refer and Earn - Share and Earn Rewards with Our Referral Program'}
      description={"Join Yolop's Refer and Earn program to start earning rewards by referring your friends. Share the thrill of online bidding and get rewarded for each successful referral. Sign up now and start earning!"}
      focusKeyphrase={'Refer and Earn'}
              canonicalURL={'https://yolop.net/refer-and-earn'}
/>
      <div className='refer-wapper'>  
        <div className="refer-wapper-heading pt-4 pb-3">
          <PageTitle
            pagetitle={'Refer and Earn'}
          />
        </div>  
    </div>

    <Container className='content-wrapper'>
    <Row className='row-wrapper'>
    <Col sm={12} md={7}  className="text-sec">
    <h2>Refer And Earn</h2>
    <p>Get Ready To Bid, Win, & Earn With <span>Yolop!</span></p>
    </Col>

    <Col sm={12} md={5}  className="img-sec">
                      <img className="refer-img" src="/images/refer&earntopimage.png" alt="" loading="lazy" />
    </Col>

    </Row>

    <Row className='row-wrapper-1'  >
    <Col sm={12} md={4}  className="img-sec-1">
    <img className="refer-img" src="/images/invite.png" alt="" />

    </Col>

    <Col sm={12} md={8} lg={8}  xl={8} xxl={4} className="text-sec-1">
    
    <h2>Invite</h2>
    <p>Invite your friends and family to join Yolop’s reverse-bidding auction platform with your unique referral link you will get on signing up with Yolop. Invite as many people as you want and earn rewards with each successful referral. The more you invite, the more you earn!</p>

    </Col>

    </Row>


    <Row className='row-wrapper-2'>
   
    <Col sm={12} md={0} lg={0}  xl={0} xxl={4}  className=""></Col>
   
    <Col sm={12} md={8} lg={8}  xl={8} xxl={4}   className="text-sec-2 change-order">
    <h2>Share</h2>
    <p>Share your referral link on different social media platforms, e mails, or through text messages to maximize  your referrals and earrings. Your referrals will get a chance to bid on exclusive commodities and win big just like you. It's a win-win situation for both you and your referrals!</p>

    </Col>

    <Col sm={12} md={4}  className="img-sec-2">
                      <img className="share-img" src="/images/share.png" alt="" loading="lazy" />

    </Col>

    </Row>

    
    <Row className='row-wrapper-3' >
    <Col sm={12} md={4}  className="img-sec-3">
                      <img className="refer-img" src="/images/earnrewards.png" alt="" loading="lazy" />

    </Col>

    <Col sm={12} md={8} lg={8}  xl={8} xxl={4} className="text-sec-3">
    
    <h2>Earn Rewards</h2>
    <p>For every friend that joins Yolop’s auction platform through your referral code, you earn a cash reward. Your wallet will be credited with 5% of the first payment that your referral makes while participating in the first auction. You can use your rewards to bid on exclusive commodities.</p>

    </Col>

    </Row>


  </Container>
    </ReferEarnHomeStyle>
  );
}

export default ReferEarnHome;
