import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { DownloadWeightStyle } from "./DownloadWeightStyle";

function DownloadWeight() {
  return (
    <DownloadWeightStyle>
      <div className="button-wapper">
        <div className="button-ios">
            <a href="https://apps.apple.com/in/app/yolop/id6447545865" target="_blank">
          <Button variant="dark">
            <Icon icon="ant-design:apple-filled" />
            <div className="button-caption">
           
              <span>Download On the </span>
              <strong>App Store</strong>
             
            </div>
          </Button> </a>
        </div>
        <div className="button-android">
        <a href="https://play.google.com/store/apps/details?id=com.yolopapp" target="_blank">
          <Button variant="dark">
            <Icon icon="ci:play-store" />
            <div className="button-caption">
              <span>Get On </span>
              <strong>Google Play</strong>
            </div>
          </Button></a>
        </div>
      </div>
    </DownloadWeightStyle>
  );
}

export default DownloadWeight;
