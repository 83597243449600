import React from "react";
/* eslint-disable eqeqeq */
import { Container, Stack, Breadcrumb } from "react-bootstrap";
import { PageHeadingStyle } from "./PageHeadingStyle";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

interface PageTitleProps {
  pagetitle: string;
  //   pageslug: string;
}

function PageTitle({ pagetitle }: PageTitleProps) {
  return (
    <PageHeadingStyle className="mb-4">
      <div className="page-title-wapper pb-4   ">
        <Container>
          <Stack className="section-title-inner">
            <div className="page-title pt-3">
              <h1> {pagetitle} </h1>
            </div>
            <div className="page-breadcrumb-wapper">
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <Icon icon="bi:arrow-right" />
                <Breadcrumb.Item active>{pagetitle}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Stack>
        </Container>
      </div>
    </PageHeadingStyle>
  );
}
export default PageTitle;
