
import styled from 'styled-components';

export const Wrapper = styled.section`


  .auction-start-video-wapper {
    .cstmhead {
    font-size: 2.5em;
  text-align: center;
  color: blue;
  }
}
`;

// export const Wrapper = styled.section`
//   padding: 4em;
//   background: papayawhip;
// `;