import { create, CancelToken} from 'apisauce';
const BaseApi = create({
        baseURL: 'https://yolop.net/admin/api/v1',
        headers: {Authorization: `Bearer ${ localStorage.getItem("Token")}`},
});
  // const BaseUrlImage = 'http://yolop.net/admin/api/v1'
  // const BaseUrlImage = 'http://192.168.0.101:8000'
export default  BaseApi

// import axios from "axios";

// const BaseApi=  axios.create({
//   baseURL: "http://yolop.net/admin/api/v1",
//   headers: {
//     Authorization: `Bearer ${ localStorage.getItem("Token")}`,
//     "Content-type": "application/json"
//   }
// });

// export default BaseApi;
