import styled from "styled-components";

export const PageHeadingStyle = styled.section`
    .page-title-wapper {
        background: #EDF9FE;
        .section-title-inner {
            align-items: center;

            .page-title {
                h1 {
                    text-align:center;
                    font-family: "Plus Jakarta Sans";
                    font-style: normal;
                    font-weight: 900;
                    font-size: 37px;
                    line-height: 47px;
                    color: #000000;
                }
            }
            .page-breadcrumb-wapper {
                .breadcrumb {
                    align-items: center;
                    font-family: "Plus Jakarta Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    flex-wrap: inherit;
                    display:inline-flex;
                    a {
                        text-decoration: none;
                        color: #141414;
                    }
                    svg {
                        padding-top: 5px;
                        width: 45px;
                        font-size: 25px;
                        color: #7a7a7a;
                    }
                    .active {
                        color: #0180ff;
                    }
                }
            }
        }
    }

`;
