import { Icon } from "@iconify/react";
import { ContactFormStyle } from "./ContactFormStyle";
import FormsSidebar from "../../common/FormsSidebar/FormsSidebar";
import React, { useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import {Container,Row,Col,Form,Button,InputGroup,Modal,} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import { loader } from "../../../loader";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { TimeZone } from "../../common/DateFormat/DateFormate";
import { useSelector } from "react-redux";

const ContactForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [Error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [value, setValue] = useState();
  const [valueError, setValueError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  let Navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!formik.isValid) {
      toast.error("please check all the fields", {
        toastId: "signup-form-email",
      });
    }
   else if(!value){
      toast.error("country code required", {
        toastId: "signup-form-email",
      });
    }else {
      formik.handleSubmit();
      setValidated(true);
    }
  };


  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      email: "",
      mobile: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required"),
      email: Yup.string().email('Invalid Email!').required('Email address is required!').trim()
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter valid email address!'),

      mobile: Yup.string().required("Phone Number Required")
        .matches(/^\d{4,15}$/, "Only 4-15 Digits Required"),
        // .required("Phone number is required"),

        subject: Yup.string().required("Subject Required"),
        message: Yup.string().required("Message Required"),
    }),

    onSubmit: (values) => {
      ExportApi.contactUs(
        values.name, 
        value, 
        values.mobile, 
        values.email,
        values.subject,
        values.message, 
        values.company
      )
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          toast.success(res.data.message, {
            toastId: "contact-us",
          });
        }
        else {
          toast.error("Something went wrong", {
            toastId: "contact-us",
          });
        }
      })
      .catch((err) => console.log(err));
      }
  });
  return (
    <ContactFormStyle>
      <div className="contact-form-wapper pb-5">
        <Container>
          <Row>
            <Col className="form-sidebar" sm={5}>
              <div className="form-sidebar-inner">
                <h2>Get In Touch</h2>
                <h3>Yolop Ltd.</h3>
                <div className="contact-details">
                <h4>Address</h4>
                <p>128 City Road, London, ECIV 2NX, United Kingdom</p>

                <h4>Call</h4>
                <p>+44 7342277524</p>

                <h4>Chat with us</h4>
                <p>info@yolop.net</p>


                </div>
              </div>
            </Col>

            <Col className="contact-form" sm={7}>
              <div className="contact-form-inner">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="6 mt-3"
                      // controlId='validationCustom01'
                    >
                      <Form.Label>
                        Name
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          required
                          type="text"
                          name="name"
                          placeholder="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                         {formik.touched.name &&
                          formik.errors.name}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6 mt-3"
                      // controlId="validationCustom02"
                    >
                      <Form.Label>
                        Company
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                               type="text"
                               name="company"
                               placeholder="company"
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.company}
                        />

                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6 mt-3"
                      // controlId="validationCustomUsername"
                    >
                      <Form.Label>
                        Email Address
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="email"
                          aria-describedby="inputGroupPrepend"
                          required
                          name="email"
                          placeholder="Email@domain.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                         {formik.touched.email &&
                          formik.errors.email}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6 mt-3 phone-input"
                      // controlId="validationCustomMobile"
                    >
                      <Form.Label>
                        Mobile
                      </Form.Label>
                      <div className="input-color">
                        <Row>
                          <InputGroup hasValidation>
                            <Col xs={3}>
                              <PhoneInput
                                className="form-control mt-1 mb-1"
                                name="CountryCode"
                                international
                                value={value}
                                onChange={setValue}
                              />
                            </Col>
                            <Col xs={9}>
                              <Form.Control
                                type="tel"
                                autoComplete="tel"
                                placeholder="078202 7292"
                                // aria-describedby="inputGroupPrepend"
                                required
                                pattern="[0-9]{15} 15"
                                maxLength="15"
                                name="mobile"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile}
                              />
                               {formik.touched.mobile &&
                          formik.errors.mobile}
                            </Col>
                          </InputGroup>
                        </Row>
                      </div>
                      <div style={{ color: "red" }}>{valueError}</div>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="12"
                    >
                      <Form.Label>
                        Subject
                      </Form.Label>
                      <InputGroup hasValidation>
                      <Form.Control
                         required
                         type="text"
                         placeholder="Subject"
                         name="subject"
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         value={formik.values.subject}
                      />
     {formik.touched.subject &&
                          formik.errors.subject}
                          </InputGroup>
                    </Form.Group>
                          <Form.Group className="textareasec mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <InputGroup hasValidation>
        <Form.Control 
                        as="textarea" 
                        rows={6}      
                        required
                         type="text"
                         placeholder="Message"
                         name="message"
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         value={formik.values.message}
                         />
                          {formik.touched.message &&
                          formik.errors.message && formik.errors.message}
                          </InputGroup>
      </Form.Group>
                  </Row>
                  <Button className="send-button mt-1" type="submit">
                    Send
                  </Button>
                  
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


    </ContactFormStyle>
  );
};

export default  ContactForm;
