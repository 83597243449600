import styled from 'styled-components';


export const LoginMainStyle = styled.section`

    .login-main-wapper{
       background-color: #E7EAF0;
        
       .page-title-wapper{
        background:#E7EAF0;
       }


       .form-sidebar{
            padding: 0px;
       }
       .login-form{
            background-color: #fff;
            padding: 40px;
        }

    }

`;  