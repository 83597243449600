import { Icon } from "@iconify/react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { UpcomingAuctionsMainStyle } from "./AuctionsMainStyle";
import AuctionsCard from "../common/AuctionsCard/AuctionsCard";
import { useEffect, useState } from "react";
import ExportApi from "../../Api/ExportApi";
import Paginations from "../common/Pagination/Paginations";
import ContentLoader, { List } from "react-content-loader";
import { useLocation } from "react-router-dom";

const UpcomingAuctionsFN = () => {
  const location = useLocation()
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState();
  const [FilterData, setFilterData] = useState();
  const [render, setRender] = useState(1);
  const [category, setCategory] = useState();
  const [HeaderData, setHeaderData] = useState([]);

  const DataFilterBySort = (value) => {
    if (value === "All") {
      let normalArr = FilterData;
      normalArr.sort((a, b) => {
        a = new Date(a.global_start_time);
        b = new Date(b.global_start_time);
        return a - b;
      });
      setFilterData(normalArr);
      setRender(render + 34);
    } else if (value == "Price low to high") {
      let normalArr = FilterData;
      normalArr.sort(({ asset_price: a }, { asset_price: b }) => {
        a = parseFloat(a);
        b = parseFloat(b);
        return a - b;
      });
      setFilterData(normalArr);
      setRender(render + 34);
    } else if (value == "Price high to low") {
      let normalArr = FilterData;
      normalArr.sort(({ asset_price: a }, { asset_price: b }) => {
        a = parseFloat(a);
        b = parseFloat(b);
        return b - a;
      });
      setFilterData(normalArr);
      setRender(render + 34);
    }
  };

  const CategoriesHeader = () => {
    ExportApi.Categories()
      .then((resp) => {
        if (resp.data) {
          setHeaderData(resp.data);
        }
      })
      .catch((err) => console.log(err));
  };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const AuctionsData = () => {
      ExportApi.Auctions(category, currentPage, itemsPerPage, timeZone) 
      .then((resp) => {
        if (resp.data) {
          setTotalCount(resp.data.meta);
          setFilterData(resp.data.data);
          setData(resp.data.data);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoader(true);
    if(location?.state?.value){
      setLoader(false);
      setData(location.state.value)
    }
    else {
    AuctionsData();
    }
    CategoriesHeader();
  }, [category, currentPage, itemsPerPage, location]);

  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <UpcomingAuctionsMainStyle>
      <div className="auctions-main-wapper pb-5">
        <Container>
          <Row>
            <Col>
              <div className="auctions-inner">
                <div className="auctions-filter mb-4">
                  <Form className="container filter-cstm">
                    <div className="filter-heading-wapper">
                      <div className="filter-heading-inner">
                        <Icon icon="system-uicons:filtering" />
                        <span>Filter</span>
                      </div>
                    </div>
                    <Form.Group className="feild-outer">
                      <Form.Label>Sort by :</Form.Label>
                      <Form.Select
                        onChange={(e) => DataFilterBySort(e.target.value)}
                      >
                        <option value="All">Newest</option>
                        <option value="Price low to high">
                          Price low to high
                        </option>
                        <option value="Price high to low">
                          Price high to low
                        </option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="feild-outer">
                      <Form.Label>Category :</Form.Label>
                      <Form.Select
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">All</option>
                        {HeaderData?.map((val, i) => {
                          return (
                            <option key={val.id} value={val.slug}>
                              {val.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="feild-outer last">
                      <Form.Label>View :</Form.Label>
                      <Form.Select
                        onChange={(e) => setItemPerPage(e.target.value)}
                      >
                        <option value="8">8</option>
                        <option value="16">16</option>
                        <option value="24">24</option>
                        <option value="32">32</option>
                      </Form.Select>
                    </Form.Group>
                  </Form>
                </div>
                <div className="auctions-list">
                  {loader ? (
                    <div className="row">
                      {["1", "2", "3", "4", "5", "6"]?.map((variant, index) => (
                        <div className="col-sm-4" key={index}>
                          <ContentLoader style={{ width: "100%" }} height={275}>
                            <rect
                              y="17"
                              rx="4"
                              ry="4"
                              width="100%"
                              height="275"
                            />
                          </ContentLoader>
                          <List className="mt-3" />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <AuctionsCard AuctionsData={data} itemRow={4} />
                    </>
                  )}
                  {/* {data ? /> : null} */}
                </div>
                <div className="pagination-wapper mt-5 mb-5">
             {(totalCount?.total > itemsPerPage) && (!location?.state?.value) && <Paginations
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalCount?.total}
                    paginate={paginate}
                  />}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </UpcomingAuctionsMainStyle>
  );
};

export default UpcomingAuctionsFN;
