import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container } from "react-bootstrap";
import PageHeading from "../../Components/common/PageHeading/PageHeading";
import { PackageStyle } from "./PackagesStyle";
import PackagesFN from "../../Components/Dashboard/Packages/Packages";
import MetaTags from "../../Components/common/MetaTags";

function PackageshomeFN() {
  return (
    <PackageStyle>
      <MetaTags
      title={'Yolop - Explore Our Exciting Packages for Exclusive Auction Deals'}
      description={"Discover our range of exciting packages at Yolop, offering exclusive auction deals on designer bags, accessories, and more. Choose the package that suits your style and budget, and get ready to bid on coveted luxury items."}
      focusKeyphrase={'Our Exciting Packages'}
              canonicalURL={'https://yolop.net/our-packages'}
/>
      <div className="packages-wapper">
        <div className="">
          <PageHeading pagetitle={`Yolops`} />
        </div>
        <Container className="inner-wrapper">
       <PackagesFN />
        </Container>
      </div>
    </PackageStyle>
  );
}

export default PackageshomeFN;
