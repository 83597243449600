import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HistoryTableStyle } from "./HistoryTableStyle";

const AuctionChargesHistory = (props) => {
  const transaction = props?.data;

  return (
    <>
      {transaction?.length <= 0   || !transaction ? (
        <h1 class="text-center">No data found</h1>
      ) : (
        <HistoryTableStyle>
          <div className="history-table-wapper">
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Fee Charged</th>
                  <th>Participation Fee</th>
                </tr>
              </thead>
              <tbody>
                {transaction?.map((i, _index) => {
                  return (
                    <tr key={i.auction_id}>
                      <td className="date">{i?.date_time}</td>
                      <td className="transaction-name">{i?.auction_name}</td>
                      <td className="amount">
                        <strong>
                          {i.view_currency} {i?.auction_fees}
                        </strong>
           
                      </td>
                      <td className="amount">
                        <strong>
                          {i.view_currency} {i?.participation_fees}
                        </strong>
                 
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </HistoryTableStyle>
      )}
    </>
  );
};


export default AuctionChargesHistory;
