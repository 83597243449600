import * as React from "react";

import "./HomeStyle.css";
import "bootstrap/dist/css/bootstrap.css";

import HomeMain from "../../Components/Home/HomeMain";
import MetaTags from "../../Components/common/MetaTags";

const App = () => {


  return (
    <div className="body-wapper">
      <MetaTags
      title={'Online Auctions in the UK | Yolop - Your Ultimate Auction Destination'}
      description={"Explore the excitement of online auctions in the UK at Yolop. Bid on exclusive items, win incredible deals, and join our vibrant bidding community today!"}
      focusKeyphrase={'Exciting Online Auctions'}
              canonicalURL={'https://yolop.net'}
/>

<HomeMain/>
    </div>
  );
};

export default App;
