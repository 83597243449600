import { Icon } from "@iconify/react";
import { SignUpFormStyle } from "./SignUpFormStyle";
import FormsSidebar from "../../common/FormsSidebar/FormsSidebar";
import React, { useCallback, useRef, useState } from "react";
import { Formik, useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import { loader } from "../../../loader";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import alert from "../../Login/LoginForm/alert.png";
import { TimeZone } from "../../common/DateFormat/DateFormate";

const SignUpForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [Error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [value, setValue] = useState();
  const [valueError, setValueError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [custonCountryCode, setCustomCountryCode] = useState();
  const phoneInput = useRef(null);

  let Navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    // console.log(form, "form.checkValidity()");
    if (!formik.isValid) {
      toast.error("please check all the fields", {
        toastId: "signup-form-email",
      });
    }
    if (!value) {
      toast.error("country code required", {
        toastId: "signup-form-email",
      });
    } else {
      formik.handleSubmit();
      setValidated(true);
    }
  };

  const handleClick = (id) => {
    // 👇️ toggle isActive state on click
    if (id === 1) {
      setShowPass((current) => !current);
    } else {
      setShowPassConfirm((current) => !current);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
      first_name: "",
      last_name: "",
      mobile: "",
      date_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      timezone: TimeZone(new Date()),
      device_type: 3,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      conditionOne: false,
      conditionTwo: false,
      conditionThree: false,

    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name Required"),
      last_name: Yup.string().required("Last Name Required"),
      email: Yup.string()
        .email("Invalid Email!")
        .required("Email address is required!")
        .trim()
        .matches(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          "Please enter valid email address"
        ),

      mobile: Yup.string().matches(/^\d{4,15}$/, "Only 4-15 Digits Required"),
      // .required("Phone number is required"),

      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(15, "Password must be at least 15 characters")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,15}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      confirm_password: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "Password and Confirm password should be same."
        )
        .required("Enter Your Confirm Password"),
      // value: Yup.string().required("country code required"),


      conditionOne: Yup.boolean().oneOf([true], "You must agree to the Condition"),
      conditionTwo: Yup.boolean().oneOf([true], "You must agree to the Condition"),
      conditionThree: Yup.boolean().oneOf([true], "You must agree to the Condition"),

    }),

    onSubmit: (values) => {
      loader("show");
      setValueError(false);
      ExportApi.Register(
        values.email,
        values.password,
        values.confirm_password,
        values.first_name,
        values.last_name,
        values.mobile,
        values.date_time,
        values.timezone,
        values.device_type,
        value, custonCountryCode,
        values.timeZone
      )
        .then((resp) => {
          if (resp.data) {
            if (resp.data.success === true) {
              Navigate("/verification/registrationEmail", {
                state: {
                  email: resp.data?.data?.email,
                  token: resp.data?.data?.token,
                  id: resp.data?.data?.id,
                },
              });
              toast.success(resp.data.message, {
                toastId: "signup-form-message",
              });
              // loader("hide");
            } else {
              if (resp.data.data.status === 3) {
                setError(resp.data.data.error);
                setIsOpen(true);
                loader("hide");
              } else if (resp.data.data.email) {
                toast.error(
                  "Your email address is already registred. Please login."
                );
              } else {
                if (resp.data.data.mobile[0]) {
                  toast.error(
                    "Your mobile number is already registred. Please login."
                  );
                }
                if (resp.data.data.email[0]) {
                  toast.error(resp.data.data.email[0], {
                    toastId: "signup-form-email",
                  });
                  Navigate("/login");
                }
              }

              loader("hide");
            }
          }
        })
        .catch((err) => loader("hide"));
    },
  });

  const onChangeCallingCode = useCallback(
    (text) => {
      setCustomCountryCode(text);
    },
    [value]
  );

  return (
    <SignUpFormStyle>
      <div className="signup-form-wapper pb-5">
        <Container>
          <Row>
            <Col className="form-sidebar" sm={5}>
              <div className="form-sidebar-inner">
                <FormsSidebar
                  sidebartitle={"Yolop Sign Up"}
                  sidebarcontent={`<strong>Bid Securely</strong> and <strong>Transparently</strong><br/>with Yolop`}
                  sidebarimage={"/images/signup.png"}
                />
              </div>
            </Col>

            <Col className="signup-form" sm={7}>
              <div className="signup-form-inner">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="6 mt-3"
                    // controlId='validationCustom01'
                    >
                      <Form.Label>
                        <Icon icon="bxs:user" />
                        First name
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          required
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                        />
                        {formik.touched.first_name &&
                          formik.errors.first_name &&
                          formik.errors.first_name}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6 mt-3"
                    // controlId="validationCustom02"
                    >
                      <Form.Label>
                        <Icon icon="bxs:user" />
                        Last Name
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Last name"
                          name="last_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                        />
                        {formik.touched.last_name &&
                          formik.errors.last_name &&
                          formik.errors.last_name}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6 mt-3"
                    // controlId="validationCustomUsername"
                    >
                      <Form.Label>
                        <Icon icon="fluent:mail-16-filled" />
                        Email Address
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="email"
                          placeholder="Email@domain.com"
                          aria-describedby="inputGroupPrepend"
                          required
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.email}
                          </div>
                        ) : (
                          <Form.Control.Feedback type="invalid">
                            Please choose a Email.
                          </Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6 mt-3 phone-input"
                    // controlId="validationCustomMobile"
                    >
                      <Form.Label>
                        <Icon icon="ion:phone-portrait-outline" />
                        Mobile
                      </Form.Label>
                      <div className="input-color">
                        <Row>
                          <InputGroup hasValidation>
                            <Col xs={3}>
                              <PhoneInput
                                ref={phoneInput}
                                className="form-control mt-1 mb-1"
                                name="CountryCode"
                                international
                                value={value}
                                onChange={setValue}
                                onCountryChange={onChangeCallingCode}
                              />
                              <Form.Control.Feedback type="invalid">
                                "Please Select Your Country ."
                              </Form.Control.Feedback>
                            </Col>
                            <Col xs={9}>
                              <Form.Control
                                type="tel"
                                autoComplete="tel"
                                placeholder="078202 7292"
                                // aria-describedby="inputGroupPrepend"
                                required
                                pattern="[0-9]{15} 15"
                                maxLength="15"
                                name="mobile"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile}
                              />
                            </Col>
                          </InputGroup>
                        </Row>
                      </div>
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.mobile}
                        </div>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          Please Enter Your Mobile Number.
                        </Form.Control.Feedback>
                      )}
                      <div style={{ color: "red" }}>{valueError}</div>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="12"
                      className="mb-3 password"
                    // controlId="validationCustom03"
                    >
                      <Form.Label>
                        <Icon icon="fa:lock" />
                        Password
                      </Form.Label>
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        placeholder="Password"
                        required
                        id="passInput"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <Icon
                        className="passshow"
                        onClick={() => handleClick(1)}
                        icon={
                          showPass ? "akar-icons:eye" : "akar-icons:eye-slashed"
                        }
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.password}
                        </div>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Password.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      className="mb-2 password"
                    // controlId="validationCustom04"
                    >
                      <Form.Label>
                        <Icon icon="fa:lock" />
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        type={showPassConfirm ? "text" : "password"}
                        placeholder="Confirm Password"
                        required
                        id="passInput2"
                        name="confirm_password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirm_password}
                      />
                      <Icon
                        className="passshow"
                        onClick={() => handleClick(2)}
                        icon={
                          showPassConfirm
                            ? "akar-icons:eye"
                            : "akar-icons:eye-slashed"
                        }
                      />
                      {formik.touched.confirm_password &&
                        formik.errors.confirm_password ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.confirm_password}
                        </div>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          Please provide a confirm password
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-12">
                  <Form.Group  as={Col} xs={12} className="mt-3">
                      <Form.Check
                        type="checkbox"
                        label="I have read and accept the terms and conditions that apply to this App and any future changes to your Privacy Policy below."
                        name="conditionOne"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.conditionOne && formik.errors.conditionOne && (
                        <div style={{ color: "red" }}>
                          {formik.errors.conditionOne}
                        </div>
                      )}
                  </Form.Group>

                  <Form.Group  as={Col} xs={12} className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="I understand that by accepting these App Terms I opt in, and give consent to allow Yolop to collect data every second."
                    name="conditionTwo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.conditionTwo && formik.errors.conditionTwo && (
                    <div style={{ color: "red" }}>
                      {formik.errors.conditionTwo}
                    </div>
                  )}
              </Form.Group>

              <Form.Group as={Col} xs={12} className="mt-3">
              <Form.Check
                type="checkbox"
                label="l opt in, and give consent to allow Yolop to send me marketing materials and communications."
                name="conditionThree"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.conditionThree && formik.errors.conditionThree && (
                <div style={{ color: "red" }}>
                  {formik.errors.conditionThree}
                </div>
              )}
          </Form.Group>


                  </Row>


                  <Button className="signup-button mt-1" type="submit">
                    Signup
                  </Button>
                  <Form.Text
                    id="passwordHelpBlock"
                    className="text-center d-block mt-3"
                    muted
                  >
                    By clicking “Signup” you agree to Yolop’s <br />
                    <Link to={"/terms-and-conditions"}>Terms of use</Link> or{" "}
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </Form.Text>
                  <Form.Group
                    className="mt-4 login-signup"
                  // controlId="formBasicCheckbox"
                  >
                    <p className="text-center mb-0">
                      Already have an account ?
                      <Link to={"/login"}>
                        Sign In? <Icon icon="bi:arrow-right" />
                      </Link>
                    </p>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={isOpen} className="send-confirm" id="resend-confirm">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsOpen(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <img style={{ marginTop: "50px" }} src={alert} alt="" loading="lazy" />
          <h4> {Error} </h4>
          <div className="modal-buttons">
            <a href="mailto:bablu.jakhar830@gmail.com?subject={YOLOP Acount}&body={Please My Acount Activeted}">
              <button
                type="button"
                className="btn btn-primary btn-filled"
                data-bs-dismiss="modal"
              >
                Click to Send an Email Admin
              </button>
            </a>
            {/* <button
                              type="button"
                              className="btn btn-primary btn-bordered light"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                   setIsOpen(false);
                              }}
                              >
                              Cancel
                              </button> */}
          </div>
        </Modal.Body>
      </Modal>
    </SignUpFormStyle>
  );
};

export default SignUpForm;
