import styled from "styled-components";

export const FooterBottomStyle = styled.section`
  // .footer-border-wapper{
  //     .footer-border{
  //         height: 2px;
  //         background: #FFD905;
  //         d
  //     }
  // }
  .footer-bottom-wapper {
    .footer-bottom-content {
      p {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.69);
      }
    }
  }
`;
