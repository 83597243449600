import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import  TransactionSlice  from "../Slices/TransactionSlice";
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

const persistConfig = {
  key: 'APIData',
  storage,
}

const reducers = combineReducers({ APIData: TransactionSlice });
const persistedReducer = persistReducer(persistConfig,reducers )

// const reducer = {
//   APIData: persistedReducer
// };
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  })
  // serializableCheck: {
  //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  // },  
});

export const persistor = persistStore(store)

