import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import ExportApi from "../../../../Api/ExportApi";
import CheckoutForm from "./CheckoutForm";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { AddFundsFormStyle } from "./AddFundsFormStyle";
import { toast } from "react-toastify";
import { savejoinAuction } from "../../../../Redux/Slices/TransactionSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const publicKey = loadStripe(
  "pk_live_51N1RiDDfcUP6ZfIglXP9hJDtrUnMu2rLwsV7zAHNyBI4XcWoytqhzb2hMGYZLQmWgUqzn5ZReiufbpqJjH3ujbRN00ygEGq0C0"
);
// you should use env variables here to not commit this
// but it is a public key anyway, so not as sensitive
const AddFundsForm = ({
  type,
  joinAuctionId,
  amount,
  auction_id,
  item,
  currency_name,
  currencySymbol,
  vat_price,
  total_price,
  coupon_code,
}) => {
  //   console.log(total_price, vat_price, currencySymbol, currency_name, auction_id, amount)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();
  const [price, setPrice] = useState();
  const [totalPayAmount, setTotalPayAmount] = useState();
  const [errorText, setErrorText] = useState("");
  const [press, setPress] = useState(false);
  const profileDetails = useSelector(
    (state) => state?.APIData?.Profiledata?.due_payment
  );
  const inclusive_exclusive_value = profileDetails[0]?.inclusive_exclusive;


  useEffect(() => {
    if (!item) {
      setPrice(total_price);
    } else if (item?.min_tokens < amount) {
      setPrice(amount);
    } else {
      setPrice(item?.min_tokens.toString());
    }
  }, [total_price, item]);

  useEffect(() => {
    if (item?.amount) {
      const totalVal = item?.amount * price;
      setTotalPayAmount(totalVal?.toFixed(2).toString());
    }
  });

  useEffect(() => {
    const minamount = item?.min_tokens
      ? JSON.parse(item?.min_tokens)
      : undefined;
    const maxamount = item?.max_tokens
      ? JSON.parse(item?.max_tokens)
      : undefined;
    const checkAmount = amount ? JSON.parse(amount) : undefined;
    if (price < minamount || price < checkAmount) {
      setErrorText("Price is too short.");
      setPress(true);
    } else if (price > maxamount) {
      setErrorText("Please choose higher package!");
      setPress(true);
    } else {
      setErrorText();
      setPress(false);
    }
  }, [price]);

  const onPaymentSheetHandler = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    const data = {
      amount: item ? totalPayAmount : price,
      payment_type: type ? 0 : 1,
      currency: item ? item?.global_currency_name : currency_name,
      package_id: item ? item?.id : null,
      auction_id: auction_id ? auction_id : null,
      amount_in_custom_currency: item ? price : null,
      coupon_code: coupon_code,
    };
    // console.log(data)
    localStorage.setItem("LastFillAmmount", data.amount);
    ExportApi.StripePayment(data).then((res) => {
      //   console.log(res)
      if (res.status === 500) {
        toast.error("value must be greater than or equal to 1.");
      } else {
        const datavel = res.data.client_secret;
        localStorage.setItem("StripeKey", datavel);
        return setClientSecret(datavel);
      }
      const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      {
        joinAuctionId &&
          ExportApi.JoinAuction(joinAuctionId, date, coupon_code).then(
            (resp) => {
              if (resp.data.success) {
                toast.success("Joined Succesfully");
                localStorage.setItem("Auctionid", "");
                navigate("/myauctions");
                dispatch(savejoinAuction(resp));
              }
            }
          );
      }
    });
  };

  function handleChange(event) {
    setPrice(Number(event.target.value));
  }

  return (
    <AddFundsFormStyle>
      <div className="app-wapper">
        <div className="amount-currency-wapper mt-5">
          {item?.name && <div className="package-type">{item?.name}</div>}
          {item?.name ? (
            <InputGroup className="mb-3">
              <InputGroup.Text>
                {currencySymbol ? currencySymbol : item?.view_currency}
              </InputGroup.Text>
              <div className="amount-feild input ">
                <input
                  id="number-input"
                  type="number"
                  value={price}
                  onChange={item ? handleChange : null}
                />
              </div>
            </InputGroup>
          ) : (
            <div className="winAuction_amount-currency-wapper mt-5">
              <h4>
                {inclusive_exclusive_value === "1" ? "Inclusive" : "Exclusive"}
              </h4>
              <h4>To claim your win, please pay :</h4>
              <div className="aomout_instruction mt-2">
                <h5>Winning Amount :</h5>
                <span>{`${currencySymbol} ${amount}`}</span>
              </div>

              <div className="aomout_instruction mt-2">
                <h5>VAT Charged :</h5>
                <span>{`${currencySymbol} ${vat_price}`}</span>
              </div>

              <div className="aomout_instruction mt-2 last">
                <h4>Total</h4>
                <span>{`${currencySymbol} ${price}`}</span>
              </div>
            </div>
          )}

          {item && (
            <div className="package-range">
              Min {item.view_currency} {item.min_tokens} - Max
              {item.view_currency} {item.max_tokens}
            </div>
          )}
          {totalPayAmount && (
            <div className="you-pay">
              <b>You only pay : </b> {item?.global_currency} {totalPayAmount}
            </div>
          )}
          {errorText && <div className="error-msg">{errorText}</div>}

          <Button
            variant="primary"
            type="submit"
            onClick={onPaymentSheetHandler}
            disabled={press}
          >
            Submit
          </Button>
        </div>
        {clientSecret && (
          <Elements options={{ clientSecret }} stripe={publicKey}>
            <CheckoutForm
              joinAuctionId={joinAuctionId}
              coupon_code={coupon_code}
              currency={currencySymbol ? currencySymbol : item?.global_currency}
            />
          </Elements>
        )}
      </div>
    </AddFundsFormStyle>
  );
};
export default AddFundsForm;
