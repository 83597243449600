import { ContactMainStyle } from './ContactMainStyle';
import PageTitle from '../common/PageHeading/PageHeading';
import ContactForm from './ContactForm/ContactForm';

function ContactMain() {
    return (
          <ContactMainStyle>
               <div className='contact-main-wapper pt-4'>
                    <PageTitle 
                         pagetitle={'Contact'} 
                         // pageslug={''}
                    />
                    <ContactForm/>
               </div>
          </ContactMainStyle>
    );
}

export default ContactMain;