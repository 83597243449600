import React, { useState, useEffect } from "react";
import { TransactionHistoryStyle } from "./TransactionHistoryStyle";
import { Tab, Tabs } from "react-bootstrap";
import PageHeading from "../../common/PageHeading/PageHeading";
import HistoryTable from "../common/HistoryTable/HistoryTable";
import ExportApi from "../../../Api/ExportApi";
import { useDispatch, useSelector } from "react-redux";
import { saveTransaction, savePurchases, saveAuctionCharges, saveEarnedRewards } from "../../../Redux/Slices/TransactionSlice";
import Paginations from "../../common/Pagination/Paginations";
import ContentLoader, { List, BulletList, Code } from "react-content-loader";
import AuctionChargesHistory from "../common/HistoryTable/AuctionChargesHistory";
import AuctionPurchasesHistory from "../common/HistoryTable/AuctionPurchasesHistory";
import EarnedRewardHistory from "../common/HistoryTable/EarnedRewardHistory";
import { useNavigate } from "react-router-dom";


const TransactionHistoryFN = () => {
    const [totalCount, setTotalCount] = useState();
    const [totalpurchases, setTotalPurchases] = useState();
    const [totalCharge, setTotalCharge] = useState();
    const [totalEarned, setTotalEarned] = useState();


    const [currentPage, setCurrentPage] = useState(1);
    const [currentChargesPage, setCurrentChargesPage] = useState(1);
    const [currentPurchasesPage, setCurrentPurchasesPage] = useState(1);
    const [currentEarnedPage, setCurrentEarnedPage] = useState(1);

    const [itemsPerPage] = useState(25);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const { Transactionsdata, AuctionPurchases, AuctionCharges, RewardsEarned } = useSelector((state) => state?.APIData)

    const TransactionData = () => {
        ExportApi.StripeTransaction(currentPage, itemsPerPage)
            .then((resp) => {
                if (resp.data) {
                    setTotalCount(resp?.data?.meta?.total);
                    dispatch(saveTransaction(resp.data.data));
                    setLoader(false);
                }
            })
            .catch((err) => console.log(err));
    };
    const AuctionChargesData = () => {
        ExportApi.AuctionChargesTransation(currentChargesPage, itemsPerPage)
            .then((resp) => {
                if (resp.data) {
                    setTotalCharge(resp?.data?.meta?.total);
                    dispatch(saveAuctionCharges(resp?.data?.data));
                    setLoader(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const AuctionWonData = () => {
        ExportApi.AuctionWonTransation(currentPurchasesPage, itemsPerPage)
            .then((resp) => {
                if (resp.data) {
                    setTotalPurchases(resp?.data?.meta?.total);
                    dispatch(savePurchases(resp?.data?.data));
                    setLoader(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const auctionRewardsDetail = () => {
        ExportApi.rewardsTransation(currentPurchasesPage, itemsPerPage)
            .then((resp) => {
                if (resp.data) {
                    console.log('resp.data', resp.data)
                    setTotalEarned(resp?.data?.meta?.total);
                    dispatch(saveEarnedRewards(resp?.data));
                    setLoader(false);
                }
            })
            .catch((err) => console.log(err));
    };


    let Navigate = useNavigate();
    const CheckValidTokenn = () => {
        if (!localStorage.getItem("Token")) {
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            Navigate("/login");
        }
    };

    useEffect(() => {
        CheckValidTokenn();
        TransactionData();
        setLoader(true);
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        AuctionWonData()
        setLoader(true);
    }, [currentPurchasesPage, itemsPerPage]);

    useEffect(() => {
        auctionRewardsDetail()
        setLoader(true);
    }, [currentEarnedPage, itemsPerPage]);

    useEffect(() => {
        AuctionChargesData();
        setLoader(true);
    }, [currentChargesPage, itemsPerPage]);


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const paginatePurchases = (pageNumber) => {
        setCurrentPurchasesPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const paginateRewards = (pageNumber) => {
        setCurrentEarnedPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const paginateCharges = (pageNumber) => {
        setCurrentChargesPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    return (
        <TransactionHistoryStyle>
            <div className="transaction-history-page-title">
                <div className="pt-4">
                    <PageHeading pagetitle={`Transaction History `} />
                </div>
            </div>
            <div className="transaction-history-main mb-5">
                <div className="transaction-history-history-wapper">
                    <Tabs
                        defaultActiveKey="deposit"
                        id="transaction-history-history"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="deposit" title="Deposit">
                            {loader ? (
                                <div className="row">
                                    {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                                        (variant, index) => (
                                            <ContentLoader
                                                className="col-md-12"
                                                style={{ width: "100%" }}
                                                height={62}
                                                key={index}
                                            >
                                                <rect y="17" rx="4" ry="4" width="100%" height="62" />
                                            </ContentLoader>
                                        )
                                    )}
                                </div>
                            ) : (
                                <>
                                    <HistoryTable data={Transactionsdata} />
                                    {Transactionsdata?.length < totalCount && <Paginations
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        totalItems={totalCount}
                                        paginate={paginate}
                                    />}
                                </>
                            )}
                        </Tab>
                        <Tab eventKey="auctioncharges" title="Auction Charges">
                            <AuctionChargesHistory data={AuctionCharges} />
                            {AuctionCharges?.length < totalCharge && <Paginations
                                currentPage={currentChargesPage}
                                itemsPerPage={itemsPerPage}
                                totalItems={totalCharge}
                                paginate={paginateCharges}
                            />}
                        </Tab>
                        <Tab eventKey="Won" title="Purchases">
                            <AuctionPurchasesHistory data={AuctionPurchases} />
                            {
                                AuctionPurchases?.length < totalpurchases && <Paginations
                                    currentPage={currentPurchasesPage}
                                    itemsPerPage={itemsPerPage}
                                    totalItems={totalpurchases}
                                    paginate={paginatePurchases}
                                />
                            }
                        </Tab>
                        <Tab eventKey="earningsReward" title="Earnings">
                            <EarnedRewardHistory data={RewardsEarned} />
                            {RewardsEarned?.length < totalEarned && <Paginations
                                currentPage={currentEarnedPage}
                                itemsPerPage={itemsPerPage}
                                totalItems={totalEarned}
                                paginate={paginateRewards}
                            />}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </TransactionHistoryStyle>
    );
};

export default TransactionHistoryFN;
