import styled from 'styled-components';


export const NotificationsStyle = styled.section`


    .notifications-page-title{
        background-color: #EDF9FE;
        .page-title-wapper{
            padding-right: 20rem;
            
            .page-title{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 34px;
                    color: #3D3838;
                }
            }
            .page-breadcrumb-wapper{
                .breadcrumb {
                    a{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 30px;
                    }
                }
            }
        
            @media screen and (max-width: 1400px) {
                padding-right:4rem;}
        }
        @media screen and (max-width: 767px) {
            display:none;
        }
    
    }
    .notifications-main{
        padding-right: 16rem;
        padding-left: 1.5rem;
        
        .notifications-history-wapper{
            background-color: #F3F7FC;
            border: 1px solid #BDC2CB;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);

            .notifications-history-item{
                padding: 40px;
                border-bottom: 1px solid #BDC2CB;
               
                
                :last-child{
                    border-bottom: 0px solid #BDC2CB;
                }
                .hstack{
                    
                    align-items: flex-start;
                    flex-direction: inherit;
                }
                .notifications-image-wapper{}
                .notifications-image{
                    width: 100px;
                    height:100px;
                    border-radius: 50px;
                }
                .notifications-content-wapper{
                    .notifications-content-time{
                        span{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 600;
                            font-size: 11px;
                            line-height: 19px;
                            color: #101010;
                        }
                    }
                    .notifications-content{
                        p{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 23px;
                            color: #545454;
                        }
                    }
                    .notifications-content-price{
                        strong{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 20px;
                            color: #101010;
                        }
                    }
                
                }
                .notifications-time-wapper{
                    span{
                        background-color: #FFFFFF;
                        border: 1px solid #96B0BB;
                        border-radius: 90px;
                        width: 100px;
                        padding: 5px;
                        display: block;
                        text-align: center;
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #2B4A69;
                    }
                }
                .cstmblock {
                cursor: pointer;
                }

                @media screen and (max-width: 601px) {
                    .cstmblock {
                        flex-direction: column;

                    }
                }
           
            }
            .read{
                background-color: #FFFF;
            }
            .notifications-content-action{
                position:relative;
                padding-left: 7rem;
            }
            .notifications-content-action{
                .btn{
                    background-color: #0180FF;
                    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
                    border-radius: 100px;
                    width: 10rem;
                    margin-top: 20px;
                    cursor: all-scroll;
                     z-index: 9999;
                }
            }
        }
        @media screen and (max-width: 1600px) {
            padding-right: 9rem;
        }
       
        @media screen and (max-width: 1400px) {
            padding-right: 6rem;
        }
        @media screen and (max-width: 1024px) {
            padding-right: 1rem;
        }
   
    }

`;