import React, { useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup, Modal } from "react-bootstrap";
import { Wrapper } from "./AuctionStartVideoStyle";

const AuctionStartVideo = (props) => {
  const [show, setShow] = useState(true);
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = props.id?.match(regExp);
  const yotubeVid = match && match[7].length == 11 ? match[7] : false;
  return (
    <Wrapper>
      <div className="auction-start-video-wapper">
      <Modal show={show} size="xl" backdrop="static" keyboard={false} pb-5 mb-5>
        <div className="cstmhead px-3 pt-2">
       <h3>Get ready</h3> 
      <p >  Auction is about to start in a minute or two. Please stay and enjoy the
        video in the meantime.</p>
        </div>
        <Modal.Body>
          <iframe
            width="100%"
            height="600"
            src={`https://www.youtube.com/embed/${yotubeVid}?controls=0&autoplay=1`}
            title="React Redux"
            frameborder="0"
            allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal.Body>
      </Modal>
      </div>
    </Wrapper>
  );
};

export default AuctionStartVideo;
