import React from "react";
import { Pagination } from "react-bootstrap";
import { PaginationStyle } from "./PaginationStyle";

const Paginations = ({ itemsPerPage, totalItems, paginate, currentPage}) => {
  const pageNumbers = [];
  const maxVisiblePages = 10;
  const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  let startPage = Math.max(1, currentPage - halfMaxVisiblePages);
  let endPage = Math.min(totalPages, currentPage + halfMaxVisiblePages);

  if (endPage - startPage + 1 < maxVisiblePages) {
    if (startPage === 1) {
      endPage = Math.min(maxVisiblePages, totalPages);
    } else {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const getPrevious = (currentPage) => paginate(currentPage - 1);
      
  const getNext = (currentPage) => paginate(currentPage + 1);

  return (
    <PaginationStyle>
      <nav>
        <div className="pagination-wapper mt-5 mb-5">
          {totalItems < itemsPerPage ? (
            ""
          ) : (
            <Pagination className="text-center">
              {currentPage === 1 ? (
                ""
              ) : (
                <Pagination.Prev
                  className="prev"
                  onClick={() => getPrevious(currentPage)}
                />
              )}

              {pageNumbers.map((number, index) => (
               <Pagination.Item
                  className={`${currentPage === number ? "active" : ""}`}
                  onClick={() => paginate(number)}
                  href={"#"}
                  key={index}
                >
                  {number}
                </Pagination.Item>
              ))}
              {currentPage === totalPages ? (
                ""
              ) : (
                <Pagination.Next
                  className="next"
                  onClick={() => getNext(currentPage)}
                />
              )}
            </Pagination>
          )}
        </div>
      </nav>
    </PaginationStyle>
  );
};

export default Paginations;
