import styled from 'styled-components';


export const TopProfileStyle = styled.section`

    .top-profile-wapper{
        .profile-cart{
            border: none;
            background: linear-gradient(360deg, #0A3C84 0%, #0B5DBA 100%);
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            align-content: space-around;
            border-radius: 0px;
            .image-wapper{
                width: 40%;
                text-align:center;
                
                img{
                    border-radius: 50%;
                    border: 3px solid rgba(255, 255, 255, 0.69);
                    height: 120px;
                    width:120px;
                }
            }
            .card-body{
                text-align: center;
                width: 90%;
                .card-text{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                    margin-bottom: 10px;          
                }
                .card-title{
                    color: #fff;
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;

                    .yolopian-currency{
                        font-family: 'Plus Jakarta Sans';
                        color:rgb(255, 216, 33);
                    }
                }
                .profile-name{
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 20px;
                    margin-bottom: 35px;
                    text-transform: capitalize;
                }
                .profile-total-ammount{
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 20px;
                    letter-spacing: -0.02em;
                    margin-bottom: 20px;
                }
                .button{
                    height: 45px;
                    background: #FFD821;
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 33px;
                    color: #000000;
                    width: 100%;
                    border: none;
                }
            }
        }
    }

`;