import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container } from "react-bootstrap";
import { PrivacyPolicyStyleMob } from './PrivacyPolicyStyleMob';

import PageHeading from '../../Components/common/PageHeading/PageHeading';
import PageContent from '../../Components/common/PageContent/PageContent';


function PrivacyPolicyMob() {
  return (
    <PrivacyPolicyStyleMob>
      <div className='privacypolicy-wapper pb-5'>

        <div className="privacypolicy-title pt-4 ">
          <PageHeading 
            pagetitle={`Privacy Policy App`}
          />
        </div>       

        <Container>
          <PageContent pagecontent={`
           
           <h2><strong>Owner and Data Controller</strong></h2>

           <p><strong>Owner Name</strong>: Yolop.<br>
           <strong>Owner contact email:</strong> <a href="mailto:info@yolop.net">info@yolop.net</a></p>
           
           <p><strong>Information Collection and Use</strong></br>
           
           All information collected by this App is used for the purpose of registering users with the App and to use that data in improvement of this App. This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
           
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. </p>
           
           <p>The User-data collected is used as explained below.</p>
           
           <h2><strong>Collecting and Using Your Personal Data</strong></h2>
           
           <h3><strong>Types of Data Collected</strong></h3>
           
           <p><strong>Personal Data</strong></br>
           
          While using our App, we may ask you to provide us with certain personal identification information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
           
           <ul>
           <li>Email address</li>
           <li>First name and last name</li>
           <li>Phone number</li>
           <li>Country</li>
           </ul>
           <h3><strong>Use of Your Personal Data</strong></h3>
           The Company may use Personal Data for the following purposes:
           <ul>
             <li> <b> To provide and maintain our Service, </b> including to monitor the usage of our Service.</li>
             <li> <b> To manage Your Account: </b>to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
             <li> <b>For the performance of a contract: </b>the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
             <li> <b>To contact You:</b> 
                To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                <li> <b> To manage Your requests:  </b> To attend and manage Your requests to Us.</li>
           </ul>
           
           <p><strong>Usage Data</strong></br>
           
          Usage Data is collected automatically while using thisApplication.<a name="_GoBack"></a>Usage Data may include information such as your Device's Internet Protocol address (e.g. IP address).</p>
           
           <h3><strong>Interpretation and Definitions</strong></h3>
           <p><strong>Interpretation</strong></br>
           The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
           <p><strong>Definitions</strong></br>
           For the purposes of this Privacy Policy:
           
             <ul>
               <li> <b> Account</b> means a unique account created for You to access our Service or parts of our Service.</li>
               <li> <b> Application</b> refers to Yolop, the software program provided by the Company.</li>
               <li> <b> Company</b>(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Yolop Ltd, Yolop Ltd, 128 City Road, EC1V 2NX, LONDON, United Kingdom.</li>
               <li> <b> Country</b> refers to: United Kingdom</li>
               <li> <b> Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
               <li> <b> Personal</b> Data is any information that relates to an identified or identifiable individual.</li>
           
           
               <li> <b> Usage</b> Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
               <li> <b> You</b>	
                  means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable</li>
             </ul>
             
             <h3><strong>Changes to this Privacy Policy</strong></h3>
             <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
           
               We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective.
               
               You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

          `}/>        
        </Container>

      </div>
    </PrivacyPolicyStyleMob>
  );
}

export default PrivacyPolicyMob;
