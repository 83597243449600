import { Container, Row, Col, Button } from "react-bootstrap";
import { setConstantValue } from "typescript";
import { AuctionsCardStyle } from "./AuctionsCardListStyle";
import { useEffect, useState } from "react";

import CountdownDate from "./CountdownDate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import ExportApi from "../../../Api/ExportApi";
import ContentLoader, { List } from "react-content-loader";
// import { AuctionsData } from "../../../Data/AuctionsData";

const AuctionsCardList = (props) => {
  let Navigate = useNavigate();
  const data = props.AuctionsData;
  const [loader, setLoader] = useState(false);
  const locetion = useLocation();
  const [val, setVal] = useState();
  const [AuctionsDataF, setAuctionsData] = useState(props.AuctionsData);
  const [AuctionsDataPage, setAuctionsDataPage] = useState();
  const [render, setRender] = useState(1);

  useEffect(() => {
    setVal(props.value);
    return () => setVal();
  }, [props.value]);

  const AuctionsData = () => {
    ExportApi.Auctions()
      .then((resp) => {
        if (resp.data) {
          setLoader(false);
          setAuctionsDataPage(resp.data.data);
          //  setData(resp.data.data)
          // setHeaderData(resp.data)
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setLoader(true);
    AuctionsData();
    let data = AuctionsDataF;
    setAuctionsData(props.AuctionsData);
    setRender(render + 34);
  }, [props]);

  const onDismissVal = () => {
    props.setFilterDATA("");
  };

  return (
    <AuctionsCardStyle>
      {val && (
        <div className="search-auctions-list-inner">
          <Container>
            <div className="search-auctions-list newclass">
              <Row>
                {locetion.pathname === "/searchitem" ? (
                  <>
                    {loader ? (
                      <div className="row">
                        {["1", "2", "3", "4", "5", "6"].map(
                          (variant, index) => (
                            <div className="col-sm-4" key={index}>
                              <ContentLoader
                                style={{ width: "100%" }}
                                height={275}
                              >
                                <rect
                                  y="17"
                                  rx="4"
                                  ry="4"
                                  width="100%"
                                  height="275"
                                />
                              </ContentLoader>
                              <List className="mt-3" />
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      AuctionsDataPage?.map((data, key) => {
                        return (
                          <Col
                            style={{ margin: "auto" }}
                            xs={12}
                            md={12}
                            lg={12}
                            key={key}
                          >
                            <div
                              className={
                                "search-auctions-item auctions-item-" + data.id
                              }
                            >
                              <Row>
                                <Col xs={4}>
                                  <div className="search-auctions-item-image">
                                    <img
                                      className="search-auctions-img"
                                      src={
                                        data.AuctionImages[0]?.image_url +
                                        data.AuctionImages[0]?.image[0]
                                      }
                                     loading="lazy"
                                    />
                                  </div>
                                </Col>
                                <Col xs={8}>
                                  <div className="search-auctions-item-card">
                                    <Row className="mid-section">
                                      <Col
                                        xs={12}
                                        className="search-auctions-title-wapper"
                                      >
                                        <div className="search-auctions-title">
                                          <span>{data.asset_name}</span>
                                        </div>
                                        <div className="search-auctions-disc">
                                          {/* <p>{data.text.substring(0, 100)}</p> */}
                                          <p> </p>
                                        </div>
                                      </Col>
                                      <Col className="search-auctions-info-inner">
                                        <div className="info-heading">
                                          <span>Starting Bid</span>
                                        </div>
                                        <div className="price">
                                          <strong>{data.asset_price}</strong>
                                        </div>
                                      </Col>
                                      <Col
                                        xs
                                        lg="5"
                                        className="search-auctions-info-inner"
                                      >
                                        <div className="info-heading">
                                          <span>Auction Time </span>
                                        </div>
                                        <div className="auction-time">
                                          <time>
                                            <CountdownDate
                                              time={data.auction_ends}
                                            />
                                          </time>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={12}
                                        className="search-auctions-buttom-wapper"
                                      >
                                        <div className="search-auctions-buttom">
                                          {/* <Link to={`/details/${data.id}`}><Button onClick={() => { props.dataFilterNull() }} variant="light" className="mt-2 viewdetails">View Details</Button></Link> */}
                                          <Button
                                            variant="light"
                                            className="mt-2 viewdetails"
                                            onClick={() => {
                                              localStorage.setItem(
                                                "details",
                                                JSON.stringify(data)
                                              );
                                              setTimeout(() => {
                                                Navigate(
                                                  `/details/${data.slug}`
                                                );
                                              }, 500);
                                            }}
                                          >
                                            View Details
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        );
                      })
                    )}
                  </>
                ) : (
                  <>
                    {data.slice(0, 6).map((data, key) => {
                      return (
                        <Col style={{ margin: "auto" }} xs={12}>
                          <Link to={`/details/${data.slug}`}>
                            <div
                              className={
                                "search-auctions-item auctions-item-" + data.id
                              }
                              key={key}
                            >
                              <Row>
                                <Col xs={2}>
                                  <div className="search-auctions-item-image">
                                    <img
                                      className="search-auctions-img"
                                      src={
                                        data.AuctionImages[0]?.image_url +
                                        data.AuctionImages[0]?.image[0]
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="search-auctions-item-card">
                                    <Row className="mid-section">
                                      <Col
                                        xs={12}
                                        className="search-auctions-title-wapper"
                                      >
                                        <div className="search-auctions-title">
                                          <span>{data.name}</span>
                                        </div>
                                        <div className="search-auctions-disc">
                                          <p>{data.asset_name}</p>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={12}
                                        className="search-auctions-buttom-wapper"
                                      >
                                        <div className="search-auctions-buttom"></div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                    <div className="btn-viewall"
                      style={{
                        cursor:"pointer",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        onDismissVal();
                        setVal();
                        Navigate("/upcoming-auctions", {
                          state: {
                            value: AuctionsDataF,
                          },
                        });
                      }}
                    >
                     View All
                    </div>
                  </>
                )}
              </Row>
            </div>
          </Container>
        </div>
      )}
    </AuctionsCardStyle>
  );
};
export default AuctionsCardList;
