import React from "react";
import { FormsSidebarStyle } from "./FormsSidebarStyle";
import { Card } from "react-bootstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Icon } from "@iconify/react";

interface LoginSidebarProps {
  sidebartitle: string;
  sidebarcontent: string;
  sidebarimage: string;
}

function FormsSidebar({
  sidebartitle,
  sidebarcontent,
  sidebarimage,
}: LoginSidebarProps) {
  return (
    <FormsSidebarStyle>
      <div className="form-sidebar-wapper">
        <Card className="text-white">
          <Card.Body>
            <Card.Title>{sidebartitle}</Card.Title>
            <Card.Text>
              <span dangerouslySetInnerHTML={{ __html: sidebarcontent }} />
            </Card.Text>
            <Card.Img src={sidebarimage} alt="Card image" />
          </Card.Body>
        </Card>
      </div>
    </FormsSidebarStyle>
  );
}
export default FormsSidebar;
