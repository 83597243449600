import { LoginMainStyle } from './LoginMainStyle';
import PageTitle from '../common/PageHeading/PageHeading';
import LoginForm from './LoginForm/LoginForm';

function LoginMain() {
    return (
     <LoginMainStyle>
        <div className='login-main-wapper pb-5 pt-4'>
            <PageTitle 
               pagetitle={'Login'}
               // pageslug={''}
            />
            <LoginForm/>    
        </div>
     </LoginMainStyle>
    );
}

export default LoginMain;