import styled from 'styled-components';


export const BannerSliderStyle = styled.section`

div#home_video {
    margin-top: -38%;
    width: 40%;
    float: right;
    margin-right: 5%;
    position: relative;
}
#home_video video {
    -webkit-box-shadow: 3px 3px 41px 4px rgb(0 0 0);
    -moz-box-shadow: 3px 3px 41px 4px rgb(0 0 0);
    box-shadow: 3px 3px 41px 4px rgb(0 0 0);
}

    .bannerslider-wapper{
        .carousel{
            /* display: none; */
            .carousel-item{
                margin-bottom: 3rem;
                .carousel-caption{
                    top: 5%;
                    bottom: 10%;
                    text-align: left;
                    right: 45%;
                    left: 3%;
                    .yolop-logo{
                        /* background: rgb(205,139,22); */
                        background: linear-gradient(180deg, rgba(205,139,22,1) 0%, rgba(255,192,82,1) 50%, rgba(236,153,3,1) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 900;
                        font-size: 50px;
                        margin-bottom: 4rem;
                        font-family: 'Plus Jakarta Sans';
                    }
                    h1{
                        color: #fff;
                        font-size: 45px;
                        font-weight: 900;
                        font-family: 'Plus Jakarta Sans';
                        line-height: 1.2;
                        span{
                            color: #ffde26;
                        }
                    }
                    p{
                        color: #fff;
                        font-size: 18px;
                        line-height: 1.6;
                        font-weight: 500;
                        margin-top: 2rem; 
                        font-family: 'Plus Jakarta Sans';          
                    }
                    .slider-inner-right{                    
                        img {
                            width: 97%;
                        }
                    }                 
                }
            }
        }

        .yolop-welcometext{
                margin-bottom: 30px;
           
            h2{
                text-align: center;
                font-weight: 700;
                margin-bottom: 25px;
            }

            .read-more-state {
                    display: none;
                    }

                    .read-more-wrap{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        color: #223A52;
                        margin-top: 15px;
                        p{
                            margin-top: -10px;
                        }
                    }

                    .read-more-target {
                    opacity: 0;
                    max-height: 0;
                    font-size: 0;
                    transition: .25s ease;
                    position: absolute;
                    width: 0px;
                    }

                    .read-more-state:checked ~ .read-more-wrap .read-more-target {
                    opacity: 1;
                    font-size: inherit;
                    max-height: 999em;
                    position: relative;
                    width: 100%;
                    }

                    .read-more-state ~ .read-more-trigger:before {
                    content: 'Show More';
                    }

                    .read-more-state:checked ~ .read-more-trigger:before {
                    content: 'Show Less';
                    }

                    .read-more-trigger {
                        cursor: pointer;
                        display: inline-block;
                        padding: 0 0.5em;
                        color: #0050c7;
                        font-size: .9em;
                        margin-top: -5px;
                        line-height: 2;
                        border: 2px solid #0050c7;
                        border-radius: 0.25em;
                        position: relative;
                        z-index: 100;
                    }

        }







        .banner-download-weight{
            /* display: none; */
            .banner-download-inner{
                margin: 0rem 0rem 1rem 0rem;
                .download-weight-title{
                    text-align: center;
                    font-weight: 700;
                }
                
            }
            .button-wapper{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                .button-ios{
                    button{
                        background: #2f80ed;
                        border-color: #2f80ed;
                    }
                }
                .button-android{
                    button{
                        background: #34a853;
                        border-color: #34a853;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1700px) {
        div#home_video {
                margin-top: -38%;
                width: 35%;
                float: right;
                margin-right: 3%;
                position: relative;
            }

        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .carousel-caption {
                        right: 42%;
                        left: 4%;
                        .slider-inner-right {
                            img {
                                width: 98%;
                                float: right;
                            }
                        }
                    }
                }
            }
            .banner-download-weight {
                .banner-download-inner{
                    .download-weight-title{
                        ::before {
                            left: 18%;
                        }
                        ::after {
                            right: 18%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .carousel-caption {
                        h1 {
                            font-size: 35px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1260px) {
          div#home_video {
                margin-top: 8%;
                width: 100%;
                float: unset;
                margin-right: 0%;
            }

        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .carousel-caption {
                        right: 10%;
                        left: 10%;
                        h1 {
                            font-size: 35px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1150px) {
         div#home_video {
                margin-top: 0%;
            }
        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .carousel-caption {
                      
                        h1 {
                            font-size: 30px;
                        }
                        p{
                            font-size: 13px;
                        }
                        .slider-inner-right {
                            img {
                                width: 70%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .hero-img {
                        min-height: 410px;
                    }
                    .carousel-caption {
                    
                        h1 {
                            font-size: 24px;
                            .yolop-logo {
                                  font-size: 36px;
                            }
                        }
                        p{
                            font-size: 13px;
                        }
                        .slider-inner-right {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .hero-img {
                        min-height: 330px;
                    }
                    .carousel-caption {
                        right: 5%;
                        left: 5%;
                        top: 0%;
                     
                        h1 {
                            font-size: 25px;
                        }
                        p{
                            margin-top: 1rem;
                        }
                    }
                    
                }
            }
        }
    }

    @media screen and (max-width: 645px) {
        .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .hero-img {
                        min-height: 285px;
                    }
                    margin-bottom: 2rem;
                    .carousel-caption {
                        top: -10px;
                     
                        h1 {
                            font-size: 20px;
                            br:last-child{
                                display: none;
                            }
                        }
                        .mobile-full{
                            width: 100% !important;
                        }
                        .mobile-hide{
                            display: none;
                        }
                    }
                    
                }
            }
            .banner-download-weight {
                .banner-download-inner{
                    .download-weight-title{
                        font-size: 20px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 500px) {
         .bannerslider-wapper {
            .carousel {
                .carousel-item {
                    .carousel-caption {
                   
                        h1 {
                            font-size: 15px;
                        }
                        p{
                            margin-top: 10px;
                            font-size: 11px;
                            br{
                                display: none;
                            }
                        }
                        .mobile-full{
                            width: 100% !important;
                        }
                        .mobile-hide{
                            display: none;
                        }
                    }
                    
                }
            }
            
        }
    }

    @media screen and (max-width: 300px) {

        .bannerslider-wapper {
            .banner-download-weight {
                .banner-download-button{
                    .button-wapper{
                        flex-direction: column;
                    }
                }
            }



        }

    }


`;