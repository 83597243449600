import * as React from "react";

// import './LoginStyle.css';
import "bootstrap/dist/css/bootstrap.css";
import ResetMain from "./ResetMain";

const ResetMainFN = () => {
  return (
    <div className="login-wapper">
      <ResetMain />
    </div>
  );
};

export default ResetMainFN;
