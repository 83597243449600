import styled from 'styled-components';


export const PageContentStyle = styled.section`

     .page-content-wapper{
          background-color: #fff;
          padding: 45px 60px;
          // box-shadow: 0px 0px 50px 0px #00000021;

          .page-content-inner{
               .page-content{
                    h4{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 600;
                         font-size: 20px;
                         line-height: 25px;
                         color: #000000;
                    }
                    p{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 400;
                         font-size: 16px;
                         line-height: 25px;
                         color: #5B5959;
                         margin: 15px 0px;
                         
                    }

                    hr{
                         margin:0px;
                         border: 1.5px solid #fbbc04;
                         opacity:1;
                         margin-top: -5px;
                         
                    }
                    span.orng-ltr{
                         color:#ed7d31;
                     }

                    span.buy-btn{
                         font-weight: bold;
                         color:#0070c0;
                     }

                     li{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 400;
                         font-size: 16px;
                         line-height: 25px;
                         color: #5B5959;
                     }
               }
          }
    
          @media screen and (max-width: 1100px){
               padding: 45px 0px;

          }
    
    
    
     }


`;