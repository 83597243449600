import styled from 'styled-components';


export const SupportStyle = styled.section`

     .support-wapper{
          background-color: #fff;
          .support-title{
               background-color: #EDF9FE;
          }
     }


`;