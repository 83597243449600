import { PageContentStyle } from "./PageContentStyle";

interface PageContentProps {
  pagecontent: string;
}

function PageContent({ pagecontent }: PageContentProps) {
  return (
    <PageContentStyle>
      <div className="page-content-wapper mb-5">
        <div className="page-content-inner">
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: pagecontent }}
          />
        </div>
      </div>
    </PageContentStyle>
  );
}
export default PageContent;
