import styled from 'styled-components';


export const UpcomingAuctionsMainStyle = styled.section`

    .auctions-main-wapper{
       background-color: #FFF;

       .page-title-wapper{
            background-color: #EDF9FE;
            padding: 2rem 0px;
       }
       .auctions-filter{
        position: relative;
         z-index: 100;
            form{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                flex-direction: row;
                align-content: center;
                font-family: 'Plus Jakarta Sans';

                .filter-heading-wapper{
                    width: 130px;
                     .filter-heading-inner{
                         background: #0070E0;
                         border-radius: 60px;
                         display: inline-block;
                         padding: 7px 16px 7px 20px;
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 500;
                         font-size: 15px;
                         line-height: 19px;
                         color: #FFF;
         
                         svg{
                             font-size: 28px;
                             font-weight: bolder;
                             margin-right: 5px;
                         }
                     }
                }
                .feild-outer{
                    width: auto;
                    padding: 10px;
                    :last-child{
                        padding-right: 0px;
                    }
                    label{
                        display: inline-block;
                        margin-right: 10px;
                    }
                    select{
                        display: inline-block;
                        width: auto;
                    }
                }
                .feild-outer.last {
                    margin-left: auto;
                }


            }
        }
        .pagination-wapper{
            .pagination{
                display: flex;
                justify-content: center;
                .page-item{
                    .page-link{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 25px;
                        background-color: transparent;
                        border-radius: 4px;
                        padding: 7px 17px;
                        margin-right: 10px;
                        color: #000000;
                    }
                }
                .prev{
                    .page-link{
                        background-color: #7F909D;
                        color: white;
                    }
                }
                .active{
                    .page-link{
                        background-color: #0180FF;
                        color: white;
                    }
                }
            }

        }
       .forgotpassword-form{
            background-color: #fff;
            padding: 40px;
        }

    }

    @media screen and (max-width: 767px) {

        .auctions-main-wapper{
            .auctions-filter{
                

                .container.filter-cstm{
                    flex-direction: column;
                    place-items: flex-start;

                }
                .form-label{
                    width:80px;
                }

                 .feild-outer.last {
                    margin-left:unset !important;

                }

            }

        }

    }

`;  