import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Table,
  Container,
} from "react-bootstrap";
import { Icon } from "@iconify/react";
import { AuctionsDetailsSingleStyle } from "./AuctionsDetelsSingleStyle";
import CountdownDate from "../AuctionsCard/CountdownDate";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SliderImage from "react-zoom-slider";
import { SetDate, SetTime } from "../DateFormat/DateFormate";
import ExportApi from "../../../Api/ExportApi";
import { savejoinAuction } from "../../../Redux/Slices/TransactionSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import ConfirmationBox from "../../ConfirmationBox/ConfirmationBox";
import PageNotFound from "../../../Pages/PageNotFound/PageNotFound";

const AuctionsDetailsSingle = () => {
  let navigate = useNavigate();
  const locetion = useLocation();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [auctionData, setAuctionData] = useState();

  let param = useParams();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const Auctiondata = async () => {
    await ExportApi.SingleAuction(param?.slug, timeZone)
      .then((resp) => {
        if (resp.data && resp.data.data) {
          setDetail(resp.data.data);
          setLoader(false);
        } else if (resp.status === 404) {
          setLoader(false);
          setIsNotFound(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoader(true);
    Auctiondata();
  }, [param]);

  // const filtering = detail?.filter((item) => item.slug === param.slug);
  const handleJoin = (data) => {
    setAuctionData(data);
    setModalVisible(true);
  };

  const handleJoinSuccess = (couponCode) => {
    const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setModalVisible(false);
    localStorage.setItem("Auctionid", auctionData.id);
    if (localStorage.getItem("Token")) {
      ExportApi.JoinAuction(auctionData.id, date, couponCode)
        .then((resp) => {
          if (resp.data.success) {
            toast.success("Joined Succesfully");
            localStorage.setItem("Auctionid", "");
            navigate("/myauctions");
            dispatch(savejoinAuction(resp));
          } else if (resp.data.data.error === "Previous Due Payment") {
            navigate("/addfunds", {
              state: {
                amount: resp.data.message?.due_payment[0].winning_price,
                auction_id: resp.data.message?.due_payment[0].auction_id,
                currency_name: resp.data.message?.due_payment[0].currency_name,
                currencySymbol:
                  resp.data.message?.due_payment[0].custom_currency_symbol,
                vat_price: resp.data.message?.due_payment[0].vat_price,
                total_price: resp.data.message?.due_payment[0].total_price,
                coupon_code: couponCode,
              },
            });
            dispatch(savejoinAuction(resp));
            toast.error(resp.data.data.error);
          } else if (resp.data.data.error) {
            if (resp.data.data.error === "Invalid coupon.") {
              toast.error(resp.data.data.error);
            } else if (resp.data.data.error === "Coupon already used.") {
              toast.error(resp.data.data.error);
            } else {
              toast.error(
                "Amount required to join: $ " +
                  resp.data.message.amount_required
              );
              navigate("/packages", {
                state: {
                  amount: resp.data.message.amount_required,
                  type: "wallets",
                  auctionId: auctionData.id,
                  coupon_code: couponCode,
                },
              });
            }

            dispatch(savejoinAuction(resp));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(savejoinAuction(null));
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <AuctionsDetailsSingleStyle>
      <div className="single-auctions-wapper">
        {isNotFound && <PageNotFound />}
        <Container>
          <div className="">
            {loader ? (
              <ContentLoader />
            ) : (
              detail?.map((data) => {
                var multiple = data?.AuctionImages?.map((i) => i.image);

                const imgarray = [];
                multiple[0].map((i) => {
                  imgarray.push({
                    image: data?.AuctionImages[0]?.image_url + i,
                  });
                });
                return (
                  <div key={data?.id?.toString()}>
                    <Row>
                      <Col xs="12" lg="6">
                        <div className="single-auctions-carousel">
                          <div
                            className={`${
                              multiple[0].length > 1
                                ? "multiple-images"
                                : "single-image"
                            }`}
                          >
                            <SliderImage
                              data={imgarray}
                              //   width="500px"
                              showDescription={true}
                              direction="right"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <Row className="single-auctions-wapper">
                          <Col xs="12" lg="6">
                            <div className="single-auctions-breadcrumb-wapper">
                              <Breadcrumb>
                                <li className="breadcrumb-item">
                                  <Link to="/">Home</Link>
                                </li>
                                <Icon icon="bi:arrow-right" />
                                <Breadcrumb.Item active>
                                  {data?.asset_name}
                                </Breadcrumb.Item>
                              </Breadcrumb>
                            </div>
                            <div className="single-auctions-name-wapper">
                              <h1>{data?.name}</h1>
                              {/* <span>{DATA?.text}</span> */}
                            </div>
                          </Col>
                          <Col>
                            <div className="single-auctions-price-wapper">
                              <p>STARTING BID</p>
                              <h3 className="single-auctions-price">
                                <strong>
                                  {data?.view_currency} {data?.asset_price}
                                </strong>
                              </h3>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        {data?.status === ("2" || "7") &&
                          data?.status !== "5" && (
                            <div className="single-auctions-time-wapper">
                              {/* <p> AUCTIONS START TIME</p>
                          {console.log(data , "data?.auction_starts")}
                          <time>
                            <CountdownDate time={data?.auction_starts} />
                          </time> */}
                            </div>
                          )}
                        <div className="single-auctions-details-wapper">
                          <Card>
                            <div className="single-auctions-details-title">
                              <h4>Auction Details</h4>
                              <hr />
                            </div>
                            <div className="single-auctions-details-data">
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>Start Date/Time</td>
                                    <td>
                                      {SetDate(data?.auction_starts)}{" "}
                                      {SetTime(data?.auction_starts)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>End Date/Time</td>
                                    <td>
                                      {SetDate(data?.auction_ends)}{" "}
                                      {SetTime(data?.auction_ends)}
                                    </td>
                                  </tr>
                                  <tr>
                                    {data?.show_participants === "1" ? (
                                      <>
                                        <td>Number of Participants</td>
                                        <td>{data?.no_of_participants}</td>
                                      </>
                                    ) : null}
                                  </tr>
                                  <tr>
                                    <td>No of Items</td>
                                    <td>{data?.items}</td>
                                  </tr>
                                  <tr>
                                    <td>Auction Duration</td>
                                    <td>{data?.duration}</td>
                                  </tr>

                                  <tr>
                                    <td>Participation Fees</td>
                                    <td>
                                      {data?.view_currency}{" "}
                                      {data?.participation_fees}
                                    </td>
                                  </tr>
                                  <tr>
                                    {data?.fee_charged ? (
                                      <>
                                        <td>Fee Charged</td>
                                        <td>
                                          {data?.view_currency}{" "}
                                          {data?.fee_charged}
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>

                                  <tr>
                                    {data?.winning_price ? (
                                      <>
                                        <td>Winning Price</td>
                                        <td>
                                          {data?.view_currency}{" "}
                                          {data?.winning_price}
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>
                                  <tr>
                                    {data?.vat_price ? (
                                      <>
                                        <td>VAT</td>
                                        <td>
                                          {data?.view_currency}{" "}
                                          {data?.vat_price}
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>
                                  <tr>
                                    {data?.total_price ? (
                                      <>
                                        <td>Total</td>
                                        <td>
                                          {data?.view_currency}{" "}
                                          {data?.total_price}
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Card>
                        </div>
                        <div className="single-auctions-buttom-wapper">
                          <div>
                            {data?.user_id === null && data?.status == 2 && (
                              <Button
                                variant="primary"
                                onClick={() => handleJoin(data)}
                                className="mt-2 joinnow"
                              >
                                Join Now
                              </Button>
                            )}
                            {data?.payment_status === "0" && (
                              <Button
                                variant="primary"
                                onClick={() =>
                                  navigate("/addfunds", {
                                    state: {
                                      amount: data?.winning_price,
                                      auction_id: data?.id,
                                      currency_name: data?.currency_name,
                                      currencySymbol: data?.view_currency,
                                      vat_price: data?.vat_price,
                                      total_price: data?.total_price,
                                    },
                                  })
                                }
                              >
                                Checkout
                              </Button>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="single-auctions-content-wapper">
                      <div className="single-auctions-title">
                        <h4>More Information</h4>
                        <hr />
                      </div>
                      <div className="single-auctions-content">
                        <div dangerouslySetInnerHTML={{ __html: data?.text }} />
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Container>
      </div>
      {modalVisible && (
        <ConfirmationBox
          isVisible={modalVisible}
          onChangeVisible={setModalVisible}
          auctionData={auctionData}
          onJoinAuction={handleJoinSuccess}
        />
      )}
    </AuctionsDetailsSingleStyle>
  );
};

export default AuctionsDetailsSingle;
