import React from 'react'
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col,Button } from "react-bootstrap";
import PageHeading from "../../Components/common/PageHeading/PageHeading";
import {QuestionStyle} from "./QuestionStyle"
import MetaTags from '../../Components/common/MetaTags';


const Question = () => {
  return (
    <QuestionStyle>
      <MetaTags
      title={'Yolop FAQ - Frequently Asked Questions about Online Bidding and Auctions'}
      description={"Find answers to commonly asked questions about online bidding, auctions, and how Yolop works. Get detailed information on registration, bidding process, payment methods, and more."}
      focusKeyphrase={'FAQ'}
              canonicalURL={'https://yolop.net/faq'}
/>
    <div className="faq-main-wapper ">
        <div className="faq-title">
          <PageHeading pagetitle={`FAQ`} />
        </div>

        <Container className='faq-inner-wrapper'>
          <Row className='row-wrapper'>
          <Col sm={12} md={12} lg={4} className="faq-img">
                          <img className="about-img" src="/images/faq.png" alt="" loading="lazy" />
          </Col>

          <Col sm={12} md={12} lg={8} className="faq-wrapper">
          <Accordion flush>
         <Accordion.Item eventKey="0">
         <Accordion.Header>1. Who will be declared the winner?</Accordion.Header>
         <Accordion.Body>
          Whoever hits the "Buy Now" button first among all other bidders will be declared the winner.
         </Accordion.Body>
         </Accordion.Item>
         <Accordion.Item eventKey="1">
         <Accordion.Header>2. Can I use my referral credits to pay the joining fee?</Accordion.Header>
         <Accordion.Body>
          Yes, you can use your referral credits to pay the joining fee and participate in auctions.
         </Accordion.Body>
         </Accordion.Item>
         <Accordion.Item eventKey="2">
         <Accordion.Header>3. How can I track my bidding history and transactions on Yolop?
         </Accordion.Header>
         <Accordion.Body>
         You can easily track your bidding history and transactions on Yolop through your account dashboard, which provides detailed information of all your bids and payments. 
         </Accordion.Body>
         </Accordion.Item>
         <Accordion.Item eventKey="3">
         <Accordion.Header>4. How do I receive my winning item?
         </Accordion.Header>
         <Accordion.Body>
         After winning the auction, and completing the payments , items will be delivered to your registered address. 
         </Accordion.Body>
         </Accordion.Item>
         <Accordion.Item eventKey="4">
         <Accordion.Header>5. What are the available payment options to add funds in Yolop?
         </Accordion.Header>
         <Accordion.Body>
         Users can add funds in Yolop through Stripe, a secure and quick payment method. 
         </Accordion.Body>
         </Accordion.Item>
         </Accordion>

         <Col sm={12} md={12} className="faq-knowmore">
         <h3>Need more details about Yolop?</h3>
         <p>No problem! We are here to help. Our friendly and knowledgeable customer care team is available to answer any questions you may have about our platform. We’re happy to provide you with the information you need to make an informed decision. </p>

         <div className="gettingstarted-button">
          <Button href="/contact-us" variant="primary" size="lg">Contact Us</Button>
          </div>


    </Col>
    
          </Col>

          </Row>
        </Container>
      </div>
      </QuestionStyle>
  )
}

export default Question