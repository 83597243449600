import { Container, Row, Col, Carousel } from "react-bootstrap";
import { BannerSliderStyle } from "./BannerSliderStyle";
import DownloadWeight from "../../common/DownloadWeight/DownloadWeight";
import video from './yolop-main-sale-video.mp4';
import videoimg from './Unbelievable-Deals-On-Designer-Bags-And-Luxury-Brands-At-Yolop-Auctions.jpg';

const BannerSlider = () => {
  return (



    <BannerSliderStyle>
      <div className="bannerslider-wapper">
        {/* <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/oneworldtechnologies/image/upload/v1664542579/Yolop/hero_lvhtof.jpg"
                    alt="First slide"
                /> */}
        <Carousel slide={false} controls={false} indicators={false}>
          <Carousel.Item>
            <img
              className="d-block w-100 hero-img"
              src="/images/yolop-web.png"
              alt="First slide"
              loading="lazy"
            />
            <Carousel.Caption>
              <Container>
                
                <Row>
                  <Col xs={8} md={7} lg={6} className="mobile-full">
                 
                    <h1>
                    <span className="yolop-logo">Yolop: </span>
                     Unleash the Excitement of <span>Online Auctions </span> 
                     and <span>Reverse Bidding App!</span> 
                    </h1>
                    <p>
                    Experience the adrenaline-pumping excitement of reverse-auction bidding anytime, anywhere with the Yolop mobile app. Embark on an exhilarating journey into the world of online auctions. Download now from the Apple Store and Play Store. Get ready to unleash the thrill!<br/> Download Now !
                    </p>
                  </Col>
                  <Col xs={4} md={5} lg={6} className="mobile-hide">
                    <div className="slider-inner-right">
                      <img
                        className="d-block"
                        src="/images/phone-2.png"
                        alt="First slide"
                        loading="lazy"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Carousel.Caption>
                      <div id="home_video">  
                          <div class="pp_video" id="pp_vid_MzlUZXN0aWVz">
                              <video style={{ width: '100%' }} controls poster={videoimg} >
                          <source src={video} type="video/mp4" />
                      </video>
                      </div>
                      </div>
               
          </Carousel.Item>
        </Carousel>

             <div className="yolop-welcometext">
            <Container>
            <h2>YOLOP - Your Treasure Trove of Unbeatable Discounts!</h2>

              <input type="checkbox" class="read-more-state" id="post-2" />

                <div class="read-more-wrap">
                  <p>Welcome to YOLOP, your ultimate destination for adrenaline-pumping flash <b>online auctions</b> and unbeatable discounts on luxury brands, electronics, fashion, and more!</p>
 
                  <div class="read-more-target">
                    <p> Our innovative <b>online platform </b> is designed to transform your shopping experience, putting the power of savings right at your fingertips</p>
                    <h4><b>How Does It Work? </b></h4>
                  <p>You have the opportunity of buying at the price you want. Yes, at the price of your choice, your own low price! Our live auctions run for two minutes where the price starts off at retail price and drops to zero in that time. As the price drops, you have the option of pressing the BUY NOW button when the price reaches the price you want to buy at, giving you the thrill of securing incredible deals like never before. The rush of adrenaline as you see the prices plummet is simply unmatched. No matter if you're a seasoned bidder with expertise or just starting out in the world of <b>online auctions,</b> our platform ensures a smooth and engaging experience for everyone.</p> 

                  <h4><b>Why YOLOP?</b></h4>
                  <p>We understand the joy of owning luxury items and the satisfaction of finding the best deals. That's why we bring you a treasure trove of unbeatable discounts, helping you save big on top-notch products. Whether you're a fashion enthusiast, tech-savvy shopper, or simply love a great bargain, YOLOP’s online auctions have something for everyone.</p> 

                  <h4><b>Auction Alerts and Notifications</b></h4>
                  <p>Never miss a beat with Yolop's customized auction alerts and notifications. Stay well-informed about upcoming auctions, last-minute bidding opportunities, and exclusive promotions, all tailored to your preferences. Be in the know, effortlessly staying up-to-date with your favorite auction categories and ensuring you never miss an opportunity to place a bid.
                  </p> 

                <h4><b>Secure and Convenient Payment Options</b></h4>
                <p>Yolop prioritizes secure and convenient payment options for our winning bidders. With a variety of choices available, including credit/debit card payments and digital wallets, users can select the payment method that aligns with their preferences. Our aim is to facilitate a seamless and hassle-free transaction process, ensuring your payment experience is smooth and worry-free.<br/>


                Ready to embark on your shopping adventure? Download the YOLOP app and start saving. With a few taps, you'll unlock exclusive access to our flash auctions and a plethora of luxury shopping deals.<br/>


                Don't miss out on this exciting opportunity! Join the YOLOP revolution today and experience the thrill of getting more for less. Embrace the power of savings and elevate your shopping game with YOLOP!
                </p>


                  </div>

                </div>

                
                <label for="post-2" class="read-more-trigger">
                </label>
               

                </Container>
                
              
            </div>


        <div className="banner-download-weight">
          <Container>
            <div className="banner-download-wapper">
              <div className="banner-download-inner">
                <div className="download-weight-title-wapper">
                  <h2 className="download-weight-title">
                  Bid with Yolop. Download Today and Experience Thrilling Online Auctions!
                  </h2>
                </div>
              </div>
              <div className="banner-download-button">
                <DownloadWeight />
              </div>
            </div>
          </Container>
        </div>


     
      </div>
    </BannerSliderStyle>
  );
};

export default BannerSlider;
