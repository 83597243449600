import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Modal, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExportApi from "../../../../../Api/ExportApi";
import { loader } from "../../../../../loader";
import { DashboardMenuStyle } from "./DashboardMenuStyle";
import throttle from "lodash/throttle"; // Import the throttle function from lodash

const DashboardMenu = () => {
    const location = useLocation();
    let Navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModal, setShowModal] = useState(false);
    const [isClickDisabled, setIsClickDisabled] = useState(false); // To prevent clicks when modal is open

    // Throttle the handleNavigation function to limit clicks to 7 every 5 seconds
    const throttledHandleNavigation = throttle((to) => {
        Navigate(to);
    }, 5000, { leading: true, trailing: false }); // Set the throttle time to 5000 milliseconds (5 seconds)

    const handleNavigation = (to) => {
        if (!showModal) {
            throttledHandleNavigation(to);
            const currentClicks = sessionStorage.getItem("clicks");
            const totalClicks = currentClicks ? parseInt(currentClicks) + 1 : 1;
            sessionStorage.setItem("clicks", totalClicks.toString());

            if (totalClicks > 20) {
                console.log('User limit reached');
                setShowModal(true);
                sessionStorage.removeItem("clicks");
                setIsClickDisabled(false);

            } else {
                setIsClickDisabled(false); // Re-enable clicks
            }
            console.log('Total clicks: ' + totalClicks)
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setIsClickDisabled(false); // Re-enable clicks when modal is closed
        window.location.reload(); // Refresh the page
    };

    const logout = () => {
        loader("show");
        setIsOpen(false);
        let Token = localStorage.getItem("Token");
        ExportApi.UserLogout(Token)
            .then((resp) => {
                if (resp.data) {
                    if (resp.data.success === true) {
                        setShow(false);
                        localStorage.removeItem("Token");
                        localStorage.removeItem("User_ID");
                        localStorage.removeItem("email");
                        localStorage.removeItem("mobile");
                        window.dispatchEvent(new Event("EventLength"));
                        loader("hide");
                        setIsOpen(false);
                        Navigate("/login");
                    }
                }
            })
            .catch((err) => setIsOpen(false));
    };

    return (
        <DashboardMenuStyle>
            <div className="dashboard-menu-wapper">
                <div className="dashboard-menu mt-3 mb-5">
                    <Nav defaultActiveKey="/" className="flex-column">
                        <Link
                            onClick={() => handleNavigation("/dashboard")}
                            className={location.pathname === "/dashboard" ? "active" : ""}
                            to="/dashboard"
                        >
                            <Icon icon="bx:pie-chart" />
                            Dashboard
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/myauctions")}
                            className={location.pathname === "/myauctions" ? "active" : ""}
                            to="/myauctions"
                        >
                            <Icon icon="ri:auction-line" />
                            My Auctions
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/transactionhistory")}
                            className={
                                location.pathname === "/transactionhistory" ? "active" : ""
                            }
                            to="/transactionhistory"
                        >
                            <Icon icon="ri:exchange-dollar-line" />
                            Transactions History
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/editprofile")}
                            className={location.pathname === "/editprofile" ? "active" : ""}
                            to="/editprofile"
                        >
                            <Icon icon="bx:user" />
                            Profile
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/changepassword")}
                            className={
                                location.pathname === "/changepassword" ? "active" : ""
                            }
                            to="/changepassword"
                        >
                            <Icon icon="bx:lock-alt" />
                            Change Password
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/notifications")}
                            className={location.pathname === "/notifications" ? "active" : ""}
                            to="/notifications"
                        >
                            <Icon icon="ion:notifications-outline" />
                            Notifications
                        </Link>
                        <Link

                            onClick={() => handleNavigation("/referEarn")}
                            className={location.pathname === "/referEarn" ? "active" : ""}
                            to="/referEarn"
                        >
                            <Icon icon="ci:share" />
                            Referal & Earn
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/helpsupport")}
                            className={location.pathname === "/helpsupport" ? "active" : ""}
                            to="/helpsupport"
                        >
                            <Icon icon="humbleicons:support" />
                            Help & Support
                        </Link>
                        <Link
                            onClick={() => handleNavigation("/packages")}
                            className={location.pathname === "/packages" ? "active" : ""}
                            to="/packages"
                        >
                            <Icon icon="uil:package" />
                            Packages
                        </Link>
                        <Link to="#" onClick={handleShow}>
                            <Icon icon="ic:twotone-logout" />
                            Logout
                        </Link>
                    </Nav>
                </div>
                <div className="logout-popup">
                    <Modal
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Logout</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are You Really Want To Logout ?</Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    logout();
                                }}
                            >
                                Logout
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Modal
                    show={showModal}
                    onHide={handleModalClose} // Close modal only on close button
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="limit-reached"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Warning!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>There have been too many clicks on this page. Refresh your page to resume functionality</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={handleModalClose} // Close modal and refresh page
                        >
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </DashboardMenuStyle>
    );
}

export default DashboardMenu;