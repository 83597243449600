export const DateFormatData = (date) => {
  let d = new Date(date);
  let datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear();
  return datestring;
};

export const TimeFormatedData = (date) => {
  let d = new Date(date);
  const value =
    ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
  return value;
};

//  format('YYYY-MM-DD HH:mm:ss');

export const DateTimeFormatedData = (date) => {
  let d = new Date(date);
  const value =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);

  return value;
};

//  format('DD-MM-YYYY HH:mm');

export const SetDateFormate = (date) => {
  let d = new Date(date);
  let h = d.getHours();
  let ampm = h >= 12 ? "PM" : "AM";
  const value =
    d.toString().replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, "$2 $1 $3") +
    "   " +
    ("0" + (d.getHours() % 12)).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ampm;
  return value;
};

//  format('DD-MM-YYYY HH:mm');

export const DateFormate = (date) => {
  let d = new Date(date);
  const value = d
    .toString()
    .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, "$2 $1 $3");
  return value;
};
// Hours
export const SetHourFormat = (date) => {
  let d = new Date(date);
  const value = ("0" + d.getHours()).slice(-2);
  return value;
};
//Minutes
export const SetMinuteFormat = (date) => {
  let d = new Date(date);
  const value = ("0" + d.getMinutes()).slice(-2);
  return value;
};
//Seconds
export const SetSecondsFormat = (date) => {
  let d = new Date(date);
  const value = ("0" + d.getSeconds()).slice(-2);

  return value;
};

export const SetDate = (date) => {
  let d = new Date(date);
  let h = d.getHours();
  const value = d
    .toString()
    .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, "$2 $1, $3");

  return value;
};
export const SetTime = (date) => {
  let d = new Date(date);
  let h = d.getHours();
  let ampm = h >= 12 ? "PM" : "AM";
  const value =
    ("0" + (d.getHours() % 12)).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    // ':' +
    // ('0' + d.getSeconds()).slice(-2) +
    " " +
    ampm;
  return value;
};
export const TimeZone = (date) => {
  let fulltime = new Date(date);
  const timeZone = fulltime.toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
  return timeZone;
};
