import styled from 'styled-components';


export const PaymentPolicyStyle = styled.section`

     .payment-policy-wapper{
          background-color: #fff;
          .paymentpolicy-title{
               background-color: #EDF9FE;
          }
     }


`;