import { OTPMainStyle } from "./OTPMainStyle";
import PageTitle from "../common/PageHeading/PageHeading";
import OTPForm from "./OTPForm/OTPForm";
import { useLocation } from "react-router-dom";

const OTPMain = () => {
  return (
    <OTPMainStyle>
      <div className="otpmain-main-wapper pb-5 pt-4">
        <OTPForm />
      </div>
    </OTPMainStyle>
  );
};

export default OTPMain;
