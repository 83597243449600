import React, { useEffect, useState } from "react";
import { MyAuctionsStyle } from "./MyAuctionsStyle";
import { Tab, Tabs } from "react-bootstrap";
import PageHeading from "../../common/PageHeading/PageHeading";
import AuctionsCard from "../../common/AuctionsCard/AuctionsCard";
import MyAuctionsList from "../common/MyAuctionsList/MyAuctionsList";
import ExportApi from "../../../Api/ExportApi";
import {
  saveCompletedAuction,
  saveUpcomingAuction,
} from "../../../Redux/Slices/TransactionSlice";
import { useDispatch } from "react-redux";
import ContentLoader, { List } from "react-content-loader";
import Paginations from "../../common/Pagination/Paginations";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const MyAuctionsFN = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [completedauctiondata, setCompletedauctiondata] = useState([]);
  const [wonauctiondata, setWonauctiondata] = useState([]);
  const [cancelauctiondata, setCancelauctiondata] = useState([]);
  const [itemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [completePage, setcompletePage] = useState(1);
  const [wonPage, setWonPage] = useState(1);
  const [cancledPage, setCancledPage] = useState(1);
  const [process, setProcess] = useState()
  const [totalCount, setTotalCount] = useState();
  const [totalComplete, setTotalComplete] = useState();
  const [totalWon, setTotalWon] = useState();
  const [totalCancled, setTotalCancled] = useState();
  const location = useLocation()


  const UpComingAuctionsData = () => {
    ExportApi.UpcomingAuctions(currentPage, itemsPerPage)
      .then((resp) => {
        if (resp.data) {
          setData(resp.data.data);
          setTotalCount(resp?.data?.meta?.total);
          dispatch(saveUpcomingAuction(resp.data.data));
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const CompletedAuction = () => {
    ExportApi.CompletedAuctions(completePage, itemsPerPage)
      .then((resp) => {
        if (resp.data) {
          setTotalComplete(resp?.data?.meta?.total);
          setCompletedauctiondata(resp.data.data);
          dispatch(saveCompletedAuction(resp.data.data));
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const WonAuction = () => {
    ExportApi.WonAuctions(wonPage, itemsPerPage)
      .then((resp) => {
        if (resp.data) {
          setWonauctiondata(resp.data.data);
          setTotalWon(resp?.data?.meta?.total);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const CancelledAuction = () => {
    ExportApi.CancelledAuctions(cancledPage, itemsPerPage)
      .then((resp) => {
        if (resp.data) {
          setTotalCancled(resp?.data?.meta?.total);
          setCancelauctiondata(resp.data.data);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const ProcessingLive = () => {
    ExportApi.ProcessingLive().then((res) => {
      setProcess(res.data.data)
    });
  };

    let Navigate = useNavigate();
    const CheckValidTokenn = () => {
        if (!localStorage.getItem("Token")) {
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            Navigate("/login");
        }
    };

  useEffect(() => {
    ProcessingLive();
      CheckValidTokenn();
  }, []);
  
  useEffect(() => {
    setLoader(true);
    UpComingAuctionsData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setLoader(true);
    CompletedAuction();
  }, [itemsPerPage, completePage]);

  useEffect(() => {
    setLoader(true);
    WonAuction();
  }, [itemsPerPage, wonPage]);

  useEffect(() => {
    setLoader(true);
    CancelledAuction();
  }, [itemsPerPage, cancledPage]);


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const paginateWon = (pageNumber) => {
    setWonPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginateCompleted = (pageNumber) => {
    setcompletePage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginateCancled = (pageNumber) => {
    setCancledPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <MyAuctionsStyle>
      <div className="myauctions-page-title">
        <div className="pt-4">
          <PageHeading pagetitle={`My Auctions`} />
        </div>
      </div>

      <div className="myauctions-main mb-5">
        <div className="myauctions-history-wapper">
          <Tabs
            defaultActiveKey={location?.state?.value ? location?.state?.value : "myupcoming"}
            id="myauctions-history"
            className="mb-3"
            fill
          >
            <Tab eventKey="myupcoming" title="My Upcoming">
              {loader ? (
                <div className="row">
                  {["1", "2", "3", "4", "5", "6"].map((variant, index) => (
                    <div className="col-sm-4" key={index}>
                      <ContentLoader style={{ width: "100%" }} height={275}>
                        <rect y="17" rx="4" ry="4" width="100%" height="275" />
                      </ContentLoader>
                      <List className="mt-3" />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                <AuctionsCard
                  type={"Myauction"}
                  AuctionsData={data}
                  processingData={process}
                  numberofcolumns={3}
                  itemRow={3}
                />
               {
               totalCount > itemsPerPage &&  <Paginations
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalCount}
                    paginate={paginate}
                  />
                  }
                  </>
              )}
            </Tab>
            <Tab eventKey="won" title="Won">
              {loader ? (
                <div className="row">
                  {["1", "2", "3", "4", "5", "6"].map((variant, index) => (
                    <div className="col-sm-4" key={index}>
                      <ContentLoader style={{ width: "100%" }} height={275}>
                        <rect y="17" rx="4" ry="4" width="100%" height="275" />
                      </ContentLoader>
                      <List className="mt-3" />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <MyAuctionsList AuctionsData={wonauctiondata} type={'won'}/>
                  {totalWon > itemsPerPage && <Paginations
                    currentPage={wonPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalWon}
                    paginate={paginateWon}
                  />}
                </>
              )}
            </Tab>
            <Tab eventKey="completed" title="Participated">
              {loader ? (
                <div className="row">
                  {["1", "2", "3", "4", "5", "6"].map((variant, index) => (
                    <div className="col-sm-4" key={index}>
                      <ContentLoader style={{ width: "100%" }} height={275}>
                        <rect y="17" rx="4" ry="4" width="100%" height="275" />
                      </ContentLoader>
                      <List className="mt-3" />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                <MyAuctionsList AuctionsData={completedauctiondata} />
                {totalComplete > itemsPerPage &&
                  <Paginations
                currentPage={completePage}
                itemsPerPage={itemsPerPage}
                totalItems={totalComplete}
                paginate={paginateCompleted}
              />}
              </>
              )}
            </Tab>
            <Tab eventKey="cancelled" title="Cancelled">
              {loader ? (
                <div className="row">
                  {["1", "2", "3", "4", "5", "6"].map((variant, index) => (
                    <div className="col-sm-4" key={index}>
                      <ContentLoader style={{ width: "100%" }} height={275}>
                        <rect y="17" rx="4" ry="4" width="100%" height="275" />
                      </ContentLoader>
                      <List className="mt-3" />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                <MyAuctionsList AuctionsData={cancelauctiondata} />
                {totalCancled > itemsPerPage &&  <Paginations
                currentPage={cancledPage}
                itemsPerPage={itemsPerPage}
                totalItems={totalCancled}
                paginate={paginateCancled}
              />
              }
              </>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </MyAuctionsStyle>
  );
};

export default MyAuctionsFN;
