import React from "react";
import { GettingStartedTitleStyle } from "./GettingStartedTitleStyle";
import {Button} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

const GettingStartedTitle = () => {
  let Navigate = useNavigate();
  return (
    <GettingStartedTitleStyle>
      <div className="gettingstarted-title-wapper">
          <div className="gettingstarted-title">
            <h2>Get started <br/>in a few minutes</h2>
            <p>In three easy steps!</p>
          </div>
          <div className="gettingstarted-button">
            <Button  href="#" variant="primary" size="lg" onClick={()=>Navigate("/login")}>Get Started Now</Button>
          </div>
      </div>
    </GettingStartedTitleStyle>
  );
};
export default GettingStartedTitle;