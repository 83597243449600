import styled from 'styled-components';


export const DashboardMainStyle = styled.section`


    .dashboard-page-title{
        background-color: #EDF9FE;
        .page-title-wapper{
            padding-right: 20rem;
            
            .page-title{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 34px;
                    color: #3D3838;
                }
            }
            .page-breadcrumb-wapper{
                .breadcrumb {
                    a{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 30px;
                    }
                }
            }
            @media screen and (max-width: 1400px) {
                padding-right:4rem;}
        
         }
                @media screen and (max-width: 767px) {
                    display:none;
                    
                
                }
            
    
    
    }
    .dashboard-main{
        padding-right: 12rem;
        padding-left: 1rem;
        
        .dashboard-liveauctions-cart{}
        .dashboard-upcomingauctions-list{
            /* .pagination-wapper{
                display: none;
                visibility: hidden;
            } */
            .dashboard-historytable-title{
                .dashboard-title-wapper{
                    h2{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 700;
                        font-size: 27px;
                        line-height: 34px;
                        color: #3D3838;
                    }

                }
            }

        }
        .dashboard-main-item-wapper{
            a{
                text-decoration: none;

                .dashboard-main-item{
                    background: #FFFFFF;
                    border: 2px solid #AAB6C4;
                    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.08);
                    border-radius: 6px;
                    padding: 30px;
                    min-height: 180px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
    
                    .dashboard-main-item-main-icon{
                        svg{
                            font-size: 35px;
                            color: #AAB6C3;
                        }
                    }
    
                    .dashboard-main-item-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
    
                        .item-title{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 23px;
                            color: #545454;
                            width: 55%;
                        }
                        .dashboard-main-item-icon{
                            svg{
                                font-size: 35px;
                                color: #AAB6C3;
                            }
                        }
                    }
                    :hover{
                        background: #0078FF;
                        border: 2px solid #0078FF;
                        .dashboard-main-item-main-icon{
                            svg{
                                font-size: 35px;
                                color: #fff;
                            }
                        }
                        .dashboard-main-item-title{
                            .item-title{
                                color: #fff;
                            }
                            .dashboard-main-item-icon{
                                svg{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
     
        @media screen and (max-width: 1600px) {
            padding-right: 9rem;

        }
       
        @media screen and (max-width: 1400px) {
            padding-right: 6rem;

        }
        @media screen and (max-width: 1024px) {
            padding-right: 1rem;

        }



    }

`;