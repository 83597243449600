import * as React from "react";
import { BitCoinsStyle } from './BitCoinsStyle';
import PageTitle from "../../Components/common/PageHeading/PageHeading";
import AuctionsMain from "../../Components/Auctions/AuctionsMain";


function BitCoinsMainFN() {

    return (
        <BitCoinsStyle>
            <div className='nft-main-wapper pb-5'>
                <PageTitle
                    pagetitle={'Mobile'}
                // pageslug={''}
                />
                <AuctionsMain />
            </div>
        </BitCoinsStyle>
    );
}

export default BitCoinsMainFN;
