import PageTitle from "../Components/common/PageHeading/PageHeading";
import { LoginMainStyle } from "../Components/Login/LoginMainStyle";
import ResetPassword from "./ResetPassword";

const ResetMain = () => {
  return (
    <LoginMainStyle>
      <div className="login-main-wapper pb-5 pt-4">
        <PageTitle
          pagetitle={"Reset Password"}
          // pageslug={''}
        />
        <ResetPassword />
      </div>
    </LoginMainStyle>
  );
};

export default ResetMain;
