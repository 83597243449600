import styled from 'styled-components';


export const HistoryTableStyle = styled.section`

    .history-table-wapper{

        overflow:hidden;
        
        table{
                margin-bottom: 2em;
                border-spacing: 0px 10px;
                border-collapse: separate;
                // box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.08);

            thead{
                border: none;
                
                tr{
                    th{
                        background-color: #8D9AA8;
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 20px;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        padding: 15px;

                        :first-child{
                            border-radius:6px 0 0 6px;
                        }
                        :last-child{
                            border-radius:0 6px 6px 0;
                        }
                    }
                }
            }
            tbody{
                tr{
                    border: none;
                    border-style: solid none;
                    vertical-align: middle;
                    
                    
                    td{
                        background-color: #fff;
                        border-top: 1px solid #AAB6C4;
                        border-bottom: 1px solid #AAB6C4;
                        font-family: 'Plus Jakarta Sans';
                        padding: 15px;

                        :first-child{
                            border-left: 1px solid #AAB6C4;
                            border-radius:6px 0 0 6px;
                        }
                        :last-child{
                            border-right: 1px solid #AAB6C4;
                            border-radius:0 6px 6px 0;
                        }
                    }
                    .date{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #343434;
                    }
                    .transaction-name{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #545455;
                    }
                    .id{
                        a{
                            color: #545455;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            text-decoration: none;
                        }
                    }
                    .amount{
                        strong{
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                            color: #343434;
                        }
                        span{
                            color: #3B8E48;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            margin-left: 10px;
                        }
                    }
                    .action-wapper{                        
                        .status{
                            background-color: #b8b8b858;
                            color: #000000;                           
                            border-radius: 64px;
                            letter-spacing: -0.03em;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            padding: 7px 15px;
                            margin-right: 5px;
                        }
                        .pending{      
                            background-color: #ffbf0059;
                            color: #2d2d2d;                   
                        }
                        .succeeded{
                            background-color: #EAF9F1;
                            color: #21A44A;
                        }
                        .payment_failed{
                            background-color: rgb(255 0 0 / 35%);
                            color: #2d2d2d;
                        }
                        .view-popup{
                            background-color: #929292;
                            border-radius: 64px;
                            letter-spacing: -0.03em;
                            color: #FFFFFF;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            padding: 7px 15px;
                            margin-left: 5px;
                            border: none;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            overflow:scroll;
        }
   
   
   
    }
`;