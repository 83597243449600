import styled from "styled-components";

export const FooterMainStyle = styled.section`
  .footer-main-wapper {
    background-color: #131b27;
    padding-top: 6rem;
    padding-bottom: 4rem;
  }

    @media screen and (max-width: 991px){
        .footer-main-wapper {
            padding-top: 4rem;
            padding-bottom: 2rem;
        }
    }
    @media screen and (max-width: 570px) {

      .footer-main-wapper {

        .footer-top-wapper{

           .button-wapper{
            display: block;
            width: 100%;
          }


        }

      }

    }





`;
