    import styled from "styled-components";

export const BottomHeaderStyle = styled.section`
  .bottom-header {
    .navbar-expand-md {
      .mobile-search-wapper {
        display: none;
      }
      .navbar-brand {
        a {
          text-decoration: none;
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 800;
          font-size: 35px;
          line-height: 44px;
          display: flex;
          align-items: center;
          letter-spacing: -0.03em;
          text-transform: capitalize;
          color: #0070e0;
        }
      }
      .offcanvas {
        align-items: flex-end;
        padding-right: 2rem;

        .offcanvas-body {
          width: 92%;

          form {
            width: 34%;
            input {
              padding-right: 3rem;
              padding-left: 22px;
              height: 55px;
              background-color: #eaedf2;
              border-radius: 3px;
              border: 0px;

              :focus,
              :hover {
                border: none;
                border-color: transparent;
                box-shadow: none;
              }
            }
            .btn-check:checked + .btn,
            .btn-check:active + .btn,
            .btn:active,
            .btn.active,
            .btn.show {
              background-color: transparent;
              border-color: transparent;
              box-shadow: none;
            }
            button {
              position: relative;
              right: 55px;
              border: none;
              color: #6c757d;
              height: 55px;
              padding-right: 1rem;

              .iconify {
                margin-right: 10px;
                font-size: 23px;
              }
              :focus,
              :hover {
                background-color: transparent;
                border: none;
                border-color: transparent;
                box-shadow: none;
              }
            }
          }
          .navbar-nav {
            align-items: center;

            a {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              color: #535050;
              padding: 8px 17px;
              text-transform: capitalize;
              text-decoration: none;
            }
            .active {
              a {
                color: #0070e0;
              }
            }
            .notifications-icon {
              padding: 8px 10px;
              overflow: hidden;
              position: relative;
              .iconify {
                font-size: 22px;
              }
              .notification-count {
                background-color: red;
                color: white;
                font-size: 8px;
                border-radius: 50%;
                padding: 4px 4.5px;
                font-weight: bolder;
                position: absolute;
                top: 2px;
                left: 20px;
                height: 18px;
                width: 18px;
                text-align: center;
              }
            }
            .user-profile-wapper {
              // border: 1px solid;
              padding: 5px 7px;
              border-radius: 50px;
              background-color: #f0f0f0;

              .user-profile {
                font-size: 20px;
                vertical-align: sub;
              }
            }
            /*.nav-item.dropdown:hover .dropdown-menu {
                    display: block;
                }*/
            .dropdown {
              .dropdown-item {
                svg {
                  font-size: 24px;
                  margin-right: 5px;
                }
              }
              .dropdown-menu {
                background-color: #1355ad;
                border-radius: 0px;
                width: 233px;
                left: unset;
                right: 0;
                padding-top: 0;
                margin-top: 13px;
                width: 190px;

                ::before {
                  content: "";
                  position: absolute;
                }
                .dropdown-item {
                  font-family: "Plus Jakarta Sans";
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #ffffff;

                  :focus,
                  :hover {
                    background: #042759;
                  }
                }
                .active {
                  background: #042759;
                }
              }
            }
            .login-button {
              color: #222325 !important;
              font-size: 16px !important;
              text-decoration: none;

              .iconify {
                margin-right: 5px;
                color: #5e7a97;
                font-size: 23px;
                margin-top: -3px;
              }
              :focus,
              :hover {
                border-color: transparent;
                box-shadow: none;
              }
            }
            .joinnow-button {
              background-color: #ffde26;
              padding: 10px 45px;
              border-radius: 4px;
              border: none;
              color: #000;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              :hover {
                background-color: #0180ff;
                color: #fff;
              }
            }

            .black-menu {
              height: 48px;
              background-color: #c1c1c1;
              padding: 0px !important;
              width: 1px;
              margin: 0px 10px;
            }
          }
        }
      }
    }
  }

  .search-wapper {
    position: absolute;
    width: 34.7%;
    background-color: #ffff;
    z-index: 99;
    padding: 25px;
    left: 16rem;
  }

  @media screen and (max-width: 1414px) {
    .bottom-header {
      .navbar-expand-md {
        .offcanvas {
          padding-right: 1rem;
          .offcanvas-body {
            width: 95%;
            form {
              width: 41%;
            }
            .navbar-nav {
              a {
                font-size: 15px;
                padding: 7px 13px;
              }
              .login-button {
                font-size: 16px !important;
                .iconify {
                  font-size: 19px;
                }
              }
              .joinnow-button {
                font-size: 15px;
                padding: 7px 30px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .search-wapper {
      left: 11.1rem;
    }
    .cbFhxl .search-wapper {
    width: 27.7%;}
  }
    @media screen and (max-width: 1335px) {

            .bottom-header {
      .navbar-expand-md {
        .offcanvas {
          padding-right: 0rem;
          .offcanvas-body {
            width: 100%;

             form {
              width: 31%;
              input {
                min-width: 100%;
              }
            }
            
            .navbar-nav {
              a {
                font-size: 15px;
                padding: 7px 13px;
              }
              .login-button {
                font-size: 16px !important;
                .iconify {
                  font-size: 19px;
                }
              }
              .joinnow-button {
                font-size: 15px;
                padding: 7px 30px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    }

  @media screen and (max-width: 1100px) {
    .bottom-header {
      .navbar-expand-md {
        .offcanvas {
          padding-right: 5px;
          .offcanvas-body {
            width: 100%;
            form {
              width: 26%;
              input {
                min-width: 100%;
              }
            }
                 .navbar-nav {
              a {
                font-size: 12px;
              }
              .login-button {
                font-size: 14px !important;
                .iconify {
                  font-size: 15px;
                }
              }
              .joinnow-button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1041px) {
    .bottom-header {
      .navbar-expand-md {
        .offcanvas {
          .offcanvas-body {
            width: 99%;
          }
        }
      }
    }
    .search-wapper {
      left: 7rem;
      width: 80%;
    }
  }
  @media screen and (max-width: 931px) {
    .bottom-header {
      .navbar-expand-md {
        .offcanvas {
          .offcanvas-body {
            form {
              width: 25%;
            }
            .navbar-nav {
              a {
                font-size: 11px;
                padding: 7px 6px;
                display: inline;
              }
              .black-menu {
                margin: 0px 6px;
              }
              .login-button {
                font-size: 10px !important;
              }
              .joinnow-button {
                font-size: 10px;
                padding: 7px 20px;
              }
            }
          }
        }
        .navbar-toggler {
          background-color: #0070e0;
          padding: 2px 7px;
          border-radius: 3px;
          .iconify {
            color: #fff;
            font-size: 30px;
          }
          .iconify--mdi {
            display: none;
          }
          .iconify--mingcute {
            display: block;
          }
        }
        .collapsed {
          .iconify--mdi {
            display: block;
          }
          .iconify--mingcute {
            display: none;
          }
        }
      }
    }
    .search-wapper {
      left: 3rem;
      width: 90%;
    }
  }

  @media screen and (max-width: 767px) {
    .bottom-header {
      .navbar-expand-md {
        .mobile-search-wapper {
          display: block;
          form {
            input {
              padding-right: 3rem;
              padding-left: 22px;
              height: 55px;
              background-color: #eaedf2;
              border-radius: 3px;
              border: 0px;
              width: 100%;

              :focus,
              :hover {
                border: none;
                border-color: transparent;
                box-shadow: none;
              }
            }
            .btn-check:checked + .btn,
            .btn-check:active + .btn,
            .btn:active,
            .btn.active,
            .btn.show {
              background-color: transparent;
              border-color: transparent;
              box-shadow: none;
            }
            button {
              position: relative;
              right: 55px;
              border: none;
              color: #6c757d;
              height: 55px;
              padding-right: 1rem;

              .iconify {
                margin-right: 10px;
                font-size: 23px;
                margin-top: -1px;
              }
              :focus,
              :hover {
                background-color: transparent;
                border: none;
                border-color: transparent;
                box-shadow: none;
              }
            }
          }
        }
        .offcanvas-body {
          form {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 539px) {
    .bottom-header {
      .navbar-expand-md {
        .navbar-brand {
          margin-right: 0px;
        }
        .mobile-search-wapper {
          width: 60%;
          form {
            position: relative;
            overflow: hidden;

            button {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
    .search-wapper {
      left: 1rem;
      width: 90%;
    }
  }
  @media screen and (max-width: 467px) {
    .bottom-header {
      .navbar-expand-md {
        .mobile-search-wapper {
          width: 55%;
        }
      }
    }
  }
  @media screen and (max-width: 369px) {
   
    
    
    
    
    .bottom-header {
      .navbar-expand-md {

      .navbar-brand a {
        font-size: 20px;
    }

        .mobile-search-wapper {
          width: 50%;
        }
      }
    }
  }
`;
