import styled from 'styled-components';


export const GettingStartedStyle = styled.section`

    .gettingstarted-main-wapper{
        background-color: #EEF4FA;
        .gettingstarted-main-inner{
            .gettingstarted-main-title{
                display: flex;
                align-items: center;
            }
        }
    }


`;