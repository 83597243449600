import React, { useState, useEffect, useCallback } from "react";
import { NotificationsStyle } from "./NotificationsStyle";
import { Button, Stack, Image } from "react-bootstrap";
import PageHeading from "../../common/PageHeading/PageHeading";
import ExportApi from "../../../Api/ExportApi";
import TimeAgo from "react-timeago";
import { useDispatch } from "react-redux";
import {
  savejoinAuction,
  saveNotification,
} from "../../../Redux/Slices/TransactionSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { BulletList } from "react-content-loader";
import Paginations from "../../common/Pagination/Paginations";
import { createGlobalStyle } from "styled-components";
import ConfirmationBox from "../../ConfirmationBox/ConfirmationBox";
import { useNavigate as newuseNavigate } from "react-router-dom";

const NotificationsFN = () => {
  const [totalCount, setTotalCount] = useState([]);
  const [ndata, setNdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [auctionData, setAuctionData] = useState();

  const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Notification API Call
  const NotificationData = () => {
    ExportApi.Notification(currentPage, itemsPerPage)
      .then((res) => {
        if (res.data) {
          setTotalCount(res.data.meta);
          setNdata(res.data.data);
          setLoader(false);
          dispatch(saveNotification(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  let Navigate = newuseNavigate();
  const CheckValidTokenn = () => {
    if (!localStorage.getItem("Token")) {
      localStorage.removeItem("Token");
      localStorage.removeItem("User_ID");
      window.dispatchEvent(new Event("EventLength"));
      Navigate("/login");
    }
  };

  useEffect(() => {
    CheckValidTokenn();
    setLoader(true);
    NotificationData();
  }, [currentPage, itemsPerPage]);

  // const handleJoin = useCallback((id) => {
  //   const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  //     if (localStorage.getItem("Token")) {
  //       ExportApi.JoinAuction(id, date)
  const handleJoin = (i) => {
    setAuctionData(i);
    setModalVisible(true);
  };

  const handleJoinSuccess = (couponCode) => {
    const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setModalVisible(false);
    if (localStorage.getItem("Token")) {
      ExportApi.JoinAuction(auctionData.id, date, couponCode)
        .then((resp) => {
          if (resp.data.success) {
            toast.success("Joined Succesfully");
            localStorage.setItem("Auctionid", "");
            navigate("/myauctions");
            dispatch(savejoinAuction(resp));
          } else if (resp.data.data.error === "Previous Due Payment") {
            navigate("/addfunds", {
              state: {
                amount: resp.data.message?.due_payment[0].winning_price,
                auction_id: resp.data.message?.due_payment[0].auction_id,
                currency_name: resp.data.message?.due_payment[0].currency_name,
                currencySymbol:
                  resp.data.message?.due_payment[0].custom_currency_symbol,
                vat_price: resp.data.message?.due_payment[0].vat_price,
                total_price: resp.data.message?.due_payment[0].total_price,
                coupon_code: couponCode,
              },
            });
            dispatch(savejoinAuction(resp));
            toast.error(resp.data.data.error);
          } else if (resp.data.data.error) {
            navigate("/packages", {
              state: {
                amount: resp.data.message.amount_required,
                type: "wallets",
                auctionId: auctionData?.auction_id,
              },
            });

            dispatch(savejoinAuction(resp));
            toast.error(
              "Amount required to join: $ " + resp.data.message.amount_required
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(savejoinAuction(null));
        });
    } else {
      navigate("/login");
    }
  };

  const handleChange = (i) => {
    ExportApi.readNotificarion(i.id, date, localStorage.getItem("Token"))
      .then((res) => {
        if (res) {
          window.dispatchEvent(new Event("EventLength"));
        }
        navigate(`/details/${i.slug}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalCount?.total / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <NotificationsStyle>
      <div className="notifications-page-title">
        <div className="pt-4">
          <PageHeading pagetitle={`Notifications`} />
        </div>
      </div>

      <div className="notifications-main mb-5">
        <div className="notifications-history-wapper">
          {loader ? (
            <BulletList />
          ) : (
            ndata?.map((i) => {
              return (
                <div
                  key={i.id}
                  className={`notifications-history-item  ${i.view_status}`}
                >
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="cstmblock"
                    onClick={() => handleChange(i)}
                  >
                    <div className="notifications-image-wapper">
                      <Image src={i.image} className="notifications-image" />
                    </div>
                    {console.log(i, new Date(), "i")}

                    <div className="notifications-content-wapper me-auto">
                      <div className="notifications-content-time">
                        <span className="mt-1"> Auction: {i.auction_time}</span>
                      </div>
                      <div className="notifications-content">
                        <h4> {i.description}</h4>
                      </div>
                      <div className="notifications-content-price">
                        <strong>
                          {i?.currency}
                          {i?.price}
                        </strong>
                      </div>
                    </div>

                    <div className="notifications-time-wapper ">
                      <span>
                        <TimeAgo date={i.updated_at} />
                      </span>
                    </div>
                  </Stack>
                  <div className="notifications-content-action ">
                    {i.notification_type === "5" && i.is_joined === 0 ? (
                      <Button
                        onClick={() => handleJoin(i)}
                        // onClick={onAuctionJoinHandler}
                        variant="primary"
                      >
                        Join
                      </Button>
                    ) : null}
                  </div>
                </div>
              );
            })
          )}
          {ndata?.length <= 0 && <h3 className="text-center">No Data Found</h3>}
        </div>
        {/* {ndata?.length > 1 && !(totalCount?.total === itemsPerPage) && ( */}
        <Paginations
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalCount?.total}
          paginate={paginate}
        />
        {/* )} */}
      </div>
      {modalVisible && (
        <ConfirmationBox
          isVisible={modalVisible}
          onChangeVisible={setModalVisible}
          auctionData={auctionData}
          onJoinAuction={handleJoinSuccess}
        />
      )}
    </NotificationsStyle>
  );
};

export default NotificationsFN;
