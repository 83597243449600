import * as React from "react";
import "./LoginStyle.css";
import "bootstrap/dist/css/bootstrap.css";
import LoginMain from "../../Components/Login/LoginMain";
import MetaTags from "../../Components/common/MetaTags";

const LoginMainFN = () => {
  return (
    
    <div className="login-wapper">
      <MetaTags
      title={'Upcoming Auctions | Yolop - Bid on Exclusive Items and Luxury Products'}
      description={"Stay updated with the latest upcoming auctions at Yolop. Explore a wide range of exclusive items and luxury products available for bidding. Don't miss out on the opportunity to win coveted fashion pieces, accessories, and more."}
      focusKeyphrase={'Login'}
/>
      <LoginMain />

    </div>
  );
};

export default LoginMainFN;
