import React, { useEffect, useState } from "react";
import { OTPFormStyle } from "./OTPFormStyle";
import FormsSidebar from "../../common/FormsSidebar/FormsSidebar";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import moment from "moment";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import { loader } from "../../../loader";
import { useLocation } from "react-router-dom";
import PageTitle from "../../common/PageHeading/PageHeading";

const OTPForm = (props) => {
  const checkPropsTpye = props?.type?.type === "verifyPhone";
  // const ForgetMobiledata = useSelector((state) => state.APIData.ForgetMobileNumber);
  let navigate = useNavigate();
  const location = useLocation();
  const locationStateData = location?.state?.type === "verifyOtp";
  const locationStateIsEmail = location;
  const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const getMobileNumber = localStorage.getItem("mobile");
  const getEmail = localStorage.getItem("email");
  const getToken = localStorage.getItem("Token");
  const getUserId = localStorage.getItem("User_ID");
  const [OTP, setOTP] = useState("");
  const [passwordError, setPasswordError] = useState();
  // const [isActive, setIsActive] = useState(false);
  // const [first1, setfirst1] = useState();
  // const [Last1, setLast] = useState();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  // const [verifyMode, setVerifyMode] = useState();
  let Params = useParams();

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(9);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  if (props?.number) {
    localStorage.setItem("mobile", props?.number);
  }

  // const emailInput = useRef(null);
  // const handleClick = (event) => {
  //   // 👇️ toggle isActive state on click
  //   setIsActive((current) => !current);
  // };

  const submitHandle = (e) => {
    console.log("working");
    e.preventDefault();
    const timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    loader("show");
    if (location?.state?.email || getEmail) {
      ExportApi.OTPSendEmail(
        OTP,
        timezone,
        location?.state?.email || getEmail,
        location?.state?.token || getToken,
        location?.state?.id || getUserId
      )
        .then((resp) => {
          if (resp.data.success === true) {
            toast.success(resp.data.message, { toastId: "otp-message" });
            localStorage.setItem("active", 1);
            setPasswordError(false);
            if (resp.data?.data?.token) {
              localStorage.setItem("Token", resp.data?.data?.token);
              navigate("/dashboard");
            } else {
              navigate("/resetpassword/email", {
                state: {
                  email: getEmail || location?.state?.email,
                },
              });
            }
          } else {
            toast.error(resp.data?.data?.error, { toastId: "otp-error" });
            setPasswordError(resp.data?.data?.error);
          }
        })
        .catch((err) => loader("hide"));
    } else if (location?.state?.phone || props?.phone || getMobileNumber) {
      ExportApi.OTPSendMobile(
        OTP,
        timezone,
        location?.state?.phone || props?.phone || getMobileNumber
      )
        .then((resp) => {
          if (resp.data.success) {
            toast.success(resp.data.message, { toastId: "otp-success" });
            if (checkPropsTpye) {
              props?.type?.VerifyPopup(false);
            } else {
              if (locationStateData) {
                navigate("/resetpassword/phone", {
                  state: {
                    phone: getMobileNumber || location.state.phone,
                  },
                });
              } else {
                navigate("/login");
              }
            }

            setPasswordError(false);
          } else {
            props?.type?.VerifyPopup(false);
            toast.error(resp.data?.data?.error, { toastId: "otp-error" });
            setPasswordError(resp.data?.data?.error);
          }
        })
        .catch((err) => loader("hide"));
    }
    loader("hide");
  };

  const resendOTP = (e) => {
    const timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    e.preventDefault();
    if (Params.id === "mobile") {
      const phoneNumber = location?.state?.phone || getMobileNumber;
      ExportApi.OTPSendResetMobile(phoneNumber, timezone)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.success === true) {
              toast.success(resp.data.message, { toastId: "otp-message" });
              window.dispatchEvent(new Event("EventLength"));
            }
          }
        })
        .catch((err) => console.log(err));
    } else if (location?.state?.email || getEmail) {
      const userEmail = location?.state?.phone || getEmail;

      let timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      ExportApi.OTPSendResetEmail(userEmail, timezone)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.success === true) {
              toast.success(resp.data.message);
              window.dispatchEvent(new Event("EventLength"));
              //  navigate("/dashboard")
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      ExportApi.VerifyPhone(
        props?.number,
        date,
        localStorage.getItem("Token")
      ).then((res) => {
        if (res.data) {
          toast.success("OTP Sent successfully");
        } else {
          toast.error("Something went wrong ");
        }
      });
    }
  };

  useEffect(() => {
    loader("hide");
  }, [location.state]);

  return (
    <>
      {props?.phone === undefined && (
        <PageTitle
          pagetitle={
            location?.state?.type ? "Verify Otp" : "Verify Your Account"
          }
        />
      )}

      <OTPFormStyle>
        <div className="otpform-form-wapper">
          <Container>
            <Row>
              <Col sm={12} md={6} className="form-sidebar">
                <div className="form-sidebar-inner">
                  <FormsSidebar
                    sidebartitle={"Yolop OTP"}
                    sidebarcontent={`<strong>Secure</strong> & <strong>Transparent</strong><br/> Bidding with Yolop`}
                    sidebarimage={
                      "https://res.cloudinary.com/oneworldtechnologies/image/upload/v1662533364/Yolop/login_1_sgz89z.png"
                    }
                  />
                </div>
              </Col>

              <Col sm={12} md={6} className="otpform-form">
                <div className="otpform-form-inner">
                  <Form onSubmit={submitHandle}>
                    <Form.Group
                      className="mb-4 mt-3 otpform-signup otpform-top"
                      controlId="formBasicCheckbox"
                    >
                      <p className="text-center">
                        Enter 4 digit verification code sent to
                        <br /> your &nbsp;
                        {locationStateIsEmail ? (
                          <>
                            email &nbsp;
                            <strong>
                              {location.state?.email?.slice(0, 2)}***********
                              {location.state?.email?.slice(-10)}
                            </strong>
                          </>
                        ) : (
                          <>
                            Mobile &nbsp;
                            <strong>
                              {location.state?.phone
                                ? location.state?.phone?.slice(0, 2)
                                : props?.phone?.toString().slice(0, 2)}
                              *****
                              {location.state?.phone
                                ? location.state?.phone?.slice(-3)
                                : props?.phone?.toString().slice(-3)}
                            </strong>
                          </>
                        )}
                      </p>
                    </Form.Group>
                    <div className="otp-inputs mt-5">
                      <OTPInput
                        className="otp-input-root"
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                      />
                      <div style={{ color: "red" }}> {passwordError}</div>
                    </div>
                    <Form.Group
                      className="mb-1 mt-5 forgotpassword-signup"
                      controlId="formBasicCheckbox"
                    >
                      <p className="text-center">
                        <span className="otpcounter">
                          {minutes === 0 && seconds === 0 ? (
                            <Link
                              to={"#"}
                              onClick={(e) => {
                                resendOTP(e);
                              }}
                            >
                              Resend OTP
                            </Link>
                          ) : (
                            <>
                              {" Resend OTP  "} {minutes} :
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </>
                          )}
                        </span>
                      </p>
                    </Form.Group>
                    <Button
                      className="login-button mt-2"
                      variant="primary"
                      type="submit"
                    >
                      Verify
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </OTPFormStyle>
    </>
  );
};

export default OTPForm;
