import styled from "styled-components";

export const OTPFormStyle = styled.section`
  .otpform-form-wapper {
    width: 55%;
    margin: 0 auto;

    .form-sidebar {
    }

    .otpform-form {
      .otpform-form-inner {
        form {
          .verify-wapper {
            .btn-check:checked + .btn,
            .btn-check:active + .btn,
            .btn:active,
            .btn.active,
            .btn.show {
              border-color: #f2c94c;
            }
            .btn-check:focus + .btn,
            .btn:focus,
            .btn-check:checked + .btn:focus,
            .btn-check:active + .btn:focus,
            .btn:active:focus,
            .btn.active:focus,
            .btn.show:focus {
              box-shadow: none !important;
            }
            label {
              width: 100%;
              margin-bottom: 25px;
              background-color: transparent;
              color: black;
              border: 2px solid #c9d2dc;
              display: flex;
              align-items: center;
              flex-direction: row;
              flex-wrap: nowrap;
              padding: 20px;

              .icon {
                height: 5rem;
                background-color: black;
                color: #f8c617;
                font-size: 2rem;
                border-radius: 50%;
                width: 5rem;
                svg {
                  vertical-align: middle;
                  height: 100%;
                }
              }
              .info {
                text-align: left;
                padding-left: 20px;
              }
            }
          }
          .otp-inputs {
            .otp-input-root {
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;

              input {
                width: 70px !important;
                height: 70px !important;
                border: none;
                border-bottom: 2px solid #98b3cf;
                color: #040404;
                font-family: "Plus Jakarta Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 33px;
                line-height: 42px;
                margin-bottom: 5px;
              }
            }
          }
          .forgotpassword-signup {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #403e3e;
            a {
              text-decoration: none;
              :hover {
                color: #000;
              }
              svg {
                font-size: 22px;
              }
            }
          }
          .login-button {
            height: 50px;
            border-radius: 4px;
            background-color: #0180ff;
            border: 0px;
            width: 100%;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
          }
       
          @media screen and (max-width: 1024px){
            .otp-inputs {
              .otp-input-root {
                input {
                  width: 50px !important;
}

              }
            }
      
          }
       
       
       
       
       
        }
      
      }
    }
    @media screen and (max-width: 1300px){
      width: 100%;

    }
  
  
  }
`;
