import styled from 'styled-components';


export const ChangePasswordMainStyle = styled.section`
    .editpagemain-page-title{
        background-color: #EDF9FE;
        .page-title-wapper{
            padding-right: 20rem;
            
            .page-title{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 34px;
                    color: #3D3838;
                }
            }
            .page-breadcrumb-wapper{
                .breadcrumb {
                    a{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 30px;
                    }
                }
            }
            @media screen and (max-width: 1400px) {
                padding-right:4rem;}
       
        }
        @media screen and (max-width: 767px) {
            display:none;
        }
   
    }
    .editpagemain-main{
        padding-right: 16rem;
        padding-left: 1rem;
        
        .editpage-main-wapper{
            padding: 20px;
            .info-change-wapper{
                 .password{
                    position: relative;

                    label{
                         display: flex;
                         flex-direction: row;
                         align-items: flex-end;
                         svg{
                              font-size: 20px;
                         }
                    }
                    .passshow{
                         position: absolute;
                         right: 43px;
                         top: 47px;
                    }
                    
               }
                .form-label{
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 19px;
                    color: #313131;
                    
                    span{
                        color:#FF0707;
                    }
                }
                .form-control{
                    background-color: #FAFCFF;
                    border: 1px solid #B1C0CC;
                    border-radius: 4px;
                    height: 52px;
                    margin-bottom: 15px;
                }
            }
            button{
                background-color: #0180FF;
                border-radius: 4px;
                font-family: 'Plus Jakarta Sans';
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                color: #FFFFFF;
                padding: 15px 20px;
                width:284px;
            }
            
        }
        @media screen and (max-width: 767px) {
            padding-right: 1rem;
        }
   
   
    }

`;