import styled from "styled-components";

export const PaginationStyle = styled.section`
  .pagination-wapper {
    .pagination {
      display: flex;
      justify-content: center;
      .page-item {
        .page-link {
          font-family: "Plus Jakarta Sans";
          font-weight: 500;
          font-size: 15px;
          line-height: 25px;
          background-color: transparent;
          border-radius: 4px;
          padding: 7px 17px;
          margin-right: 10px;
          color: #000000;
        }
      }
      .prev {
        .page-link {
          background-color: #7f909d;
          color: white;
        }
      }
      .active {
        .page-link {
          background-color: #0180ff;
          color: white;
        }
      }
    }
  }
`;
