import styled from 'styled-components';


export const GettingStartedCardStyle = styled.section`

    .gettingstarted-card-wapper{
        .card-group{

            .mid-card.card{
                margin-top:5rem;
            }

            .card{
                background-color: transparent;
                border: none;

             
                .card-title{
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 29px;
                    color: #000000;
                }
                .card-text{
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    color: #223A52;
                }

                .icon-wapper {
                    width: 13rem;
                    padding: 2rem;
                    text-align: center;
                    background-color: white;
                    border-radius: 50%;
                    font-size: 90px;
                    color: #CDAE00;
                    font-weight: bold;
                }
                .card-body{
                    padding-left:0px;
                }

            }   
    
        
            @media screen and (max-width: 991px){

                margin-top:20px;
                .mid-card.card{
                    margin-top:0rem;
                }

                .first-card.card{
                    text-align:center;
                }

                .mid-card.card{
                    margin-top:0rem;
                    text-align:center;
                }

                .last-card.card{
                    text-align:center;
                }
        
                .icon-wapper {
                    margin:auto;
                }

            }
        
        } 

    }

`;