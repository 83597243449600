import styled from 'styled-components';


export const BlogStyle = styled.section`

    .blog-main-wapper{
        .blog-main-inner{
            
        }
        @media screen and (max-width:  570px) {
            .section-title-wapper{
            .section-title{
                width: 70%;
            }
        }
            
        }
    }
    


`;