import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyAcH83y9hPPNaU2OM8sfuCgnYVpbS8H-8I",
  authDomain: "yolop-a8a75.firebaseapp.com",
  projectId: "yolop-a8a75",
  storageBucket: "yolop-a8a75.appspot.com",
  messagingSenderId: "836698649249",
  appId: "1:836698649249:web:59135eaf7fe59ea7ebb6e0",
  measurementId: "G-0KWCNXMN71",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const notificationBrowserSupportCheck = () => {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    console.log("This browser does not support desktop notification");
  }
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey:
            "BJAkoccrPyYAmu34WGwl56Erd47ezK0j_uD1ENolHS1G9-h_Uk4SIwlgEfiigsiODMWkHSeUnYyy6wMIalWPSaU",
        }).then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("Fcm_Token", currentToken);
          } else {
            console.log("Can not get token");
          }
        });
      } else {
        console.log("Do not have permission!");
      }
    });
  }

}

// export const requestPermission = () => {
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       getToken(messaging, {
//         vapidKey:
//           "BJAkoccrPyYAmu34WGwl56Erd47ezK0j_uD1ENolHS1G9-h_Uk4SIwlgEfiigsiODMWkHSeUnYyy6wMIalWPSaU",
//       }).then((currentToken) => {
//         if (currentToken) {
//           localStorage.setItem("Fcm_Token", currentToken);
//         } else {
//           console.log("Can not get token");
//         }
//       });
//     } else {
//       console.log("Do not have permission!");
//     }
//   });
// };

export const onMessageListener = (props) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      if (payload.notification.title === 'Lucky Draw Winner') {
        props.onChangeValue(true)
      }
    });
  });
