import React, { useEffect, useState } from "react";
import { NoLiveAuctionStyle } from "./NoLiveAuctionStyle";
import { Col, Button, Alert } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import CountdownDate from "../../../../common/AuctionsCardList/CountdownDate";
import { useSelector } from "react-redux";
import ExportApi from "../../../../../Api/ExportApi";

const NoLiveAuction = () => {
  const [AuctionTime, setAuctionTime] = useState()
  useEffect(()=> {
    ProcessingLive()
  }, [setAuctionTime])

  const ProcessingLive = () => {
    ExportApi.ProcessingLive().then((res) => {
      setAuctionTime(res.data.data)
    });
  };

  return (
    <NoLiveAuctionStyle>
      <div className="nolive-auction-wapper">
        <Alert variant="danger" className="nolive-auction-alert">
          <div className="icon-wapper">
            <Icon icon="ri:auction-fill" />
          </div>
     <div className="content-wapper">
            <Alert.Heading>No Live Auction Yet</Alert.Heading>
        {AuctionTime?.length > 0 && <p>
              {" "}
              {"Your Next Auction start with in"}
              <strong>
                <CountdownDate time={AuctionTime[0]?.global_start_time} />
              </strong>
            </p>}
          </div>
          <div className="button-wapper">
            <Link to="/myauctions">
              <Button variant="dark">My Upcomming Auctions</Button>
            </Link>
          </div>
        </Alert>
      </div>
    </NoLiveAuctionStyle>
  );
};

export default NoLiveAuction;
