import React from "react";
import { Card } from "react-bootstrap";
import { TopProfileStyle } from "./TopProfileStyle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const  TopProfile = () => {
    const pdata = useSelector((state) => state.APIData.Profiledata) 


  return (
    <TopProfileStyle>
      <div className="top-profile-wapper">
        <Card className="profile-cart ">
          <div className="image-wapper mt-5">
            {
            pdata.image ? (
                <Card.Img variant="top" id="imgViewwTop" src={pdata?.image} />
              ) : (
                "NAN"
              )
              // <Card.Img variant="top" id="imgViewwTop" src={"https://dummyimage.com/250x250/e7e7e7/c9c9c9.jpg&text=Yolop+Dummy+image+holder.js/100px180"} />
            }
          </div>
          <Card.Body className="mb-4">
            <Card.Text>Welcome</Card.Text>
            <Card.Title className="profile-name">
              {pdata ? pdata?.user_details?.first_name : "Hii User"}
            </Card.Title>
            <Card.Title className="profile-total-ammount">
              <span className="yolopian-currency">
              {pdata?.view_currency} </span>{pdata ? pdata?.balance : "Loding"}
            </Card.Title>
            {pdata?.user_details?.status=== '2' &&  
            <Card.Text>Your Acount has been Deactivated!</Card.Text> }

            <Link to={"/packages"} className="mt-3 button btn btn-primary">
              Add Funds
            </Link>
          </Card.Body>
        </Card>
      </div>
    </TopProfileStyle>
  );
}

export default TopProfile;
