import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { LoginFormStyle } from "./LoginFormStyle";
import FormsSidebar from "../../common/FormsSidebar/FormsSidebar";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import ExportApi from "../../../Api/ExportApi";
import { toast } from "react-toastify";
import moment from "moment";
import { loader } from "../../../loader";
import "./log.css";
import alert from "./alert.png";
import { TimeZone } from "../../common/DateFormat/DateFormate";
import { useDispatch } from "react-redux";
import { saveUserLogin } from "../../../Redux/Slices/TransactionSlice";

const LoginForm = () => {
  let Navigate = useNavigate();

  const dispatch = useDispatch();
  const [IsEmail, setIsEmail] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [mpassword, setMpassword] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [Error, setError] = useState(false);
  const [alert, setAlert] = useState();
  const RememberMeData = JSON.parse(localStorage.getItem("RememberMe"));
  let auctionid = localStorage.getItem("Auctionid");
  let devicetoken = localStorage.getItem("Fcm_Token");
  let coupon_code = localStorage.getItem("coupon_code");
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const formik = useFormik({
    initialValues: {
      email: RememberMeData ? RememberMeData.email : "",
      password: RememberMeData ? RememberMeData.password : "",
      RememberMe: RememberMeData ? RememberMeData.RememberMe : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(4, "Password must be 6 characters long")
        .required("Enter your password"),
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Enter your email"),
    }),

    onSubmit: (values) => {
      loader("show");
      let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let timezone = TimeZone(new Date());

      let devicetype = "3";
      ExportApi.UserLogin(
        values.email,
        values.password,
        date,
        timezone,
        devicetoken,
        devicetype,
        auctionid,
        coupon_code
      )
        .then((resp) => {
          localStorage.setItem("email", formik.values.email);
          if (resp.data) {
            if (resp.data.success === true) {
              localStorage.setItem("Token", resp.data.data.token);
              setAlert(resp.data.message);
              toast.success(resp.data.message);
              dispatch(saveUserLogin(resp.data));
              loader("hide");
              setError(false);
              if (auctionid) {
                ExportApi.JoinAuction(auctionid, date, coupon_code).then(
                  (res) => {
                    if (res.data.data.error) {
                      console.log(res.data.data.error, "this error");
                      toast.warning(res.data.data.error);
                      localStorage.removeItem("Auctionid");
                      localStorage.removeItem("coupon_code");
                      Navigate("/");
                    } else if (resp.data.data.error) {
                      console.log(resp.data.data.error, "this error");
                      if (resp.data.data.error === "Invalid coupon.") {
                        toast.error(resp.data.data.error);
                      } else if (
                        resp.data.data.error === "Coupon already used."
                      ) {
                        toast.error(resp.data.data.error);
                      } else {
                        toast.error(
                          "Amount required to join: $ " +
                            resp.data.message.amount_required
                        );
                      }
                    } else {
                      toast.success("Your Auction Joined Successfully");
                      Navigate("/myauctions");
                    }
                  }
                );
              }
              if (values.RememberMe) {
                localStorage.setItem("RememberMe", JSON.stringify(values));
                // setCheck(e.target.checked);
              }

              window.dispatchEvent(new Event("EventLength"));
              Navigate("/", { state: { alert: "alert" } });
            } else {
              if (resp.data.data.status === 3) {
                setError(resp.data.data.error);
                setIsOpen(true);
                loader("hide");
              } else if (resp.data.data.status === 0) {
                toast.error(resp.data.data.error, {
                  toastId: "forgot-password-error",
                });
                Navigate("/verification/email");
                loader("hide");
              } else {
                toast.error(resp.data.data.error);
                loader("hide");
              }
            }
          }
        })
        .catch((err) => console.log(err));
    },
  });
  const forgetPasswordHandle = () => {
    Navigate("/forgot-password");
  };

  const submitHandle = () => {
    localStorage.setItem("mobile", value);
    let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let timezone = TimeZone(new Date());

    if (value?.length > 9) {
      if (mpassword?.length < 4) {
        toast.error("Password must be 6 characters long");
      } else if (value?.length < 10) {
        toast.error("Please enter valid Number", {
          toastId: "forgot-password-validnumber",
        });
      } else if (mpassword === undefined) {
        toast.error("Please Enter Password");
      } else {
        loader("show");
        ExportApi.UserLoginWithMobile(value, mpassword, date, timezone)
          .then((resp) => {
            if (resp.data) {
              if (resp.data.success === true) {
                toast.success(resp.data.message, {
                  toastId: "success-message",
                });
                localStorage.setItem("Token", resp.data.data.token);
                loader("hide");
                setError(false);
                if (auctionid) {
                  ExportApi.JoinAuction(auctionid, date, coupon_code).then(
                    (res) => {
                      if (res.data.data.error) {
                        console.log(res.data.data.error, "this error");
                        toast.warning(res.data.data.error);
                        localStorage.removeItem("Auctionid");
                        localStorage.removeItem("coupon_code");
                        Navigate("/");
                      } else if (resp.data.data.error) {
                        console.log(resp.data.data.error, "this error");
                        if (resp.data.data.error === "Invalid coupon.") {
                          toast.error(resp.data.data.error);
                        } else if (
                          resp.data.data.error === "Coupon already used."
                        ) {
                          toast.error(resp.data.data.error);
                        } else {
                          toast.error(
                            "Amount required to join: $ " +
                              resp.data.message.amount_required
                          );
                        }
                      } else {
                        toast.success("Your Auction Joined Successfully");
                        Navigate("/myauctions");
                      }
                    }
                  );
                }
                // if (RememberMeData) {
                //   localStorage.setItem("RememberMe", JSON.stringify(values));
                //   // setCheck(e.target.checked);
                // }
                window.dispatchEvent(new Event("EventLength"));
                Navigate("/dashboard");
              } else {
                if (resp.data.data.status === 3) {
                  setError(resp.data.data.error);
                  setIsOpen(true);
                  loader("hide");
                } else if (resp.data.data.status === 0) {
                  toast.error(resp.data.data.error, {
                    toastId: "forgot-password-error",
                  });
                  Navigate("/verification/mobile");
                  loader("hide");
                } else {
                  toast.error(resp.data.data.error, {
                    toastId: "forgot-password-error",
                  });
                  // Navigate("/verification/mobile");

                  loader("hide");
                }
              }
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      toast.error("Please enter valid Number", {
        toastId: "forgot-password-valid-number",
      });
    }
  };

  return (
    <LoginFormStyle>
      <div className="login-form-wapper">
        <Container>
          <Row>
            <Col sm={12} md={6} className="form-sidebar">
              <div className="form-sidebar-inner">
                <FormsSidebar
                  sidebartitle={"Yolop Login"}
                  sidebarcontent={`<strong>Bid Securely </strong> and <strong>Transparently</strong><br/>with Yolop`}
                  sidebarimage={"/images/login-icon.png"}
                />
              </div>
            </Col>

            <Col sm={12} md={6} className="login-form">
              <div className="login-form-inner">
                <Form onSubmit={formik.handleSubmit}>
                  {IsEmail ? (
                    <>
                      <Form.Group
                        className="mb-3 email"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          <Icon icon="fluent:mail-16-filled" />
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type={"email"}
                          name="email"
                          placeholder="Email@domain.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="mb-3 password">
                        <Form.Label>
                          <Icon icon="fa:lock" />
                          Password
                        </Form.Label>
                        <Form.Control
                          id="passInput"
                          name="password"
                          placeholder="Password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          type={isActive ? "text" : "password"}
                        />
                        <Icon
                          className="passshow"
                          onClick={handleClick}
                          icon={
                            isActive
                              ? "akar-icons:eye-slashed"
                              : "akar-icons:eye"
                          }
                        />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group
                        className="mb-3 mobile"
                        controlId="formBasicPhone"
                      >
                        <Form.Label>
                          <Icon icon="ion:phone-portrait-outline" />
                          Mobile Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="123 456 789 0"
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 password">
                        <Form.Label>
                          <Icon icon="fa:lock" />
                          Password
                        </Form.Label>
                        <Form.Control
                          type={isActive ? "text" : "password"}
                          // id="passInput"
                          name="mpassword"
                          placeholder="Password"
                          value={mpassword}
                          onChange={(e) => setMpassword(e.target.value)}
                        />
                        <Icon
                          className="passshow"
                          onClick={handleClick}
                          icon={
                            isActive
                              ? "akar-icons:eye-slashed"
                              : "akar-icons:eye"
                          }
                        />
                      </Form.Group>
                    </>
                  )}

                  {IsEmail ? (
                    <>
                      {formik.touched.password && formik.errors.password ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.password}
                        </div>
                      ) : null}
                      <Button
                        className="login-button mt-2"
                        variant="primary"
                        type="submit"
                      >
                        Login
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="login-button mt-2"
                      variant="primary"
                      onClick={submitHandle}
                    >
                      Login with Mobile
                    </Button>
                  )}
                  <Form.Group
                    className="mb-3 mt-4 remember-forgot"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      name="RememberMe"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.RememberMe}
                      // checked={check}
                      label="Remember Me"
                    />
                    <Link
                      to={"/forgot-password"}
                      onClick={forgetPasswordHandle}
                    >
                      Forgot Password?
                    </Link>
                  </Form.Group>

                  <div className="divider mb-4 mt-4"> or </div>
                  {IsEmail ? (
                    <Button
                      className="loginphone-button mt-2"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setIsEmail(false);
                      }}
                    >
                      <Icon icon="ion:phone-portrait-outline" />
                      Login via Phone
                    </Button>
                  ) : (
                    <Button
                      className="loginphone-button mt-2"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setIsEmail(true);
                      }}
                    >
                      <Icon icon="fluent:mail-16-filled" />
                      Login with Email
                    </Button>
                  )}
                  <Form.Group
                    className="mb-2 mt-5 login-signup"
                    controlId="formBasicCheckbox"
                  >
                    <p className="text-center">
                      Don’t have an account ?
                      <Link to={"/signup"}>
                        Sign up? <Icon icon="bi:arrow-right" />
                      </Link>
                    </p>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={isOpen} className="send-confirm" id="resend-confirm">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsOpen(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <img
            style={{ marginTop: "50px" }}
            src={alert}
            alt=""
            loading="lazy"
          />
          <h4>{Error}</h4>

          <div className="modal-buttons">
            <a href="mailto:bablu.jakhar830@gmail.com?subject={YOLOP Acount}&body={Please My Acount Activeted}">
              <button
                type="button"
                className="btn btn-primary btn-filled"
                data-bs-dismiss="modal"
              >
                Click to Send an Email Admin
              </button>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </LoginFormStyle>
  );
};

export default LoginForm;
