import styled from 'styled-components';


export const UpcomingAuctionsStyle = styled.section`


    .upcomingAuctions-main-wapper{
       background-color: #FFF;

       .page-title-wapper{
            background-color: #EDF9FE;
            padding: 2rem 0px;
       }

       .yolop-upcoming-text{
                margin-bottom: 30px;
           
            h2{
                text-align: center;
                font-weight: 700;
                margin-bottom: 25px;
            }

            .read-more-state {
                    display: none;
                    }

                    .read-more-wrap{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        color: #223A52;
                        margin-top: 15px;
                        p{
                            margin-top: -10px;
                        }
                    }

                    .read-more-target {
                    opacity: 0;
                    max-height: 0;
                    font-size: 0;
                    transition: .25s ease;
                    }

                    .read-more-state:checked ~ .read-more-wrap .read-more-target {
                    opacity: 1;
                    font-size: inherit;
                    max-height: 999em;
                    }

                    .read-more-state ~ .read-more-trigger:before {
                    content: 'Show More';
                    }

                    .read-more-state:checked ~ .read-more-trigger:before {
                    content: 'Show Less';
                    }

                    .read-more-trigger {
                        cursor: pointer;
                        display: inline-block;
                        padding: 0 0.5em;
                        color: #0050c7;
                        font-size: .9em;
                        margin-top: -5px;
                        line-height: 2;
                        border: 2px solid #0050c7;
                        border-radius: 0.25em;
                        position: relative;
                        z-index: 100;
                    }

        }
       

    }


`;