import BottomHeader from "./BottomHeader/BottomHeader";

const HeaderMain = () => {
  return (
    <header className="main-header">
      {/* <TopHeader/> */}
      <BottomHeader />
    </header>
  );
};

export default HeaderMain;
