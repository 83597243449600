import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import SectionTitle from '../../common/SectionTitle/SectionTitle';
import { BlogStyle } from "./BlogStyle";
import BlogCard from "../../common/BlogCard/BlogCard";
import ExportApi from "../../../Api/ExportApi";

function Blog() {
    const [blogsData, setBlogsData] = useState<object>([])
    useEffect(() => {
        getBlogsData()
    }, [])
    const getBlogsData = () => {
        ExportApi.blogs(1, 3)
            .then((resp: any) => {
                if (resp.data) {
                    setBlogsData(resp?.data?.data)
                }
            })
            .catch((err) => {
                console.log(err)
            });
    };
    return (
        <BlogStyle>
            <section className="blog-main-wapper pt-5 pb-5">
                <Container>
                    <div className="blog-main-inner">
                        <SectionTitle
                            sectiontitle={"Keep Learning Keep Growing"}
                            sectionlinktext={"Read All"}
                            sectionlink={"/blogs"}
                            tabs={''}
                        />

                        <BlogCard BlogData={blogsData} />
                    </div>
                </Container>
            </section>
        </BlogStyle>
    );
}

export default Blog;
