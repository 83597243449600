import Container from "react-bootstrap/Container";
import SectionTitle from "../../common/SectionTitle/SectionTitle";
import AuctionsCard from "../../common/AuctionsCard/AuctionsCard";
import { useSelector } from "react-redux";
const UpcomingAuctions = () => {
    const data = useSelector((state) => state.APIData.Auctiondata.data);
    return (
        <section className="auctions-main-wapper pt-5 pb-5">
            <Container>
                <div className="auctions-inner" style={{ clear: 'both' }} >
                    <SectionTitle
                        sectiontitle={"Discover the Excitement of Upcoming Auctions at Yolop"}
                        sectionlinktext={"View All Auctions"}
                        sectionlink={"upcoming-auctions"}
                    />
                    {data ? <AuctionsCard AuctionsData={data.slice(0, 8)} logo={true} /> : null}
                </div>
            </Container>
        </section>
    );
};

export default UpcomingAuctions;
