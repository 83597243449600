import React from "react";
import {Helmet} from "react-helmet";
 
const MetaTags = ({title, description, focusKeyphrase, canonicalURL}) => {

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name="keywords" content={focusKeyphrase} />
                <link rel="canonical" href={canonicalURL} />
            </Helmet>
        </div>
    );
  }

export default MetaTags;
