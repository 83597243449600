import styled from 'styled-components';


export const BlogStyle = styled.section`

    .blog-wapper{
        .blog-wapper-heading{
            background-color: #EDF9FE;
            margin-bottom: 2rem;
        }
    }
     

`;