import { AddFundsMainStyle } from "./AddFundsMainStyle";
import PageHeading from "../../common/PageHeading/PageHeading";
import AddFundsForm from "../common/AddFundsForm/AddFundsForm.jsx";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const AddFundsMainFN = (props) => {
  const [fundType, setFundType] = useState("Add Funds");
  const location = useLocation();
  // const { , joinAuctionId, amount, auction_id, item, currency_name, currencySymbol } =location?.state
  const type = location?.state?.type;
  const joinAuctionId = location?.state?.joinAuctionId;
  const amount = location?.state?.amount;
  const auction_id = location?.state?.auction_id;
  const item = location?.state?.item;
  const currency_name = location?.state?.currency_name;
  const currencySymbol = location?.state?.currencySymbol;
  const vat_price = location?.state?.vat_price;
  const total_price = location?.state?.total_price;
  const coupon_code = location?.state?.coupon_code;
  // console.log(coupon_code, "coupeon_codecoupeon_code");
  useEffect(() => {
    if (amount) {
      setFundType("CheckOut");
    }
  }, []);

  return (
    <AddFundsMainStyle>
      <div className="addfunds-page-title">
        <div className="pt-4">
          <PageHeading pagetitle={`${fundType}`} />
        </div>
      </div>
      <div className="addfunds-main mb-3">
        <div className="addfunds-liveauctions-cart">
          <AddFundsForm
            type={type}
            joinAuctionId={joinAuctionId}
            auction_id={auction_id}
            currency_name={currency_name}
            amount={amount}
            item={item}
            currencySymbol={currencySymbol}
            vat_price={vat_price}
            total_price={total_price}
            coupon_code={coupon_code}
          />
        </div>
      </div>
    </AddFundsMainStyle>
  );
};

export default AddFundsMainFN;
