import BaseApi from "./BaseApi";
// import { CancelToken } from "apisauce";
//Auth
// const source = CancelToken.source();

const UserLogin = (
  email,
  password,
  date,
  timezone,
  devicetoken,
  devicetype,
  auctionid
) =>
  BaseApi.post("/login", {
    email: email,
    password: password,
    date_time: date,
    time_zone: timezone,
    device_token: devicetoken,
    device_type: devicetype,
    auction_id: auctionid,
  });

const UserLogout = (Token) =>
  BaseApi.post(
    "/logout",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );

const UserDelete = (Token) =>
  BaseApi.post(
    "/delete-account",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );

const UserLoginWithMobile = (mobile, password, date, time_zone) =>
  BaseApi.post("/login-phone", {
    mobile: mobile,
    password: password,
    date_time: date,
    time_zone: time_zone,
  });

const Register = (
  email,
  password,
  confirm_password,
  first_name,
  last_name,
  mobile,
  date_time,
  timezone,
  device_type,
  value,
  custonCountryCode,
  timeZone
) =>
  BaseApi.post(
    "/register",
    {
      email: email,
      password: password,
      confirm_password: confirm_password,
      first_name: first_name,
      last_name: last_name,
      mobile: mobile,
      date_time: date_time,
      time_zone: timezone,
      device_type: device_type,
      country_code: value,
      country_code_symbol: custonCountryCode,
      timeZone: timeZone,
    },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );

const OTPSendMobile = (otp, date, mobile, id) =>
  BaseApi.post(
    "/verification",
    {
      otp: otp,
      token: "",
      date_time: date,
      phone: mobile,
      id: id,
    },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );

const OTPSendEmail = (otp, date, email, toekn, id) =>
  BaseApi.post(
    "/verification",
    {
      otp: otp,
      email: email,
      date_time: date,
      token: toekn,
      id: id,
    },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const OTPSendResetEmail = (email, date) =>
  BaseApi.post(
    "/reset-otp",
    { email: email, date_time: date, type: "email" },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const ForgottEmail = (email, date) =>
  BaseApi.post(
    "/forgot-password",
    { email: email, date_time: date, type: "email" },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const ForgottMobile = (number, date) =>
  BaseApi.post(
    "/forgot-password",
    {
      phone: number,
      date_time: date,
      type: "phone",
    },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const ChangeForgottMobile = (phone, date, password, confirm_password) =>
  BaseApi.post(
    "/reset-password",
    {
      phone: phone,
      date_time: date,
      type: "phone",
      password: password,
      confirm_password: confirm_password,
    },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const ChangeForgottEmail = (email, date, password, confirm_password) =>
  BaseApi.post(
    "/reset-password",
    {
      email: email,
      date_time: date,
      type: "email",
      password: password,
      confirm_password: confirm_password,
    },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const OTPSendResetMobile = (email, date) =>
  BaseApi.post(
    "/reset-otp",
    { phone: email, date_time: date, type: "phone" },
    {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    }
  );
const Categories = (timeZone) =>
  BaseApi.get(
    "/categories",
    { timeZone: timeZone },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );
const Auctions = (category, currentPage, itemsPerPage, timeZone) =>
  BaseApi.get(
    `/auctions${category ? `?slug=${category}` : `?slug=${""}`} ${
      currentPage ? `&page=${currentPage}` : ""
    } ${itemsPerPage ? ` &limit=${itemsPerPage}` : ""} `,

    { timeZone: timeZone },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );
const getProfileData = () =>
  BaseApi.get(
    "/profile",
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );

const UpdateProfileData = (form) =>
  BaseApi.post("edit-profile", form, {
    headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
  });

const UpdateProfileDP = (form) =>
  BaseApi.post("/update-profileImage", form, {
    headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
  });

const ChangePassword = (data) =>
  BaseApi.post("/change-password", data, {
    headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
  });

const StripePayment = (data) =>
  BaseApi.post("/pay", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("Token")}`,
      "Content-Type": "application/json",
    },
  });

const Notification = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/notifications?page=${currentPage}&limit=${itemsPerPage}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const StripeTransaction = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/transactions${currentPage ? `?page=${currentPage}` : ""} ${
      itemsPerPage ? `&limit=${itemsPerPage}` : ""
    } `,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const AuctionChargesTransation = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/auction-charge${currentPage ? `?page=${currentPage}` : ""} ${
      itemsPerPage ? `&limit=${itemsPerPage}` : ""
    } `,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const AuctionWonTransation = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/purchases${currentPage ? `?page=${currentPage}` : ""} ${
      itemsPerPage ? `&limit=${itemsPerPage}` : ""
    } `,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const rewardsTransation = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/referral-rewards${currentPage ? `?page=${currentPage}` : ""} ${
      itemsPerPage ? `&limit=${itemsPerPage}` : ""
    } `,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const UpcomingAuctions = () =>
  BaseApi.get(
    "/upcoming-auctions",
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const ProcessingLive = () =>
  BaseApi.get(
    "/processinglive",
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );
const LiveAuction = () =>
  BaseApi.get(
    "/liveAuction",
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const BuyAuction = (
  auction_id,
  auction_price,
  winning_price,
  date_time,
  no_of_items,
  duration,
  discount
) =>
  BaseApi.post(
    "/buy-auction",
    {
      auction_id,
      auction_price: parseInt(auction_price),
      winning_price,
      date_time,
      no_of_items,
      duration,
      discount,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const CompletedAuctions = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/completed-auctions?page=${currentPage}&limit=${itemsPerPage}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );
const WonAuctions = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/won-auctions?page=${currentPage}&limit=${itemsPerPage}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const PrevWonAuctions = (timeZone) =>
  BaseApi.get(
    "/list-won-auctions",
    { timeZone: timeZone },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const CancelledAuctions = () =>
  BaseApi.get(
    "/cancelled-auctions",
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const JoinAuction = (auction_id, date_time, couponCode) =>
  BaseApi.post(
    "/join-auction",
    { auction_id: auction_id, date_time: date_time, coupon_code: couponCode },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );
const readNotificarion = (id, datetime, Token) =>
  BaseApi.post(
    "/notifications-status",
    { view_status: "2", id: id, date_time: datetime, Token: Token },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const SingleAuction = (slug, timeZone) =>
  BaseApi.get(
    `/auction/${slug}`,
    { timeZone: timeZone },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const VerifyPhone = (Mobile, datetime, Token) =>
  BaseApi.post(
    "/verify-phone",
    { mobile: Mobile, date_time: datetime },
    {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    }
  );

const Packages = (timeZone) =>
  BaseApi.get(
    `/packages`,
    { timeZone: timeZone },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const CheckToken = () =>
  BaseApi.get(
    `/check-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const contactUs = (
  name,
  country_code,
  mobile,
  email,
  subject,
  message,
  company
) =>
  BaseApi.post(
    "/contact-us",
    {
      name: name,
      country_code: country_code,
      mobile: mobile,
      email: email,
      subject: subject,
      message: message,
      company: company,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "application/json",
      },
    }
  );

const deleteUserAccount = (name, email, password, mobile) =>
  BaseApi.post(
    "/delete-user",
    {
      name: name,
      email: email,
      password: password,
      phone: mobile,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const termsCondition = () =>
  BaseApi.get(
    "/terms-and-conditions",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const privacyPolicy = () =>
  BaseApi.get(
    "/privacy-policy",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const refundReturn = () =>
  BaseApi.get(
    "/refunds-and-returns",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );
const gdprPolicy = () =>
  BaseApi.get(
    "/gdpr-policy",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const cookiesPolicy = () =>
  BaseApi.get(
    "/cookies-policy",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const support = () =>
  BaseApi.get(
    "/support",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const hiring = () =>
  BaseApi.get(
    "/hiring",
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const blogs = (currentPage, itemsPerPage) =>
  BaseApi.get(
    `/blogs${currentPage ? `?page=${currentPage}` : ""} ${
      itemsPerPage ? `&limit=${itemsPerPage}` : ""
    } `,
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const singleBlog = (slug) =>
  BaseApi.get(
    `/blog/${slug}`,
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export default {
  StripePayment,
  ChangePassword,
  UpdateProfileDP,
  UpdateProfileData,
  getProfileData,
  ChangeForgottEmail,
  ChangeForgottMobile,
  ForgottMobile,
  ForgottEmail,
  OTPSendEmail,
  OTPSendResetMobile,
  Categories,
  OTPSendMobile,
  OTPSendResetEmail,
  Register,
  UserLogin,
  UserLogout,
  UserDelete,
  UserLoginWithMobile,
  Auctions,
  StripeTransaction,
  AuctionChargesTransation,
  AuctionWonTransation,
  rewardsTransation,
  Notification,
  JoinAuction,
  UpcomingAuctions,
  ProcessingLive,
  LiveAuction,
  BuyAuction,
  CompletedAuctions,
  WonAuctions,
  PrevWonAuctions,
  CancelledAuctions,
  readNotificarion,
  SingleAuction,
  VerifyPhone,
  Packages,
  CheckToken,
  contactUs,
  deleteUserAccount,
  termsCondition,
  privacyPolicy,
  refundReturn,
  gdprPolicy,
  cookiesPolicy,
  support,
  hiring,
  blogs,
  singleBlog,
};
