import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ExportApi from "../../../../Api/ExportApi";
import { loader } from "../../../../loader";
import { saveProfile } from "../../../../Redux/Slices/TransactionSlice";
import DashboardMenu from "./DashboardMenu/DashboardMenu";
import { DashboardSideBarStyle } from "./DashboardSideBarStyle";
import TopProfile from "./TopProfile/TopProfile";

const  DashboardSideBarMain = () => {
  const dispatch = useDispatch();

  const getData = () => {
    ExportApi.getProfileData()
      .then((resp) => {
        if (resp.data) {
          dispatch(saveProfile(resp.data));
          loader("hide");
        }
      })
      .catch((err) => loader("hide"));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardSideBarStyle>
      <div className="dashboard-sideBar">
        <div className="top-profile-sideBar">
          <TopProfile />
        </div>
        <div className="dashboard-menu">
          <DashboardMenu />
        </div>
      </div>
    </DashboardSideBarStyle>
  );
}

export default DashboardSideBarMain;
