import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PackageStyle } from "./PackageStyle";
import ExportApi from "../../../Api/ExportApi";
import { useEffect, useState } from "react";



function PackageSection() {


    const [currency, setCurrency] = useState('$')

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    const CatInfo = async () => {
        await ExportApi.Categories(timeZone)
            .then((resp) => {
                if (resp.data) {
                    //console.log("resp.data-->", resp.data);
                    setCurrency(resp?.data[0]?.currency)
                }

            })
            .catch((err) => {
                setCurrency('$')
                console.log(err)
            });
    };

    useEffect(() => {
        CatInfo();
    }, []);





    return (
        <PackageStyle>
            <div className="package-section-wapper">
                <Card className="bg-dark text-white">
                    <Card.Img
                        src="/images/Packages-Banner.jpg"
                        alt="Card image"
                    />
                    <Card.ImgOverlay>
                        <Container>
                            <div className="package-content">

                                <p>Don't miss out on the opportunity to take your bidding experience
                                    to the next level. Add funds to your wallet and participate in our exclusive
                                    auctions today.</p>

                                <Card.Title>
                                    {`Buy Yolops and get started ! (1Y = ${currency}1)`}

                                </Card.Title>
                                <Link to="/our-packages">
                                    <Button variant="outline-primary" >
                                        View Packages
                                    </Button>{" "}
                                </Link>
                            </div>
                        </Container>
                    </Card.ImgOverlay>
                </Card>
            </div>
        </PackageStyle>
    );
}

export default PackageSection;
