import React, { useEffect, useState } from "react";
import { ReferEarnStyle } from "./ReferEarnStyle";
import { Container, Row, Col,InputGroup,Button, Form} from "react-bootstrap";

import PageHeading from "../../common/PageHeading/PageHeading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ReferEarnFN() {
const [referralLink, setReferralLink] = useState('');
const [copyCode, setCopyCode] = useState(false);
const {refer_code} = useSelector((state) => state?.APIData?.Profiledata)


    let Navigate = useNavigate();
    const CheckValidTokenn = () => {
        if (!localStorage.getItem("Token")) {
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            Navigate("/login");
        }
    };

useEffect(()=> {
    CheckValidTokenn();
  generateReferralLink()
}, [])

  const generateReferralLink = () => {
    const branch = window.branch;

    branch.link(
      {
        channel: 'referral',
        feature: 'invite',
        data: {
          userId: refer_code,
        },
        locallyIndex: true,
        canonicalUrl: 'mdi5w.app.link',
      },
      (err, link) => {
        if (err) {
          console.error('Error generating referral link:', err);
        } else {
          setReferralLink(link);
        }
      }
    );
  };

  const onCopyRefCodeHandler = () => {
    navigator.clipboard.writeText(referralLink)
    setCopyCode(true)
  }
  
  return (
    <ReferEarnStyle>
      <div className="referearn-page-title">
        <div className="pt-4">
          <PageHeading pagetitle={`Refer and Earn`} />
        </div>
      </div>

      <Container className='referearn-history-wapper'>
      <Row className='row-wrapper-inner'>
      <Col sm={12} md={12} lg={6}  className="left-sec ">
      <h2>Refer & Earn</h2>
      <p>Get ready to bid, win, and earn with Yolop!<br></br><br></br>
      Invite your friends and family to join our platform with your
      referral code and get rewarded. Start earning now by
      following these simple steps:
      </p>
      <Row className="inner-steps mt-5">
      <Col sm={2} >
      <img className="about-img" src="/images/01.png" alt="" />
      </Col>

      <Col sm={10}>
        <p>Invite your friends and family to join our
          reverse- auction platform with your unique
          referral link from Yolop app.</p>
      </Col>

      <Col sm={2}>
            <img className="about-img" src="/images/02.png" alt="" loading="lazy" />
      </Col>

      <Col sm={10}>
        <p>Share your referral link with your friends and
          family on different social media platforms,
          email or through text message.</p>
      </Col>

      <Col sm={2}>
            <img className="about-img" src="/images/03.png" alt="" loading="lazy" />
      </Col>

      <Col sm={10}>
        <p>You get the <span>extra 5%</span> credit on your wallet
            once Your friend joins his first auction.</p>
      </Col>

      </Row>
      </Col>
      <Col sm={12} md={12} lg={6}  className="right-sec ">

            <img className="about-img" src="/images/refer&earn1.png" alt="" loading="lazy" />
      </Col>

      </Row>

      <Row className='row-wrapper-reffer mt-5'>
      <Col >
      <p>Your Referal Link</p>

      <div className="outer">
      <InputGroup className="">
        <Form.Control
          aria-label="Refer link"
          aria-describedby="basic-addon2"
          value={referralLink}
        />
        <Button variant="outline-secondary" id="button-addon2" className="referbtn"
         onClick={onCopyRefCodeHandler}>
         {copyCode ? 'Copied' : 'Copy code'}
        </Button>
      </InputGroup>
      </div>
      <p className="termtext">*Terms and Conditions Apply</p>
      </Col>

      </Row>
      </Container>
    </ReferEarnStyle>
  );
}

export default ReferEarnFN;
