import React from "react";
import { useEffect, useState } from "react";
const CountdownDate = (props) => {
  const [countdownDate, setCountdownDate] = useState(
    new Date(props.time).getTime()
  );
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [render, setRender] = useState(1);
  const [day, setDay] = useState();

  const [horse, sethour] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    setCountdownDate(new Date(props.time).getTime());
    setRender(render + 34);
  }, [props]);

  useEffect(() => {
    if (countdownDate) {
      const currentTime = new Date().getTime();
      const distanceToDate = countdownDate - currentTime;
      setDay(Math.floor(distanceToDate / (1000 * 60 * 60 * 24)));
      sethour(
        Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distanceToDate % (1000 * 60)) / 1000));
    }
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
      if (minutes == 0) {
        clearInterval(myInterval);
        if (minutes === 0) {
          sethour(horse - 1);
          setMinutes(59);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <div className="countdown-date-wapper">
      {/* {console.log(day)} */}
      {/* {state.days > 0 || state.hours > 0 || state.minutes > 0 || state.seconds > 0 ?
        <li><span>{state.days || '0'}:{state.hours || '0'}:{state.minutes || '0'}:{state.seconds || '0'} Time Left</span></li>  : ""} */}
      {day == 0 ? (
        <>
          {horse < 10 ? `0${horse}` : horse}:
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </>
      ) : null}
    </div>
  );
};

export default CountdownDate;
