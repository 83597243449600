import React, { useState } from "react";
import {
  Modal,
} from "react-bootstrap";

const RaffelModal = ({onChangeVisible}) => {

 const handleClose = () => onChangeVisible(false);

return (
    <Modal
    show={true}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    className="congratulationspop"
  >
    <Modal.Header closeButton> </Modal.Header>
    <Modal.Body>
      <div className="text-center cong-inner-content">
                <img src="/images/congratulations-1.png" alt="" loading="lazy" />
        <h3>Congratulations!</h3>
        <p style={{ marginBottom: '0.2rem'}}>
        You're the lucky winner of our thrilling Raffle! As a reward, Your 
next entry into the next 
auction is absolutely  
        </p>
                <img src="/images/freeIcon.png" alt="" style={{ width: '20%' }} loading="lazy" />
      </div>
    </Modal.Body>
  </Modal>
)

}

export default RaffelModal;