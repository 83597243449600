import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container } from "react-bootstrap";
import { PrivacyPolicyStyle } from './PrivacyPolicyStyle';

import PageHeading from '../../Components/common/PageHeading/PageHeading';
import PageContent from '../../Components/common/PageContent/PageContent';
import MetaTags from "../../Components/common/MetaTags";
import ExportApi from "../../Api/ExportApi";
import { loader } from "../../loader";


function PrivacyPolicyMainFN() {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        getPrivacyPolicy();
    }, []);

    const getPrivacyPolicy = () => {
        loader('show')
        ExportApi.privacyPolicy()
            .then((res: any) => {
                loader('hide')
                if (res?.data) {
                    setData(res?.data?.data);
                }
            })
            .catch((err) => {
                loader('hide')
                console.log(err)
            });
    }
    return (
        <PrivacyPolicyStyle>
            <MetaTags
                title={data[0]?.seo_title}
                description={data[0]?.meta_description}
                focusKeyphrase={data[0]?.focus_keyphrase}
                canonicalURL={data[0]?.canonical}
            />
            <div className='privacypolicy-wapper pb-5'>

                <div className="privacypolicy-title pt-4 pb-2">
                    <PageHeading
                        pagetitle={`Privacy Policy`}
                    />
                </div>

                <Container>
                    <PageContent pagecontent={data[0]?.content} />
                </Container>

            </div>
        </PrivacyPolicyStyle>
    );
}

export default PrivacyPolicyMainFN;
