import styled from 'styled-components';


export const DashboardMenuStyle = styled.section`

    .dashboard-menu-wapper{
        .dashboard-menu{
            .nav{
                a{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 25px;
                    display: flex;
                    align-items: center;
                    color: #0B3B71;
                    padding-left: 1.5rem;
                    height: 56px;
                    text-decoration: navajowhite;
                    svg{
                        font-size: 26px;
                        margin-right: 5px;
                    }
                }
                a.active{
                    color: #FFF;
                    background: #0180FF;
                    
                }
            }
        }
    }

`;