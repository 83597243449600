import styled from "styled-components";

export const DownloadWeightStyle = styled.section`
  .button-wapper {
    width: 100%;
    display: flex;

      .button-android,
      .button-ios {
        display: inline-block;
        padding: 5px;

        button {
          width: 240px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 6px;

          svg {
            font-size: 60px;
            display: inline-block;
          }
          .button-caption {
            display: inline-block;
            text-align: left;
            span {
              display: block;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 19px;
            }
            strong {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 21px;
              line-height: 26px;
            }
          }
        }
      }
      .button-ios {
        text-align: right;
        button {
          color: #fff;
          background: #2f80ed;
          border-color: #2f80ed;
        }
      }
      .button-android {
        text-align: left;
        button {
          color: #fff;
          background-color: #34a853;
          border-color: #34a853;
        }
      }
 
 
      @media screen and (max-width: 768px) {

        .button-android,
          .button-ios {
            button {
              width:140px !important;
              svg {
                font-size: 35px;
                display: inline-block;
              }
              .button-caption {
                span {
                  font-size: 9px;
                  line-height: 15px;
              }
  
              strong {
                font-size: 13px;
                line-height: 19px;
            }



            }
          }
      }

 
    }
  }
`;
