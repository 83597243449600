import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { BlogStyle } from './BlogStyle';
import { Container } from "react-bootstrap";
import PageTitle from '../../Components/common/PageHeading/PageHeading';
import BlogCard from '../../Components/common/BlogCard/BlogCard';
import MetaTags from "../../Components/common/MetaTags";
import ExportApi from "../../Api/ExportApi";
import { loader } from "../../loader";

function BlogMainFN() {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(6);
    const [blogsData, setBlogsData] = useState<object>([])

    useEffect(() => {
        getBlogsData()
    }, [])
    const getBlogsData = () => {
        loader('show')
        ExportApi.blogs(currentPage, itemsPerPage)
            .then((resp: any) => {
                loader('hide')
                if (resp.data) {
                    setBlogsData(resp?.data?.data)
                }
            })
            .catch((err) => {
                loader('hide')
                console.log(err)
            });
    };

    return (
        <BlogStyle>
            <MetaTags
                title={'Yolop Blogs - Discover the Latest Auction Trends and Insights'}
                description={"Explore our Yolop blog for valuable insights, auction trends, and expert tips. Stay informed about the world of online bidding and discover strategies to enhance your bidding experience. Read our latest articles now!"}
                focusKeyphrase={'Blogs'}
                canonicalURL={'https://yolop.net/blogs'}
            />
            <div className='blog-wapper'>
                <div className="blog-wapper-heading">
                    <PageTitle
                        pagetitle={'Blogs'}
                    />
                </div>
                <div className="blog-wapper mb-5">
                    <Container>
                        <BlogCard BlogData={blogsData} />
                    </Container>
                </div>
            </div>
        </BlogStyle>
    );
}

export default BlogMainFN;
