import styled from 'styled-components';


export const HowItWorksStyle = styled.section`

     .howitwork-wapper{
          background-color: #fff;

          .page-title-wapper {
               padding-top:1.5rem;
               padding-bottom:1.5rem;
          }
          .about-img{
               display:block;
               margin:auto;
               max-width:100%;
          }
     
          .content-wrapper{

               .row-wrapper-1{
                    text-align:center;

                    p{   margin:20px 0px;
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 400;
                         font-size: 20px;
                         line-height: 31px;
                         color: #222222;
                    }

                    .top-img-sec{

                         h2{
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 27px;
                              line-height: 44px;
                              color: #000000;
                              margin-top:20px;
                         }

                         p{
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 31px;
                              text-align: center;
                              text-transform: capitalize;
                              color: #222222;
                              margin-top:0px;
                         }
                    }
               }
                    .mid-heading{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 600;
                         font-size: 35.2px;
                         line-height: 44px;
                         text-align: center;
                         color: #000000;
                         
                    }

                    .row-wrapper-2{
                         display:flex;
                         align-items:center;
                         padding:45px;
                         border: 1px solid #CACACA;
                         box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.13);
                         margin:30px 0px;
                        
                         .text-sec{
                              h3{
                                   font-family: 'Plus Jakarta Sans';
                                   font-style: normal;
                                   font-weight: 600;
                                   font-size: 27px;
                                   line-height: 44px;
                                   color: #000000;
                              }
                              p{
                                   font-family: 'Plus Jakarta Sans';
                                   font-style: normal;
                                   font-weight: 400;
                                   font-size: 16px;
                                   line-height: 31px;
                                   color: #585757;
                              }
                         }

                    }

                         .last-text{
                              width:800px;
                              text-align:center;
                              margin: auto;
                         }
                         @media screen and (max-width: 1000px){
                              .last-text{
                                   width:100%;
                              }

                         }
               }

     }
`;