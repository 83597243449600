import React, { useEffect, useState } from "react";
import { PackagesStyle } from "./PackagesStyle";
import { Container, Row, Col, Button } from "react-bootstrap";

import PageHeading from "../../common/PageHeading/PageHeading";
import { AuctionsData } from "../../../Data/AuctionsData";
import ExportApi from "../../../Api/ExportApi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate as useNavigatenew } from "react-router-dom";

function PackagesFN() {
  // const dataone = Object.values(AuctionsData.slice(0, 6));
  // const datatwo = Object.values(AuctionsData.slice(0, 2));
  // const datathree = Object.values(AuctionsData.slice(0, 3));
  // const datafour = Object.values(AuctionsData.slice(0, 4));
  // console.log(dataone , "dataone")
  let navigate = useNavigate();
  const location = useLocation();
  const [packageData, setPackagesData] = useState([]);
  const amount = location?.state?.amount;
  const auctionId = location?.state?.auctionId;
  const coupon_code = location?.state?.coupon_code;
  const color = ["#EC7B7B", "#FCCE71", "#17C2A9", "#6ECEFE"];
  const buttonColor = ["#FCCE71", "#17C2A9", "#6ECEFE", "#EC7B7B"];

  const addFundHandler = (item) => {
    if (localStorage.getItem("Token")) {
      navigate("/addfunds", {
        state: {
          type: "wallets",
          amount,
          item,
          joinAuctionId: auctionId,
          coupon_code: coupon_code,
        },
      });
    } else {
      toast.error("To buy this package you need to login.");
      navigate("/login");
    }
  };
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Packages API Call
  const PackagesData = () => {
    ExportApi.Packages(timeZone)
      .then((res) => {
        if (res.data.data.length > 0) {
          if (amount) {
            const val = res.data.data.filter((items) => {
              if (items.max_tokens > amount) {
                return items;
              }
            });
            setPackagesData(val);
          } else {
            setPackagesData(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  let Navigate = useNavigatenew();
  const CheckValidTokenn = () => {
    if (!localStorage.getItem("Token")) {
      localStorage.removeItem("Token");
      localStorage.removeItem("User_ID");
      window.dispatchEvent(new Event("EventLength"));
      Navigate("/login");
    }
  };
  useEffect(() => {
    CheckValidTokenn();
    PackagesData();
  }, []);

  return (
    <PackagesStyle>
      <div className="Packages-page-title">
        <div className="pt-4">
          <PageHeading pagetitle={`Packages`} />
        </div>
      </div>

      <Container className="packages-wrapper">
        <Row className="package-wrapper-inner">
          {packageData.map((item, index) => {
            const rec = amount <= item.max_tokens && amount >= item.min_tokens;
            //   console.log(rec)
            return (
              <Col
                sm={12}
                md={12}
                lg={6}
                xxl={6}
                className="package-outer"
                style={{ backgroundColor: color[index % color.length] }}
              >
                <p className="package-heading">
                  {item?.name} &nbsp; &nbsp; {rec && " (Recomended)"}
                </p>
                <Row className="inner-sec ">
                  <Col sm={5} className="left-sec ">
                    <p className="package-price">
                      {item.min_tokens} <sup>+</sup>
                    </p>
                  </Col>
                  <Col sm={7} className="right-sec ">
                    <p className="package-value">
                      {item?.min_tokens} {item?.custom_currency_name}
                    </p>

                    <p className="discount-value">
                      {item?.global_currency} {item?.amount}{" "}
                      {item?.custom_currency_name}
                    </p>
                  </Col>
                  <div className="btnouter">
                    <Button
                      className="buy-package-btn"
                      style={{
                        backgroundColor:
                          buttonColor[index % buttonColor.length],
                        borderColor: buttonColor[index % buttonColor.length],
                      }}
                      onClick={() => addFundHandler(item)}
                    >
                      Buy Now
                    </Button>
                  </div>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Container>
    </PackagesStyle>
  );
}

export default PackagesFN;
