import styled from 'styled-components';


export const DashboardAuctionsCardStyle = styled.section`

    .dashboard-auctions-card-wapper{
        padding: 20px;
        background-color: #FFFFFF;
        border: 1px solid #C6C9D0;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
        border-radius: 6px;

        .dashboard-auctions-card-title{
            font-family: 'Plus Jakarta Sans';
            font-weight: 700;
            font-size: 17px;
            line-height: 20px;
            text-transform: uppercase;
            color: #393939;
            margin-bottom: 15px;
                        
            svg{
                color: #E90E00;
                font-size: 25px;
                margin-right: 11px;
                border-radius: 50%;
                animation: pulse 0.5s infinite;
            }
            @-webkit-keyframes pulse {
                0% {
                    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
                }
                70% {
                    -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
                }
                100% {
                    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
                }
            }
            @keyframes pulse {
                0% {
                    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
                    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
                }
                70% {
                    -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
                    box-shadow: 0 0 0 10px rgba(204,169,44, 0);
                }
                100% {
                    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
                    box-shadow: 0 0 0 0 rgba(204,169,44, 0);
                }
            }
            
        }
        .dashboard-auctions-card{
            .dashboard-auctions-card-image{
                img{
                    border-radius: 6px;
                    width: 100%;
                    height: 310px;
                    object-fit: contain;
                }
            }
            .dashboard-auctions-card{
                padding: 10px 0px;
                .mid-section{
                    // align-items: center;
                    .dashboard-auctions-title-inner{
                        .dashboard-auctions-title{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 21px;
                            line-height: 26px;
                            margin-bottom: 5px;
                            margin-top: 5px;
                            display:flex;
                        }
                        .dashboard-auctions-disc{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            text-transform: uppercase;
                            color: #323131;
                        }
                    }
                    .dashboard-auctions-price{
                        margin-top: 5px;
                        p{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            text-transform: uppercase;
                            color: #323131;
                            margin-bottom: 5px;
                            margin-top: 5px;
                        }
                    }
                }
            }
            
            .dashboard-auctions-card-graph{
                background-color: #EDF9FE;
                border-radius: 6px;
                text-align: center;
                padding: 30px 0px;
                
                .card-graph-inner{
                    width: 170px; 
                    margin: 0 auto;
                }
            }
            .dashboard-auctions-card-time{
                .discount-price,
                .current-price{
                    border-radius: 6px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                    padding: 15px;
                    height: 95px;

                    .current-price-content{
                        span{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #101010;
                        }
                        h2{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: bolder;
                            font-size: 26px;
                            line-height: 20px;
                            letter-spacing: -0.04em;
                            color: #000000;
                            margin-top: 5px;
                            span{
                                font-size: 26px;
                                font-weight: 700;
                            }
                        }
                        
                    }
                    .current-price-icon{
                        svg{
                            font-size: 40px;
                        }
                    }
                }
                .current-price{                    
                    background-color: #FFF1F1;
                    .current-price-icon{
                        svg{
                            color: #FF0000;
                        }
                    }                    
                }                
                .discount-price{
                    background-color: #EBFFE1;
                    .current-price-icon{
                        svg{
                            color: #537641;
                        }
                    }
                }
            }
            .dashboard-auctions-card-button{
                button{
                    height: 65px;
                    width: 100%; 
                    margin: 0 auto;
                    background: #0180FF;
                    border-radius: 4px;
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
                .pending-payment{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;

                     span{
                        color:#0180FF;
                        cursor: pointer;
                        font-weight:700;
                        font-family: 'Plus Jakarta Sans';
                     }

                }
            }
        }
    }
    
`;