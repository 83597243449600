import styled from 'styled-components';


export const SectionTitleStyle = styled.section`

    .section-title-wapper{
     .sectitlecontainer{
        --bs-gutter-x: .5rem;

         .section-title-inner{
            justify-content: space-between;

            .section-title{
                
                h2{
                    font-size: 27px;
                    font-weight: 700;
                    font-family: 'Plus Jakarta Sans';
                }
            }
            .section-button{
                
                border-bottom: 1px solid blue;
                a{
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 500;
                }
        
            }
       } 
      }
    }

    @media screen and (max-width: 1100px){
        .section-title-wapper{
            .sectitlecontainer{
            .section-title-inner{
               .section-title{
                    h2{
                        font-size: 2rem;
                    }
                }
                .section-button{
                    a{
                        font-size: 15px;
                    }
                }
          
          }
        
        }
        }
    }
    @media screen and (max-width: 931px){
        .section-title-wapper{
            .sectitlecontainer{
            .section-title-inner{
               .section-title{
                    h2{
                        font-size: 1.6rem;
                    }
                }                
           
           }
         }
        }
    }
    @media screen and (max-width: 576px){
        .section-title-wapper{
            .sectitlecontainer{
            .section-title-inner{
               .section-title{
                    h2{
                        font-size: 1.2rem;
                    }
                }                
            }
        }
    }
    }

`;