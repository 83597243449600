import styled from "styled-components";

export const OTPMainStyle = styled.section`
  .otpmain-main-wapper {
    background-color: #e7eaf0;

    .page-title-wapper{
      background:#E7EAF0;
      padding:1.5rem 0rem;
     }

    .form-sidebar {
      padding: 0px;
    }
    .otpform-form {
      background-color: #fff;
      padding: 40px;
    }
  }
`;
