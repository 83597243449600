import { SignUpMainStyle } from './SignUpMainStyle';
import PageTitle from '../common/PageHeading/PageHeading';
import SignUpForm from './SignUpForm/SignUpForm';

function SignUpMain() {
    return (
          <SignUpMainStyle>
               <div className='signup-main-wapper pt-4'>
                    <PageTitle 
                         pagetitle={'SignUp'} 
                         // pageslug={''}
                    />
                    <SignUpForm/>
               </div>
          </SignUpMainStyle>
    );
}

export default SignUpMain;