import React, { useEffect, useState } from "react";
import { HelpSupportStyle } from "./HelpSupportStyle";
import { Button, Card, Form, Modal } from "react-bootstrap";
import PageHeading from "../../common/PageHeading/PageHeading";
import { Link } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let profileObj = {
    country_code: '+91',
    user_details: {
        email: 'abc12@gmail.com',
        first_name: 'test',
        mobile: '1234567890'
    }
}


function HelpSupportFN() {
    const profileDetails = useSelector((state) => state?.APIData?.Profiledata);
    const { country_code, user_details } = !profileDetails?.message ? profileDetails : profileObj;
    const { email, first_name, mobile } = user_details;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isSelect, setIsSelect] = useState('0')
    const [subject, setSubject] = useState('')


    let Navigate = useNavigate();
    const CheckValidTokenn = () => {
        if (!localStorage.getItem("Token")) {
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            Navigate("/login");
        }
    };

    
    useEffect(() => {
        if (isSelect === '1') setSubject('Online Auctions');
        else if (isSelect === '2') setSubject('Registration');
        else if (isSelect === '3') setSubject('Bidding');
        else if (isSelect === '4') setSubject('Payment');
        else if (isSelect === '5') setSubject('Shipping');
        else if (isSelect === '6') setSubject('Other');
    }, [isSelect]);

    useEffect(() => {
        CheckValidTokenn();
    }, []);

    const onSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isSelect === '0') toast.error("You have to select any subject!")
        else formik.handleSubmit();
    };

    const formik = useFormik({
        initialValues: {
            name: first_name,
            company: "",
            email: email,
            mobile: mobile,
            message: "",
        },
        onSubmit: (values) => {
            ExportApi.contactUs(
                values.name,
                country_code,
                values.mobile,
                values.email,
                subject,
                values.message,
                values.company
            )
                .then((res) => {
                    if (res.data.success) {
                        toast.success(res.data.message, {
                            toastId: "contact-us-popup",
                        });
                    }
                    else {
                        toast.error("Something went wrong", {
                            toastId: "contact-us-popup",
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    });



    return (
        <HelpSupportStyle>
            <div className="helpsupport-page-title">
                <div className="pt-4">
                    <PageHeading pagetitle={`Help and Support`} />
                </div>
            </div>
            <div className="helpsupport-main mb-5">
                <div className="helpsupport-history-wapper">
                    <img className="support-img" src="/images/help&supportweb.png" alt="" loading="lazy" />

                    <div className="support-wapper mt-4">
                        <div className="support-wapper-inner">
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>Need Help?</Card.Title>
                                    <Card.Text>
                                        Send us a message and we shall try to resolve your query in one business day.
                                    </Card.Text>
                                    <Link
                                        className="content-button"
                                        onClick={handleShow}
                                        to={"#"}
                                    >
                                        Contact Us
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>
                        <Modal
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="contained-modal-title-vcenter"
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Support Center</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={(e) => onSubmitHandler(e)}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Select
                                            onChange={(e) => setIsSelect(e.target.value)}
                                        >
                                            <option>Open this select menu</option>
                                            <option value="1">Online Auctions</option>
                                            <option value="2">Registration</option>
                                            <option value="3">Bidding</option>
                                            <option value="4">Payment</option>
                                            <option value="5">Shipping</option>
                                            <option value="6">Other</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Message"
                                            name="message"
                                            required
                                            rows={3}
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                        />
                                    </Form.Group>
                                    <Button className="send-button mt-1" type="submit" onClick={handleClose}>
                                        Send
                                    </Button>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </HelpSupportStyle>
    );
}

export default HelpSupportFN;
