import styled from 'styled-components';


export const CookiesStyle = styled.section`

     .cookies-wapper{
          background-color: #fff;
          .cookies-title{
               background-color: #EDF9FE;
          }
     }


`;