import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { BlogDetailsSingleStyle } from "./BlogDetelsSingleStyle";
import PageHeading from "../../common/PageHeading/PageHeading";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BlogCard from "../BlogCard/BlogCard";
import MetaTags from "../MetaTags";
import { loader } from "../../../loader";
import ExportApi from "../../../Api/ExportApi";

const BlogDetailsSingle = () => {
    const [DATA, setDATA] = useState([]);
    const [blogsData, setBlogsData] = useState([]);
    const [similarBlog,setSimilarBlogs]=useState([])
    let params = useParams();
    useEffect(() => {
        if (params) {
            getSingleBlog();
        }
        getBlogsData();
    }, [params]);

    useEffect(() => {
        if (blogsData.length != 0) {
            const shuffled = blogsData.sort(() => 0.5 - Math.random());
            let selected = shuffled.slice(0, 3);
            setSimilarBlogs(selected);
        }
    }, [blogsData])

    const getSingleBlog = () => {
        loader('show');
        ExportApi.singleBlog(params?.id)
            .then((res) => {
                loader('hide');
                if (res.data) {
                    setDATA(res?.data?.data);
                }
            })
            .catch((err) => {
                loader('hide');
                console.log(err);
            })
    }
    const getBlogsData = () => {
        ExportApi.blogs(1, 6)
            .then((resp) => {
                if (resp.data) {
                    setBlogsData(resp?.data?.data)
                }
            })
            .catch((err) => {
                console.log(err)
            });
    };
    return (
        <BlogDetailsSingleStyle>

            <MetaTags
                title={DATA[0]?.seo_title}
                description={DATA[0]?.meta_description}
                focusKeyphrase={DATA[0]?.focus_keyphrase}
            />


            <div className="single-blog-wapper">
                <PageHeading
                    pagetitle={DATA[0]?.title}
                />
                <Container>
                    <div className="single-blog-content-inner">
                        <div className="single-blog-image-wapper">
                            <div className="single-blog-image">
                                <img src={DATA[0]?.image} alt="" loading="lazy" />
                            </div>
                        </div>
                        <div className="single-blog-content-wapper mt-5 ">
                            <div className="single-blog-title">
                                {/* <h4>{DATA?.BlogName}</h4> */}
                                <div className="userinfo-wapper">
                                    {/* By
                                    <strong> Admin</strong> */}
                                </div>
                            </div>
                            <div
                                className="single-blog-content"
                                dangerouslySetInnerHTML={{ __html: DATA[0]?.content }}
                            />
                        </div>
                    </div>
                    <div className="sinlge-blog-share mb-5">
                        <div className="social-media-inner">
                            {/*<Nav>
                <Nav.Item>
                  <strong>Share : </strong>
                </Nav.Item>
                <Nav.Item>
                  <Link to={"#"}>
                    <Icon icon="bxl:facebook" />
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={"#"}>
                    <Icon icon="akar-icons:twitter-fill" />
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={"#"}>
                    <Icon icon="ci:linkedin" />
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={"#"}>
                    <Icon icon="ant-design:youtube-filled" />
                  </Link>
                </Nav.Item>
              </Nav> */}
                        </div>
                    </div>
                    <div className="sinlge-blog-related">
                        <div className="sinlge-blog-related-title mb-4">
                            <h2>Other Similar Blog Posts</h2>
                        </div>
                        <div className="sinlge-blog-related-posts mb-5">
                            <BlogCard BlogData={similarBlog} />
                        </div>
                    </div>
                </Container>
            </div>
        </BlogDetailsSingleStyle>
    );
};

export default BlogDetailsSingle;
