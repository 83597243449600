import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiddingStyle } from "./BiddingStyle";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import {useDispatch, useSelector} from 'react-redux';
// import { loginAction } from '../../../redux/LoginData/Action';

function BiddingSection() {
  // const dispatch = useDispatch();
  // const onStarted = () => {
  //      dispatch(loginAction({name: 'Yogesh'}))
  // }
  return (
    <BiddingStyle>
      <div className="bidding-section-wapper">
        <Card className="bg-dark text-white">
          <Card.Img 
            src="/images/home-banner.jpg"
            alt="Card image"
          />
          <Card.ImgOverlay>
            <Container>
              <div className="bidding-content">
                <Card.Title>
                Bid Securely and Transparently 
                  <br/> with <span>YOLOP</span>
                </Card.Title>
                <p>Bid like a pro with Yolop’s innovative platform, designed to elevate your bidding game to the next level. Experience hassle-free bidding at its finest with Yolop’s secure and transparent platform.</p>
                <Link to="/about">
                  <Button variant="outline-primary" >
                    Know More
                  </Button>{" "}
                </Link>
              </div>
            </Container>
          </Card.ImgOverlay>
        </Card>
      </div>
    </BiddingStyle>
  );
}

export default BiddingSection;
