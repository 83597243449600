import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HistoryTableStyle } from "./HistoryTableStyle";

const HistoryTable = (props) => {
  const transaction = props?.data;
  return (
    <>
      {transaction?.length <= 0   || !transaction ? (
         <h1 className="text-center">No Data Found</h1>
      ) : (
      <HistoryTableStyle>
        <div className="history-table-wapper">
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Package Name</th>
                <th>Id</th>
                <th>DISCOUNT</th>
                <th>Amount PAID</th>
                <th>Amount RECEIVED</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.type === "Dashboard"
                ? transaction?.slice(0, 4).map((i, index) => {
                    return (
                      <tr key={i.id}>
                        <td className="date">{i?.date_time}</td>
                        <td className="packagename">
                                {i?.package_name}
                        </td>
                        <td className="id">
                          {i?.transaction_id}
                        </td>
                        <td className="id">
                          {i?.discount}
                        </td>
                        <td className="amount">
                          <strong>{i?.currency} {i?.total}</strong>
                        </td>
                        <td className="amount">
                          <strong>{i?.amount}</strong>
                        </td>
                        <td className="action-wapper">
                          <span
                            className={
                              i?.payment_status.toLowerCase() + " " + "status"
                            }
                          >
                            {i?.payment_status === "succeeded"
                              ? "Success"
                              : i?.payment_status === "Pending"
                              ? "Pending"
                              : i?.payment_status === "processing"
                              ? "canceled"
                              : i?.payment_status === "processing"
                              ? "Cancelled Payment"
                              : "Payment Failed"}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                : transaction?.map((i, index) => {
                  
                    return (
                      <tr key={i.id ? i.id : i.auction_id}>
                        <td className="date">{i?.date_time ? i?.date_time : i?.updated_at}</td>
                        {/* <td className="transaction-name">{i.auction_name? i.auction_name : i.name}</td> */}
                            <td className="packagename">
                                {i?.package_name}
                            </td>
                        <td className="id">
                          {i?.transaction_id}
                        </td>
                    
                       <td className="id">
                          {i?.discount}
                        </td>
                        <td className="amount">
                          <strong>{i?.currency} {i?.total}</strong>
                        </td>
                        <td className="amount">
                          <strong>{i?.amount}</strong>
                         
                        </td>
                       {i.payment_status && <td className="action-wapper">
                          <span
                            className={
                              i.payment_status.toLowerCase() + " " + "status"
                            }
                          >
                            {i.payment_status === "succeeded"
                              ? "Success"
                              : i.payment_status === "Pending"
                              ? "Pending"
                              : i.payment_status === "processing"
                              ? "canceled"
                              : i.payment_status === "processing"
                              ? "Cancelled Payment"
                              : "Payment Failed"}
                          </span>
                        </td>
                           }
                        {/* <td className="action-wapper">
                          <Button
                            onClick={() => {
                              handleShow(i, index);
                            }}
                            className="view-popup"
                          >
                            View
                          </Button>
                        </td> */}
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          {/* <div className="logout-popup">
            <Modal
              show={show}
              onHide={handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="lg"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Table>
                  <tbody>
                    <tr>
                      <td>Title</td>
                      <td>
                        <strong>Ullamco laboris Nisi loer sipsnet </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Transaction Date</td>
                      <td>
                        <strong>{paymentDetail.date_time}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>ID</td>
                      <td>
                        <strong>{paymentDetail.transaction_id}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>
                        <strong className="price">{paymentDetail.total}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        <strong className="status-button">
                          {paymentDetail.payment_status === "succeeded"
                            ? "Success"
                            : paymentDetail.payment_status === "Pending"
                            ? "Pending"
                            : paymentDetail.payment_status === "processing"
                            ? "canceled"
                            : paymentDetail.payment_status === "processing"
                            ? "Cancelled Payment"
                            : "Payment Failed"}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Type</td>
                      <td>
                        <strong>{paymentDetail.payment_type}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Method</td>
                      <td>
                        <strong>Credit</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          </div> */}
        </div>
      </HistoryTableStyle>
      )
        }
    </>
  );
};

export default HistoryTable;
