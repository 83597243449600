import styled from 'styled-components';


export const SignUpFormStyle = styled.section`

     .signup-form-wapper{
          width: 55%;
          margin: 0 auto;
          .form-sidebar{
               background: #1E5CAC;
               .form-sidebar-inner{
                    .card-img {
                         margin-top: 85px;
                    }
               }
          }
          .signup-form{
               label{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #131313;
                    svg{
                         color: #93999F;
                         font-size: 22px;
                         margin-right: 5px;
                    }
               }
               .phone-input{
                   .input-color{
                        background: #EAEDF2;
                        border: 1px solid #ced4da;
                        height: 50px;
                        .PhoneInput{
                            background: transparent;
                            border: none;
                            .PhoneInputCountryIcon{
                                width: 40px;
                                height: auto;
                                border: none;
                            }
                            .PhoneInputInput{
                                pointer-events: none;
                                display: none;
                            }
                        }
                        input{
                            border: 0px;
                        }
                    }
                    
               }
               input.form-control{
                    height: 50px;
                    border-radius: 0px;
                    background-color: #EAEDF2;
                    width:100%;
               }
               .password{
                    position: relative;

                    label{
                         display: flex;
                         flex-direction: row;
                         align-items: flex-end;
                         svg{
                              font-size: 20px;
                         }
                    }
                    .passshow{
                         position: absolute;
                         right: 20px;
                         top: 47px;
                    }
               }
               .signup-button{
                    height: 50px;
                    border-radius: 4px;
                    background-color: #0180FF;
                    border: 0px;
                    width: 100%;
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
               }
               .login-signup{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #000000;
                    a{
                         text-decoration: none;
                         :hover{
                              color: #000;
                         }
                         svg{
                              font-size: 22px;
                         }
                    }
               }
          }
    
          @media screen and (max-width: 1200px){
               width: 100%;



          }
    
    
    
     }


`;