import * as React from "react";
import './ForgotPasswordStyle.css';
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import ForgotPasswordMain from '../../Components/ForgotPassword/ForgotPasswordMain'
import MetaTags from "../../Components/common/MetaTags";


function ForgotPasswordMainFN() {
  return (
    <div className='forgotpassword-wapper'>
      <MetaTags
          title={'Reset Your Account Password | Yolop '}
          description={"Forgot your password? No worries! Use Yolop's password recovery feature to reset your account password. Follow the simple steps to regain access to your account and continue enjoying our exciting auctions and deals."}
          focusKeyphrase={'Forgot your password'}
              canonicalURL={'https://yolop.net/forgot-password'}
        />
        <ForgotPasswordMain/>
    </div>
  );
}

export default ForgotPasswordMainFN;
