import styled from 'styled-components';


export const WearehiringStyle = styled.section`

     .wearehiring-wapper{
          background-color: #fff;
          .wearehiring-title{
               background-color: #EDF9FE;
          }
     }


`;