import * as React from "react";

import './SignUpStyle.css';
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import SignUpMain from '../../Components/SignUp/SignUpMain'
import MetaTags from "../../Components/common/MetaTags";


function SignUpMainFN() {
  return (
    <div className='body-wapper'>
      <MetaTags
      title={'Sign Up | Yolop - Join Now and Start Bidding on Luxury Products'}
      description={"Sign up for Yolop, gain access to exclusive luxury auctions. Join our bidding community for competitive deals. Register now!"}
      focusKeyphrase={'Sign Up'}
              canonicalURL={'https://yolop.net/signup'}
/>
        <SignUpMain/>
    </div>
  );
}

export default SignUpMainFN;
