import styled from 'styled-components';


export const AboutStyle = styled.section`

     .about-wapper{
          background-color: #fff;

          .page-title-wapper {
               padding-top:1.5rem;
               padding-bottom:1.5rem;
          }
          .about-img{
               display:block;
               margin:auto;
               max-width:100%;
          }
     }


`;