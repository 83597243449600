import styled from "styled-components";

export const AuctionsDetailsSingleStyle = styled.section`
  .single-auctions-wapper {
    .single-auctions-inner {
      padding: 30px 0px;

      .single-auctions-carousel {
        .single-image{
          .react-slider__lens{
            @media screen and (max-width: 1024px) {
              display:none;
              }

          }
            .react-slider__picture{
                img{
                    height: 550px;
                    object-fit: cover;
                }
            }
            .react-slider__imgZoom{
              @media screen and (max-width: 1024px) {
              display:none;
              }
            
            }
            
            .react-slider__ul{
                display: none;
            }
            .react-slider__btnPrev,
            .react-slider__btnNext{
                display: none;
            }

            }     
          }
        .carousel {
          .carousel-status {
            top: unset;
            bottom: 10px;
            right: 45%;
            left: 45%;
            padding: 5px;
            font-size: 10px;
            text-shadow: none;
            text-align: center;
            background: rgba(33, 33, 33, 0.65);
            border-radius: 63px;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 13px;
            line-height: 19px;
          }
          .control-dots {
            display: none;
          }
        }
      
      }
      .single-auctions-wapper {
        align-items: center;
        padding-top: 30px;

        .single-auctions-breadcrumb-wapper {
          .breadcrumb {
            align-items: center;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 30px;
            margin-bottom: 0px;

            a {
              text-decoration: none;
              color: #141414;
            }
            svg {
              padding-top: 5px;
              width: 45px;
              font-size: 25px;
              color: #7a7a7a;
            }
            .active {
              color: #0180ff;
            }
          }
        }
        .single-auctions-name-wapper {
          h1 {
            font-family: "Plus Jakarta Sans";
            font-weight: 700;
            font-size: 35px;
            line-height: 30px;
            color: #000000;
          }
          span {
            font-family: "Plus Jakarta Sans";
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #323131;
          }
        }
        .single-auctions-price-wapper {
          p {
            font-family: "Plus Jakarta Sans";
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;
            color: #323131;
            margin-bottom: 5px;
          }
          .single-auctions-price {
            font-family: "Plus Jakarta Sans";
            font-weight: 800;
            font-size: 28px;
            line-height: 35px;
            letter-spacing: -0.04em;
            color: #0961b9;
          }
        }
      }
      .single-auctions-time-wapper {
        margin-bottom: 15px;
        display: inline-block;

        p {
          font-family: "Plus Jakarta Sans";
          font-weight: 500;
          font-size: 12px;
          line-height: 31px;
          text-transform: uppercase;
          color: #000000;
        }
        time {
          font-size: 23px;
          color: #e90e00;
          span {
            border: 1px solid #e90e00;
            border-radius: 4px;
            padding: 10px;
            margin: 0px 5px;
          }
        }
      }
      .single-auctions-details-wapper {
        background: #ffffff;
        border: 1px solid #bababa;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        padding: 35px;
        margin-top: 15px;
        margin-bottom: 10px;

        .card {
          border: none;
          .single-auctions-details-title {
            h4 {
              font-family: "Plus Jakarta Sans";
              font-weight: 700;
              font-size: 19px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: #0b3b71;
              display: inline-block;
            }
            hr {
              width: 8rem;
              border: 3px solid #0180ff;
              opacity: 1;
              margin-top: 0px;
            }
          }
          .single-auctions-details-data {
            table {
              tr {
                border-color: transparent;
                td {
                  padding: 5px 0;
                  color: #101010;
                  font-family: "Plus Jakarta Sans";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }
      .single-auctions-buttom-wapper {
        .joinnow {
          width: 100%;
          background-color: #0180ff;
          border-radius: 4px;
          padding: 20px;
          font-family: "Plus Jakarta Sans";
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          color: #ffffff;
        }
      }

      .single-auctions-content-wapper {
        margin-top: 2rem;
        .single-auctions-title {
          h4 {
            font-family: "Plus Jakarta Sans";
            font-weight: 700;
            font-size: 26px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #0b3b71;
            display: inline-block;
            margin-bottom: 15px;
          }
          hr {
            width: 14rem;
            border: 3px solid #0180ff;
            opacity: 1;
            margin-top: 0px;
          }
        }
        .single-auctions-content {
          p {
            font-family: "Plus Jakarta Sans";
            font-weight: 400;
            font-size: 15px;
            line-height: 28px;
            color: #5b5959;
          }
        }
      }
    }
 `;

