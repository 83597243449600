import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState, useRef, useCallback } from "react";
import { BottomHeaderStyle } from "./Style";

import socket from "../../../../socket";

import {
  Button,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import AuctionsCardList from "../../AuctionsCardList/AuctionsCardList";
import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExportApi from "../../../../Api/ExportApi";
import { loader } from "../../../../loader";
import alert from "../../../Login/LoginForm/alert.png";
import { useDispatch } from "react-redux";
import {
  saveAuction,
  saveProfile,
} from "../../../../Redux/Slices/TransactionSlice";

const BottomHeader = () => {
  const ref = useRef();

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [Error, setError] = useState(false);
  const [Data, setData] = useState();
  const location = useLocation();
  const Token = localStorage.getItem("Token");
  const [HeaderData, setHeaderData] = useState();
  const [filterDATA, setFilterDATA] = useState("");
  const [DATA, setDATA] = useState();
  const [Image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState([]);
  const [dropDownShow, setDropDownShow] = useState(false);

  // const [isLiveRedirect, setIsLiveRedirect] = useState(false);
  const [auctionStartTimeLeftN, setAuctionStartTimeLeftN] = useState();

  const handleClose = () => {
    setShow(false);
    setDropDownShow(false);
    setFilterDATA();
  };

  const handleShow = () => {
    setShow(true);
    setDropDownShow(false);
  };

  const dataFilter = (e) => {
    setFilterDATA(e);
    if (e.length > 0) {
      let Data = HeaderData.filter(
        (value) =>
          value.asset_name.toLowerCase().match(e.toLowerCase()) ||
          value.name.toLowerCase().match(e.toLowerCase())
      );
      setDATA(Data);
    } else if (e.length === 0) {
      setDATA();
    } else {
      setDATA();
    }
  };
  const logout = () => {
    loader("show");
    setIsOpen(false);
    let Token = localStorage.getItem("Token");
    ExportApi.UserLogout(Token)
      .then((resp) => {
        if (resp.data) {
          if (resp.data.success === true) {
            setShow(false);
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            loader("hide");
            setIsOpen(false);
            Navigate("/login");
          }
        }
      })
      .catch((err) => setIsOpen(false));
  };
  const dataFilterNextPage = (e) => {
    e.preventDefault();

    // if (filterDATA?.length > 0) {
    //  Navigate("/searchitem", {state: {AuctionsData: DATA}});
    // }
  };
  const dataFilterNull = (e) => {
    setDATA();
    setFilterDATA();
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //console.log(timeZone);

  const CategoriesHeader = () => {
    ExportApi.Categories()
      .then((resp) => {
        if (resp.data) {
        }
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    ExportApi.getProfileData()
      .then((resp) => {
        dispatch(saveProfile(resp.data));
        if (resp.status === 401) {
          localStorage.removeItem("Token");
        }
        if (resp.status === 200) {
          localStorage.setItem(
            "Qsr",
            window.btoa(resp.data.user_details.status)
          );
          localStorage.setItem("User_ID", resp.data.user_details.user_id);
          setNotification(resp?.data?.unread_notifications);
          setData(resp.data.user_details);
          setImage(resp.data.image);
          if (resp.data.user_details.status === 2) {
            toast.warn(
              "Your account has been Deactivated. Please Contact Admin",
              {
                toastId: "account-deactivated",
              }
            );
          } else if (resp.data.user_details.status === 3) {
            setError("Your account has been Deleted. Please Contact Admin", {
              toastId: "account-deleted",
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const AuctionsData = () => {
    ExportApi.Auctions("", "", "", timeZone)
      .then((resp) => {
        if (resp.data) {
          dispatch(saveAuction(resp.data));
          setHeaderData(resp.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getData();
    CategoriesHeader();
    AuctionsData();
  }, [location.pathname]);
  // useEffect(() => {
  //   if (
  //     (localStorage.getItem("Token") && location.pathname == "/verification") ||
  //     (localStorage.getItem("Token") && location.pathname == "/login") ||
  //     (localStorage.getItem("Token") && location.pathname == "/signup") ||
  //     (localStorage.getItem("Token") &&
  //       location.pathname.includes("/verification"))
  //   ) {
  //     Navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    setFilterDATA();
    goToTop();
    setDATA();
    setDropDownShow(false);
  }, [location.pathname]);

  const remove_toggle = useCallback(() => {
    setFilterDATA("");
    document.getElementById("offcanvasNavbar-expand-md").style.visibility =
      "hidden";
    // if (document.querySelector('.offcanvas-backdrop')) {
    document.getElementsByClassName("offcanvas-backdrop")[0].style.visibility =
      "hidden";
    // }
    const elements = document.getElementsByClassName("navbar-toggler");
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add("collapsed");
      elements[i].click();
    }
  }, [location.pathname]);

  const removeDropdown = useCallback(() => {
    setDropDownShow(false);
    if (document.querySelector(".offcanvas-backdrop")) {
      remove_toggle();
    }
  }, [dropDownShow]);

  const div = document.getElementById("offcanvasNavbar-expand-md");
  // document.addEventListener('click', function(event) {
  //   if (event.target.closest('#offcanvasNavbar-expand-md') === div) {
  //       console.log(div)
  //   } else {
  //       console.log('check else statement')
  //    setFilterDATA('')
  //   }
  // });

  // handle useeffect to redirect useer to auction before aution start
  useEffect(() => {
    socket.on("connect").connect();
    socket.on("preAuction", (preauction) => {
      setAuctionStartTimeLeftN(preauction.diff);
      //  setVideoUrl(preauction?.video_url);
    });
    socket.on("liveAuctionData", (val) => {
      setAuctionStartTimeLeftN();
      //setIsLiveRedirect(true);
    });
    if (auctionStartTimeLeftN > 18) {
      Navigate("/dashboard");
      // console.log("its started ddd");
    }
  });

  return (
    <>
      <BottomHeaderStyle ref={ref}>
        <div className="loader" id="custom_loader">
          <img src="/images/yoloploader.gif" alt="Site Loader" />
        </div>
        <div className="bottom-header">
          <Navbar bg="light" expand="md" className=" ">
            <Container fluid>
              <Navbar.Brand onClick={() => setFilterDATA()}>
                <Link to="/">Yolop</Link>
              </Navbar.Brand>
              <div className="mobile-search-wapper">
                <Form className="d-flex" onSubmit={dataFilterNextPage}>
                  <Form.Control
                    type="search"
                    placeholder="Search items"
                    className="me-1"
                    aria-label="Search"
                    value={filterDATA}
                    onChange={(e) => dataFilter(e.target.value)}
                  />
                  <Button
                    variant="outline-success"
                    onClick={dataFilterNextPage}
                  >
                    <Icon icon="akar-icons:search" />
                  </Button>
                </Form>
              </div>

              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`}>
                <Icon icon="mdi:menu" />
                <Icon icon="mingcute:close-fill" />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-md`}
                aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                placement="start"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                    Yolop
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Form className="d-flex" onSubmit={dataFilterNextPage}>
                    <Form.Control
                      type="search"
                      placeholder="Search items"
                      className="me-1"
                      aria-label="Search"
                      value={filterDATA}
                      onChange={(e) => dataFilter(e.target.value)}
                    />
                    <Button
                      variant="outline-success"
                      onClick={dataFilterNextPage}
                    >
                      <Icon icon="akar-icons:search" />
                    </Button>
                  </Form>

                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Item
                      onClick={removeDropdown}
                      className={
                        location.pathname === "/upcomingauctions"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/upcoming-auctions">Upcoming Auctions</Link>

                      {/* <Link to="/upcomingauctions"  data-bs-dismiss="offcanvas">Upcoming Auctions</Link> */}
                    </Nav.Item>
                    <NavDropdown
                      title="Auction Categories"
                      id="navbarScrollingDropdown"
                      renderMenuOnMount={true}
                    >
                      <NavDropdown.Item href="/bag-auctions">
                        Bag Auction
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item
                      onClick={removeDropdown}
                      className={location.pathname === "/blog" ? "active" : ""}
                    >
                      <Link to="/our-packages">Yolops</Link>
                    </Nav.Item>
                    <Nav.Item>
                      <div className="black-menu"> </div>
                    </Nav.Item>

                    {Token ? (
                      <>
                        <Link
                          onClick={removeDropdown}
                          className="notifications-icon"
                          to="/notifications"
                        >
                          <Icon icon="material-symbols:notifications-rounded" />
                          {notification === 0 ? null : (
                            <span className="notification-count">
                              {notification}
                            </span>
                          )}
                        </Link>
                        <Link
                          className="user-profile-wapper"
                          to="/editprofile"
                          onClick={removeDropdown}
                        >
                          {Image ? (
                            <img
                              src={Image}
                              id="imgViewwHeader"
                              style={{
                                borderRadius: "50%",
                                height: "40px",
                                width: "40px",
                              }}
                              alt="profileimg"
                            />
                          ) : (
                            <Icon className="user-profile" icon="bx:user" />
                          )}
                        </Link>
                        <NavDropdown
                          show={dropDownShow}
                          title={Data?.first_name}
                          id="navbarScrollingDropdown"
                          onClick={() => setDropDownShow(true)}
                          onToggle={() => setDropDownShow(false)}
                        >
                          <div onClick={removeDropdown}>
                            <Link
                              to="/dashboard"
                              className={
                                location.pathname === "/dashboard"
                                  ? "dropdown-item active"
                                  : "dropdown-item"
                              }
                            >
                              <Icon icon="dashicons:dashboard" />
                              Dashboard
                            </Link>
                          </div>
                          {/* <Link className="dropdown-item" to="/dashboard">
                                                    <Icon icon="ic:baseline-notifications" />
                                                    Notifications
                                                    </Link> */}
                          <Link className="dropdown-item" to="/myauctions">
                            <Icon icon="ri:auction-fill" />
                            My Auctions
                          </Link>

                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={handleShow}
                          >
                            <Icon icon="ic:twotone-logout" />
                            Logout
                          </Link>

                          {/* <NavDropdown.Divider /> */}
                        </NavDropdown>
                      </>
                    ) : (
                      <>
                        <Nav.Item onClick={removeDropdown}>
                          <Link to="/login" className="btn login-button">
                            <Icon icon="bx:user" />
                            Login
                          </Link>
                          <Link to="/signup" className="btn joinnow-button">
                            Signup
                          </Link>
                        </Nav.Item>
                      </>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
        {DATA?.length > 0 ? (
          <div className={filterDATA ? "search-wapper" : null}>
            <AuctionsCardList
              dataFilterNull={dataFilterNull}
              AuctionsData={DATA}
              value={filterDATA}
              setFilterDATA={setFilterDATA}
            />
          </div>
        ) : filterDATA?.length > 0 ? (
          <div className="search-wapper">
            <p>No auction found for the given search text.</p>
          </div>
        ) : null}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BottomHeaderStyle>
      <div className="logout-popup">
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Modal show={isOpen} className="send-confirm" id="resend-confirm">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsOpen(false);
            }}
          ></button>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default BottomHeader;
