import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, Form, Button, InputGroup, Modal, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import ExportApi from "../../Api/ExportApi";
import PageTitle from "../../Components/common/PageHeading/PageHeading";


function DeleteAccount() {

    let Navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState();
    const [valueError, setValueError] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (!formik.isValid) {
            toast.error("please check all the fields", {
                toastId: "signup-form-email",
            });
        }
        else if (!value) {
            toast.error("country code required", {
                toastId: "signup-form-email",
            });
        } else {
            formik.handleSubmit();
            setValidated(true);
        }
    };


    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            mobile: "",

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name Required"),
            email: Yup.string().email('Invalid Email!').required('Email address is required!').trim()
                .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter valid email address!'),

            password: Yup.string().required("Password Required"),
            mobile: Yup.string().required("Phone Number Required")
                .matches(/^\d{4,15}$/, "Only 4-15 Digits Required"),
            // .required("Phone number is required"),
        }),

        onSubmit: (values) => {
            ExportApi.deleteUserAccount(
                values.name,
                values.email,
                values.password,
                values.mobile,


            )
                .then((res) => {
                    // console.log(res)
                    if (res.data.success) {
                        toast.success(res.data.message, {
                            toastId: "delete-account",
                        });
                    }
                    else {
                        toast.error(res.data?.data?.error, {
                            toastId: "delete-account",
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    });


    return (
        <div className='contact-main-wapper pt-4'>
            <PageTitle
                pagetitle={'Delete Your Account'}
            // pageslug={''}
            />

            <div className="contact-form-wapper pb-5">
                <Container>
                    <Row>

                        <Col className="contact-form" sm={12}>
                            <div className="contact-form-inner">
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={(e) => handleSubmit(e)}
                                >
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6 mt-3"
                                        // controlId='validationCustom01'
                                        >
                                            <Form.Label>
                                                Name
                                            </Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="name"
                                                    placeholder="name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                {formik.touched.name &&
                                                    formik.errors.name}
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6 mt-3"
                                        // controlId="validationCustom02"
                                        >
                                            <Form.Label>
                                                Email Address
                                            </Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="email"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    name="email"
                                                    placeholder="Email@domain.com"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                {formik.touched.email &&
                                                    formik.errors.email}
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6 mt-3"
                                        // controlId="validationCustomUsername"
                                        >

                                            <Form.Label>
                                                <Icon icon="fa:lock" />
                                                Password
                                            </Form.Label>
                                            <Form.Control
                                                type={"password"}
                                                // id="passInput"
                                                name="password"
                                                placeholder="Password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}

                                            />



                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6 mt-3 phone-input"
                                        // controlId="validationCustomMobile"
                                        >
                                            <Form.Label>
                                                Mobile
                                            </Form.Label>
                                            <div className="input-color">
                                                <Row>
                                                    <InputGroup hasValidation>
                                                        <Col xs={1}  className="mt-n3">
                                                            <PhoneInput
                                                                className="form-control mt-1 mb-1"
                                                                name="CountryCode"
                                                                international
                                                                value={value}
                                                                onChange={setValue}
                                                            />
                                                        </Col>
                                                        <Col xs={11}>
                                                            <Form.Control
                                                                type="tel"
                                                                autoComplete="tel"
                                                                placeholder="078202 7292"
                                                                // aria-describedby="inputGroupPrepend"
                                                                required
                                                                pattern="[0-9]{15} 15"
                                                                maxLength="15"
                                                                name="mobile"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.mobile}
                                                            />
                                                            {formik.touched.mobile &&
                                                                formik.errors.mobile}
                                                        </Col>
                                                    </InputGroup>
                                                </Row>
                                            </div>
                                            <div style={{ color: "red" }}>{valueError}</div>
                                        </Form.Group>
                                    </Row>
                       
                                    <Button className="send-button mt-1" type="submit">
                                        Send
                                    </Button>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );

}

export default DeleteAccount;