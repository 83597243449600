import styled from 'styled-components';


export const BlogDetailsSingleStyle = styled.section`


    .single-blog-wapper{
        .page-title-wapper{
            background-color: #EDF9FE;
            padding: 3rem 0px;
        }
        .single-blog-content-inner{
            padding: 30px 0px;
            .single-blog-image-wapper{
                .single-blog-image{
                    text-align: center;
                    img{
                        max-width:100%;
                    }
                }
            }
            .single-blog-content-wapper{
                .single-blog-title{
                    h4{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 38px;
                        color: #000000;
                        margin-bottom: 0px;
                    }
                    .userinfo-wapper{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 31px;
                        color: #0180FF;
                    }
                }
                .single-blog-content{
                    p{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 31px;
                        color: #585757;
                    }

                    ol{ font-family: 'Plus Jakarta Sans';
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 31px;
                        color: #585757;

                    }
                    h4{
                        font-family: 'Plus Jakarta Sans';
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 44px;
                        color: #000000;
                    }
                }
            }
        }
        .sinlge-blog-share{
            .social-media-inner{
                .nav{
                    .nav-item{
                        .iconify{
                            color: rgba(82, 91, 104, 0.58);
                            font-size: 25px;
                            margin-right:10px;
                        }
                    }
                }
            }
        }
        .sinlge-blog-related{
            .sinlge-blog-related-title{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 42px;
                    text-align: center;
                    color: #000000;


                }
            }
        }
    }

`;