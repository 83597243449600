import styled from 'styled-components';


export const QuestionStyle = styled.section`

     .faq-main-wapper{
          background-color: #fff;

          .page-title-wapper {
               padding-top:1.5rem;
               padding-bottom:1.5rem;
          }
          .about-img{
               display:block;
               margin:auto;
               max-width:100%;
          }
          
          .faq-inner-wrapper{
               padding:20px;

               .row-wrapper{
               .faq-img img{  
                    max-width:100%;
               }

               }
          
               .faq-wrapper{
                    padding-left:40px;
                    .accordion {
                         .accordion-item{
                              .accordion-header{
                                   .accordion-button{
                                   font-family: "Plus Jakarta Sans";
                                   font-style:normal;
                                   padding-left: 0px;
                                   font-weight: 600;
                                   font-size: 20px;
                                   background:none;
                                   line-height: 44px;
                                   color:#000}
                              }
                        
                              .accordion-collapse{
                                   .accordion-body{
                                        padding-left:0px;
                                   }
                              }
                         }
                    }

                    .faq-knowmore{
                        
                         margin:40px 0px;
                         h3{
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 600;
                              font-size: 26px;
                              line-height: 44px;
                         }

                         p{
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 16px;
                              margin-bottom:40px;
                         }

                         .gettingstarted-button{
                              .btn{
                                   width: 181px;
                                   background: #0180FF;
                                   border-radius: 6px;
                              }
                         }

                    }  

               }

               @media screen and (max-width: 1024px){
                    .faq-wrapper{
                    padding-left:0px;}
               }
         
          
          }

     }


`;