import styled from "styled-components";

export const FooterTopStyle = styled.section`
  .footer-top-wapper {
    .footer-about-wapper {
      .footer-about-title {
        p {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          padding-right:25px;
          line-height: 32px;
          color: rgba(255, 255, 255, 0.83);
        }
      }
      .footer-download-weight{
            .button-wapper{
              .button-ios{
                padding-left: 0px;
                margin-right: 8px;
              button {
                padding: 2.5px;
                width: 227px;}

            }
            .button-android {
              padding-left: 0px;
            button{
              padding: 2.5px;
              width: 227px;}
            }   
           
          }

      }


      .social-media-wapper {
        .social-media-inner {
          padding: 15px 0px;
          .nav {
            .nav-item {
              .iconify {
                color: rgba(255, 255, 255, 0.58);
                font-size: 30px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .footer-menu-wapper {
      .footer-menu-title {
        p {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 21px;
          color: #ffffff;
        }
      }
      .footer-menu {
        .nav {
          a {
            padding: 0px;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.81);
            text-decoration: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px){
    .footer-about-wapper{
        margin-bottom: 30px;
    }
  }


`;
