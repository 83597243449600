import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import ExportApi from "../Api/ExportApi";
import { LoginFormStyle } from "../Components/Login/LoginForm/LoginFormStyle";
import FormsSidebar from "../Components/common/FormsSidebar/FormsSidebar";
import { loader } from "../loader";
import moment from "moment";
import { toast } from "react-toastify";
const ResetPassword = () => {
  let Navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const handleClick1 = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive1((current) => !current);
  };
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      conpassword: "",
    },
    validationSchema: Yup.object({
      newpassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(15, "Password must be at most 15 characters")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,15}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      conpassword: Yup.string()
        .oneOf(
          [Yup.ref("newpassword"), null],
          "Confirm and new password should be same"
        )
        .required("Enter your confirm  password"),
    }),
    onSubmit: (values) => {
      loader("show");
      let timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (location.state.email) {
        ExportApi.ChangeForgottEmail(
          location.state.email,
          timezone,
          values.newpassword,
          values.conpassword
        )
          .then((resp) => {
            if (resp.data) {
              if (resp.data.success === true) {
                localStorage.removeItem("active");
                localStorage.removeItem("email");
                loader("hide");
                Navigate("/login");
                toast.success(resp.data.message);
              } else {
                loader("hide");
                toast.error(resp.data.error);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        ExportApi.ChangeForgottMobile(
          location.state.phone,
          timezone,
          values.newpassword,
          values.conpassword
        )
          .then((resp) => {
            if (resp.data) {
              if (resp.data.success === true) {
                localStorage.removeItem("active");
                loader("hide");
                Navigate("/login");
                toast.success(resp.data.message);
              } else {
                loader("hide");
                toast.error(resp.data.error);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    },
  });

  return (
    <LoginFormStyle>
      <div className="login-form-wapper">
        <Container>
          <Row>
            <Col className="form-sidebar">
              <div className="form-sidebar-inner">
                <FormsSidebar
                  sidebartitle={"Yolop Login"}
                  sidebarcontent={`<strong>Secure</strong> & <strong>Transparent</strong><br/> Bidding with Yolop`}
                  sidebarimage={
                    "https://res.cloudinary.com/oneworldtechnologies/image/upload/v1662533364/Yolop/login_1_sgz89z.png"
                  }
                />
              </div>
            </Col>

            <Col className="login-form">
              <div className="login-form-inner">
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3 password">
                    <Form.Label>
                      <Icon icon="fa:lock" />
                      New Password
                    </Form.Label>
                    <Form.Control
                      name="newpassword"
                      placeholder="Please enter new Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newpassword}
                      type={isActive1 ? "text" : "password"}
                    />
                    <Icon
                      className="passshow"
                      onClick={handleClick1}
                      icon={
                        isActive1 ? "akar-icons:eye-slashed" : "akar-icons:eye"
                      }
                    />
                    {formik.touched.newpassword && formik.errors.newpassword ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.newpassword}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3 password">
                    <Form.Label>
                      <Icon icon="fa:lock" />
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      id="passInput"
                      name="conpassword"
                      placeholder="Please enter confirm password "
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.conpassword}
                      type={isActive ? "text" : "password"}
                    />
                    <Icon
                      className="passshow"
                      onClick={handleClick}
                      icon={
                        isActive ? "akar-icons:eye-slashed" : "akar-icons:eye"
                      }
                    />
                  </Form.Group>
                  {formik.touched.conpassword && formik.errors.conpassword ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.conpassword}
                    </div>
                  ) : null}
                  <Button
                    className="login-button mt-2"
                    variant="primary"
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LoginFormStyle>
  );
};

export default ResetPassword;
