import React from "react";
import { FooterTopStyle } from "./FooterTopStyle";
import { Icon } from "@iconify/react";

import { Container, Row, Col, Nav } from "react-bootstrap";
import DownloadWeight from "../../DownloadWeight/DownloadWeight";
import { Link } from "react-router-dom";

const Token = localStorage.getItem("Token");

function FooterTop() {
  return (
    <FooterTopStyle>
      <div className="footer-top-wapper">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <div className="footer-about-wapper">
                <div className="footer-about-title">
                  <p>
                  Join millions of Yolopians from over 120 countries and discover the ultimate thrill of reverse-auction bidding like never before !
                  </p>
                </div>
                <div className="footer-download-weight pt-3 pb-3">
                  <DownloadWeight />
                </div>
                <div className="social-media-wapper">
                  <div className="social-media-inner">
                    <Nav>
                      <Nav.Item>
                        <a href={'https://www.facebook.com/yolop.auctions/'}>
                          <Icon icon="bxl:facebook" /></a>
                      </Nav.Item>
                      <Nav.Item>
                      <a href={"https://www.instagram.com/yolop.auctions/"}>
                          <Icon icon="ph:instagram-logo-fill" />
                        </a>
                      </Nav.Item>
                      <Nav.Item>
                      <a href={"https://www.pinterest.com/Yolopauctions/"}>
                          <Icon icon="mdi:pinterest" />
                        </a>
                      </Nav.Item>
                      <Nav.Item>
                        <a href={"https://twitter.com/yolopauctions"}>
                          <Icon icon="akar-icons:twitter-fill" />
                        </a>
                      </Nav.Item>
                      <Nav.Item>
                        <a href={"https://www.reddit.com/user/yolopauctions"}>
                          <Icon icon="ic:baseline-reddit" />
                        </a>
                      </Nav.Item>
                      <Nav.Item>
                        <a href={"https://www.youtube.com/@yolop.auctions"}>
                          <Icon icon="ant-design:youtube-filled" />
                        </a>
                      </Nav.Item>
                      <Nav.Item>
                        <a href={"https://www.tumblr.com/yolopauctions"}>
                          <Icon icon="typcn:social-tumbler" />
                        </a>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="footer-menu-wapper">
                <div className="footer-menu-title">
                  <p>Company</p>
                </div>
                <div className="footer-menu">
                  <Nav defaultActiveKey="/home" className="flex-column">
                    <Link to={"/about"}>About</Link>
                    <Link to={"/we-are-hiring"}>We're Hiring</Link>
                    <Link to={"/blogs"}>Blogs</Link>
                    <Link to={"/refer-and-earn"}>Referal & Earn</Link>
                    {Token ? ( <Link to={"/editprofile"}>Delete Account</Link>) : ( <Link to={"/delete-account"}>Delete Account</Link>)}
                   
                  </Nav>
                </div>
              </div>
            </Col>
            <Col>
              <div className="footer-menu-wapper">
                <div className="footer-menu-title">
                  <p>Help</p>
                </div>
                <div className="footer-menu">
                  <Nav defaultActiveKey="/home" className="flex-column">
                    <Link to={"/faq"}>FAQ</Link>
                    <Link to={"/support"}>Support</Link>
                    <Link to={"/how-it-works"}>How It Works</Link>
                    <Link to={"/contact-us"}>Contact Us</Link>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col>
              <div className="footer-menu-wapper">
                <div className="footer-menu-title">
                  <p>Policies</p>
                </div>
                <div className="footer-menu">
                  <Nav defaultActiveKey="#" className="flex-column">
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    <Link to={"/terms-and-conditions"}>Terms & Conditions</Link>
                    <Link to={"/refunds-and-returns"}>Refunds & Returns </Link>
                    <Link to={"/gdpr-policy"}>GDPR Policy  </Link>
                    <Link to={"/cookies-policy"}>Cookies Policy   </Link>
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </FooterTopStyle>
  );
}

export default FooterTop;
