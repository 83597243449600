import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { ForgotPasswordFormStyle } from "./ForgotPasswordFormStyle";
import FormsSidebar from "../../common/FormsSidebar/FormsSidebar";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { saveForgetData } from "../../../Redux/Slices/TransactionSlice";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [IsEmail, setIsEmail] = useState(true);
  let Navigate = useNavigate();
  const [value, setValue] = useState();
  const [valueError, setValueError] = useState(false);
  const [Mobile, setMobile] = useState();
  const [Error, setError] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Enter your email"),
    }),

    onSubmit: (values) => {
      let timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      ExportApi.ForgottEmail(values.email, timezone)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.success === true) {
              toast.success(resp.data.message, {
                toastId: "forgott-email-message",
              });
              setError(false);
              //  let Token=localStorage.getItem("DToken");
              //  localStorage.setItem("Token", Token);
              // window.dispatchEvent(new Event("EventLength"));
              Navigate("/verification/email", {
                state: { email: formik.values.email, type: "verifyOtp" },
              });
            } else {
              toast.error(resp.data.data.email[0], {
                toastId: "account-update-email",
              });
            }
          }
        })
        .catch((err) => console.log(err));
    },
  });

  const HandleMobileOtp = () => {
    if (Mobile === undefined) {
      setValueError("Please enter mobile number");
    } else {
      if (Mobile.length > 6) {
        let timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        ExportApi.ForgottMobile(Mobile, timezone)
          .then((resp) => {
            // dispatch(saveForgetData(resp.data.data));
            // localStorage.setItem("type", "phone");
            setValueError("");
            if (resp.data) {
              if (resp.data.success === true) {
                toast.success(resp.data.message);
                // localStorage.setItem("mobile", value);
                setError(false);

                //  let Token=localStorage.getItem("DToken")
                //  localStorage.setItem("Token", Token)
                // window.dispatchEvent(new Event("EventLength"));
                Navigate("/verification/mobile", {
                  state: { phone: resp.data.data.phone, type: "verifyOtp" },
                });
              } else {
                toast.error(resp.data.data?.phone[0]);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        // toast.error("Something went wrong");
        setValueError("Please enter valid Number");
      }
    }
  };

  return (
    <ForgotPasswordFormStyle>
      <div className="forgotpassword-main-wapper">
        <Container>
          <Row>
            <Col className="form-sidebar" sm={12} md={5}>
              <div className="form-sidebar-inner">
                <FormsSidebar
                  sidebartitle={"Yolop Forgot Password"}
                  sidebarcontent={`<strong>Secure</strong> & <strong>Transparent</strong><br/> Bidding with Yolop`}
                  sidebarimage={"/images/forgotpassword.png"}
                />
              </div>
            </Col>

            <Col className="forgotpassword-form" sm={12} md={7}>
              <div className="forgotpassword-form-inner">
                <p className="forgetpasswrod-text">
                  Select which contact details should we use to reset your
                  password
                </p>

                <Form onSubmit={formik.handleSubmit}>
                  {IsEmail ? (
                    <>
                      <Form.Group
                        className="mb-3 email"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          <Icon icon="fluent:mail-16-filled" />
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type={"email"}
                          name="email"
                          placeholder="email@domain.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.email}
                          </div>
                        ) : (
                          <div style={{ color: "red" }}> {Error}</div>
                        )}
                      </Form.Group>

                      <Button
                        className="login-button mt-2"
                        variant="primary"
                        type="submit"
                      >
                        Get OTP
                      </Button>
                    </>
                  ) : (
                    <>
                      <Form.Group
                        md="6 mt-3"
                        className="mb-3 phone-input"
                        controlId="validationCustomPhone"
                      >
                        <Form.Label>
                          <Icon icon="ion:phone-portrait-outline" />
                          Mobile
                        </Form.Label>
                        <div className="input-color">
                          <Row>
                            <InputGroup hasValidation>
                              <Col xs={10}>
                                <Form.Control
                                  type="number"
                                  placeholder="078202 7292"
                                  aria-describedby="inputGroupPrepend"
                                  required
                                  name="mobile"
                                  value={Mobile}
                                  onChange={(e) => {
                                    setMobile(e.target.value);
                                    localStorage.setItem(
                                      "mobile",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <div style={{ color: "red" }}>{valueError}</div>
                            </InputGroup>
                          </Row>
                        </div>
                      </Form.Group>
                      <Button
                        className="login-button mt-4"
                        variant="primary"
                        type="button"
                        onClick={HandleMobileOtp}
                      >
                        Get OTP
                      </Button>
                    </>
                  )}

                  <div className="divider mb-4 mt-4"> or </div>
                  {IsEmail ? (
                    <Button
                      className="loginphone-button mt-2"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setIsEmail(false);
                      }}
                    >
                      <Icon icon="ion:phone-portrait-outline" />
                      Login via Phone
                    </Button>
                  ) : (
                    <Button
                      className="loginphone-button mt-2"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setIsEmail(true);
                      }}
                    >
                      <Icon icon="fluent:mail-16-filled" />
                      OTP with Email
                    </Button>
                  )}
                  <Form.Group
                    className="mb-2 mt-5 login-signup"
                    controlId="formBasicCheckbox"
                  >
                    <p className="text-center">
                      Don’t have an account ?
                      <Link to={"/login"}>
                        {" "}
                        Sign In?
                        <Icon icon="bi:arrow-right" />
                      </Link>
                    </p>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ForgotPasswordFormStyle>
  );
};

export default ForgotPasswordForm;
