import { Container, Stack } from "react-bootstrap";
import { SectionTitleStyle } from "./SectionTitleStyle";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";

interface TitleProps {
  sectiontitle: string;
  sectionlinktext: string;
  sectionlink: string;
  tabs: string;
}

function HomeTitle({ sectiontitle, sectionlinktext, sectionlink, tabs }: TitleProps) {
  const navigate = useNavigate();
  return (
    <SectionTitleStyle className="mb-4">
      <div className="section-title-wapper">
        <Container className=" sectitlecontainer">
          <Stack direction="horizontal" className="section-title-inner">
            <div className="section-title pt-3">
              <h2><b> {sectiontitle}</b> </h2>
            </div>
            {sectionlink !== "" ? (
              <div className="section-button">
                <Link to={sectionlink} state={{value: tabs}}>
                  <Icon icon="akar-icons:plus" />
                  {sectionlinktext}
                </Link>
              </div>
            ) : null}
          </Stack>
        </Container>
      </div>
    </SectionTitleStyle>
  );
}
export default HomeTitle;
