import styled from 'styled-components';


export const ForgotPasswordMainStyle = styled.section`

    .forgotpassword-main-wapper{
       background-color: #E7EAF0;

       .page-title-wapper{
        background:#E7EAF0;
       }

       .form-sidebar{
            padding: 0px;
       }
       .forgotpassword-form{
            background-color: #fff;
            padding: 60px;
        }

    }

`;  