import styled from "styled-components";

export const BiddingStyle = styled.section`
  .bidding-section-wapper {
    .card {
      border-radius: 0px;
      img {
      }
      .card-img-overlay {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        background-color: #08080826;

        .bidding-content {
          width: 50%;
          float: right;
          text-align: center;

          .card-title {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 38px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 20px;
            color: #3fc7ff;

            span{
              color:#ffff;
            }
          }
          p{
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 400;
            font-size:18px;

          }
          button {
            border: 2px solid #ffd905;
            border-radius: 4px;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            color: #ffd905;
            padding: 10px 35px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1350px) {
    .bidding-section-wapper {
      .card {
        border-radius: 0px;
        img {    
          height: 440px;
          object-fit: cover;
        }
        .card-img-overlay {

          .bidding-content {
            width: 55%;
          }
        }
      }
    }


  }

  
  @media screen and (max-width: 991px) {
    .bidding-section-wapper {
      .card {
        border-radius: 0px;
        img {
        }
        .card-img-overlay {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: #08080826;

          .bidding-content {
            width: 55%;
            float: right;
            text-align: center;

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 23px;
              line-height: 35px;
              text-align: center;
              margin-bottom: 0px;
            }
            button {
              border: 2px solid #ffd905;
              border-radius: 4px;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #ffd905;
              padding: 8px 32px;
            }
          }
        }
      }
    }


  }

  @media screen and (max-width: 767px) {
    .bidding-section-wapper {
      .card {
        border-radius: 0px;
        img {
          height: 365 px;
        }
        .card-img-overlay {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: #08080826;

          .bidding-content {
            width: 100%;
            float: right;
            text-align: center;
            padding-bottom: 10px;

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 500;
              font-size: 22px;
              line-height: 38px;
              text-align: center;
            }
            button {
              border: 2px solid #ffd905;
              border-radius: 4px;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #ffd905;
              padding: 8px 32px;
            }



          }
        }
      }
    }
  }
  @media screen and (max-width: 539px) {
    .bidding-section-wapper {
      .card {
        border-radius: 0px;
        img {
        }
        .card-img-overlay {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: #08080826;

          .bidding-content {
            width: 100%;
            float: right;
            text-align: center;
            padding-bottom: 10px;

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              margin-bottom: 10px;
            }
            button {
              border: 2px solid #ffd905;
              border-radius: 4px;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 22px;
              text-align: center;
              color: #ffd905;
              padding: 5px 35px;
            }


          }
        }
      }
    }
  }
`;
