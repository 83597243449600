import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container } from "react-bootstrap";
import PageHeading from "../../Components/common/PageHeading/PageHeading";
import PageContent from "../../Components/common/PageContent/PageContent";
import { AboutStyle } from "./AboutStyle";
import MetaTags from "../../Components/common/MetaTags";

function AboutMainFN() {
  return (
    <AboutStyle>
      <MetaTags
        title={"About Yolop - Your Ultimate Destination for Exclusive Auctions"}
        description={
          "Sign up for Yolop, gain access to exclusive luxury auctions. Join our bidding community for competitive deals. Register now!"
        }
        focusKeyphrase={"About Yolop"}
        canonicalURL={"https://yolop.net/about"}
      />
      <div className="about-wapper ">
        <div className="">
          <PageHeading pagetitle={`About Yolop`} />
        </div>

        <Container>
          <img
            className="about-img"
            src="/images/About-Yolop-img1.jpg"
            alt=""
            loading="lazy"
          />
          <PageContent
            pagecontent={`
            
            <h4>Welcome to YOLOP - Where you only BUY at <span class="orng-ltr">Y</span>our <span class="orng-ltr">O</span>wn <span class="orng-ltr">L</span>ow <span class="orng-ltr">P</span>rice <span class="orng-ltr">(#YOLOP).</span> </h4>
            <p><b><span class="orng-ltr">YOLOP</b></span> is an innovative online auction site that lets you buy goods you love at the price you want! You achieve huge savings on brands you know and products you love.</p>
            <p><b>WHO WE ARE</b></br>
            We are bringing the excitement of Reverse Bidding to online shopping though our fast pay to bid online auctions that run for two minutes. 
            </p>

            <p><b>WHAT WE DO</b></br>
            We help our clients achieve huge savings through our disruptive innovative online auction platform where we auction luxury goods and offer huge discounts for fuel, groceries and trending electronic products. Our fast-paced auctions create an exhilarating and adrenaline pumping experience combining the thrill of reverse bidding and online shopping for our clients.
            </p>

            <p><b>HOW WE DO IT</b></br>
            Bidders are required to pay a minimum fee in order to participate in an online auction event which is either through the Yolop app or on our website. </br></br>
            Familiar with a normal auction where the price goes up? Well Yolop is turning this upside down! Each event is a reverse auction in which the retail price for goods on offer is dropped from normal market price at start of auction to £0.00 in a period of two minutes. The price therefore dramatically drops to zero and the bidders have an option to wait or press the <span class="buy-btn">Buy Now </span>Button at the price they are prepared to buy the goods on auction. The first bidder to press the <span class="buy-btn">Buy Now </span> Button wins the auction. </br></br>
            Do you have the nerves to wait for the price to drop to the price you want or risk someone pressing the <span class="buy-btn">Buy Now</span> button before you! Let the game begin.</br></br>
            The winner is required to pay the final discounted price on display to claim the won goods.</br></br>
           <b> YOLOP offers the opportunity to achieve 90% or more discounts. As a bidder you have the opportunity to buy at <span class="orng-ltr">Y</span>our <span class="orng-ltr">O</span>wn <span class="orng-ltr">L</span>ow <span class="orng-ltr">P</span>rice <span class="orng-ltr">(YOLOP).</span></b>
            </p>
            </br>

            <h4> WHY WE'RE HERE </h4>
            <hr>
            <p><b> We believe there’s a better way for online shoppers to save money, and that nothing beats the adrenaline rush of a two-minute auction.</b></br></br>
            <b>MISSION:</b> Bringing affordability to our customers through huge discounts whilst combining the excitement of Reverse Bidding with online shopping.
             </p>
             </br>
             
            <h4>HOW WE’RE DIFFERENT </h4>
            <hr>
            <p>We do not believe one has to participate in one auction for weeks or long periods of time all in the name of getting a good deal, as in current traditional penny auctions. </br></br>
            So we built something much faster. <b>Two-minute auctions, where the price drops to your satisfaction in a flash. Innovative, thrilling, adrenaline pumping is the experience that we are bringing to our customers in order to get them good deals at the prices they LOVE.</b> </br>
             </p>
             <p><b>FREE SHIPPING NATIONWIDE</b> </br>
             Every item sold on our site is delivered to your door for free in the UK.</p>
   
          `}
          />
        </Container>
      </div>
    </AboutStyle>
  );
}

export default AboutMainFN;
