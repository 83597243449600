import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HistoryTableStyle } from "./HistoryTableStyle";

const EarnedRewardHistory = (props) => {
    const transaction = props?.data;
    return (
        <>
            {props.data.length == 0 || transaction.message ? (
                <h1 class="text-center">No data found</h1>
            ) : (
                <HistoryTableStyle>
                    <div className="history-table-wapper">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Auction Name</th>
                                    <th>Referred To</th>
                                    <th>Amount</th>
                                    <th>Participation Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transaction?.map((i, _index) => {
                                    return (
                                        <tr key={i.auction_id}>
                                            <td className="date">{i?.updated_at}</td>
                                            <td className="transaction-name"> <strong>{i?.name}</strong></td>
                                            <td className="id">{i?.first_name} {i?.last_name}</td>
                                            <td className="amount">
                                                <strong>
                                                    {i?.currency_symbol} {i?.amount}
                                                </strong>
                                            </td>
                                            <td className="amount">
                                                <strong>
                                                    {i.currency_symbol} {i?.participation_fees}
                                                </strong>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </HistoryTableStyle>
            )}
        </>
    );
};


export default EarnedRewardHistory;