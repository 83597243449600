import styled from 'styled-components';


export const ContactFormStyle = styled.section`

     .contact-form-wapper{
          width: 55%;
          margin: 0 auto;
          .form-sidebar{
               background: url(/images/contactus.png);
               padding: 40px;
               background-repeat: no-repeat;
               background-size: cover;
               .form-sidebar-inner{
                    h2{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 500;
                         font-size: 36px;
                         line-height: 41px;
                         color: #fff;
                         margin-bottom: 80px;
                    }

                    h3{
                         font-family: 'Plus Jakarta Sans';
                         font-style: normal;
                         font-weight: 700;
                         font-size: 40px;
                         line-height: 41px;
                         color: #fff;
                         margin-bottom: 50px;
                    }

                         .contact-details{
                              h4{
                                   font-family: 'Plus Jakarta Sans';
                                   font-style: normal;
                                   font-weight: 700;
                                   font-size: 25px;
                                   color: #FFFFFF;
                                   margin-bottom: 5px;
                              }
                              p{
                                   font-family: 'Plus Jakarta Sans';
                                   font-style: normal;
                                   font-weight: 600;
                                   font-size: 16px;
                                   line-height: 25px;
                                   color: #FFFFFF;
                                   margin-bottom:30px;

                              }
                         }

               }
          }
          .contact-form{
               background-color: #fff;
               padding:40px;
               label{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #131313;
                    svg{
                         color: #93999F;
                         font-size: 22px;
                         margin-right: 5px;
                    }
               }
               .phone-input{
                   .input-color{
                        background: #EAEDF2;
                        border: 1px solid #ced4da;
                        height: 50px;
                        .PhoneInput{
                            background: transparent;
                            border: none;
                            .PhoneInputCountryIcon{
                                width: 40px;
                                height: auto;
                                border: none;
                            }
                            .PhoneInputInput{
                                pointer-events: none;
                                display: none;
                            }
                        }
                        input{
                            border: 0px;
                        }
                    }
                    
               }
               input.form-control{
                    height: 50px;
                    border-radius: 0px;
                    background-color: #EAEDF2;
                    width:100%;
               }
               .textareasec textarea{
                    background: #EAEDF2;
                    border: 1px solid #ced4da;
                    border-radius: 0px;
                    width:100%;

               }

               .send-button{
                    height: 50px;
                    border-radius: 4px;
                    background-color: #0180FF;
                    border: 0px;
                    width: 100%;
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
               }
              
          }
    
          @media screen and (max-width: 1200px){
               width: 100%;



          }
    
    
    
     }


`;