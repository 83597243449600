import React, { useCallback, useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { AuctionsCardStyle } from "./AuctionsCardStyle";
import CountdownDate from "./CountdownDate";
import { useNavigate } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import { savejoinAuction } from "../../../Redux/Slices/TransactionSlice";
import { SetDate, SetTime } from "../DateFormat/DateFormate";
import Countdown from "react-countdown";
import ConfirmationBox from "../../ConfirmationBox/ConfirmationBox";
// import Countdown from 'react-countdown';

const AuctionsCard = (props) => {
  const [card, setCard] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [auctionData, setAuctionData] = useState();

  const { AuctionsData, processingData, logo } = props;

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let userid = localStorage.getItem("User_ID");

  const handleJoin = (data) => {
    setAuctionData(data);
    setModalVisible(true);
  };

  const handleJoinSuccess = (couponCode) => {
    const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setModalVisible(false);
    localStorage.setItem("Auctionid", auctionData.id);
    localStorage.setItem("coupon_code", couponCode);
    if (localStorage.getItem("Token")) {
      ExportApi.JoinAuction(auctionData.id, date, couponCode)
        .then((resp) => {
          if (resp.data.success) {
            toast.success("Joined Succesfully");
            localStorage.setItem("Auctionid", "");
            navigate("/myauctions");
            dispatch(savejoinAuction(resp));
          } else if (resp.data.data.error === "Previous Due Payment") {
            navigate("/addfunds", {
              state: {
                amount: resp.data.message?.due_payment[0].winning_price,
                auction_id: resp.data.message?.due_payment[0].auction_id,
                currency_name: resp.data.message?.due_payment[0].currency_name,
                currencySymbol:
                  resp.data.message?.due_payment[0].custom_currency_symbol,
                vat_price: resp.data.message?.due_payment[0].vat_price,
                total_price: resp.data.message?.due_payment[0].total_price,
                coupon_code: couponCode,
              },
            });
            dispatch(savejoinAuction(resp));
            toast.error(resp.data.data.error);
          } else if (resp.data.data.error) {
            console.log(resp.data.data.error, "this error");
            if (resp.data.data.error === "Invalid coupon.") {
              toast.error(resp.data.data.error);
            } else if (resp.data.data.error === "Coupon already used.") {
              toast.error(resp.data.data.error);
            } else {
              toast.error(
                "Amount required to join: $ " +
                  resp.data.message.amount_required
              );
              navigate("/packages", {
                state: {
                  amount: resp.data.message.amount_required,
                  type: "wallets",
                  auctionId: auctionData.id,
                  coupon_code: couponCode,
                },
              });
            }

            dispatch(savejoinAuction(resp));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(savejoinAuction(null));
        });
    } else {
      navigate("/login");
    }
  };
  return (
    <AuctionsCardStyle>
      <div className="auctions-card-wapper">
        {processingData?.length > 0 &&
          processingData?.map((data, key) => {
            return (
              <div
                className={"auctions-card-item card-item-" + key.toString()}
                key={data.id}
              >
                {/* {data?.status && "comming soon"} */}
                <Col xs={12} sm={4}>
                  {card && (
                    <Card>
                      <Card.Img
                        variant="top"
                        src={
                          data.AuctionImages[0]?.image_url +
                          data.AuctionImages[0]?.image[0]
                        }
                      />
                      <Card.Body>
                        <Row>
                          <p className="text-live">Live soon</p>
                          <Col xs={8} className="title-wapper-left">
                            <Card.Title>{data.name}</Card.Title>
                            <Card.Text>{data.asset_name}</Card.Text>
                          </Col>
                          <Col xs={4} className="quantity-wapper-right">
                            <span>
                              QTY <br /> {data.items}
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <Row className="mid-section">
                          <Col xs={6} className="price-wapper">
                            <div className="price-heading">
                              <p>Starting Price</p>
                            </div>
                            <div className="price">
                              {/* <span>$</span> */}
                              <strong>
                                {data.view_currency} {data.asset_price}
                              </strong>
                            </div>
                          </Col>
                          <Col xs={6} className="time-wapper">
                            <div className="price-heading">
                              <p>Auction Time</p>
                            </div>
                            <div className="price">
                              <time>
                                <CountdownDate time={data?.global_start_time} />
                              </time>
                            </div>
                          </Col>
                        </Row>
                      </Card.Footer>
                      <div className="button-wapper">
                        {props.type === "Myauction" ? null : (
                          <>
                            <Button
                              variant="primary"
                              className="mt-2 joinnow"
                              onClick={() => handleJoin(data)}
                            >
                              Join Now
                            </Button>
                          </>
                        )}
                        <Button
                          variant="light"
                          className="mt-2 viewdetails"
                          onClick={() => {
                            navigate(`/details/${data.slug}`);
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                    </Card>
                  )}
                </Col>
              </div>
            );
          })}
      </div>
      <div className="auctions-card-wapper mt-5">
        {AuctionsData?.length > 0 ? (
          <Row
            xs={1}
            md={2}
            lg={props.itemRow ? props.itemRow : 5}
            className="g-4"
          >
            {AuctionsData?.map((data, key) => {
              const startDate = new Date(data?.global_start_time).getTime();
              const difference = startDate - new Date().getTime();
              const dateDifference = Math.floor(
                difference / (1000 * 60 * 60 * 24)
              );

              return (
                <div
                  className={"auctions-card-item card-item-" + key.toString()}
                  key={data.id}
                >
                  {/* {data?.status && "comming soon"} */}
                  <Col>
                    {card && (
                      <Card>
                        <Card.Img
                          variant="top"
                          src={
                            data.AuctionImages[0]?.image_url +
                            data.AuctionImages[0]?.image[0]
                          }
                        />
                        <Card.Body>
                          <Row>
                            <Col xs={8} className="title-wapper-left">
                              <Card.Title>{data.name}</Card.Title>
                              <Card.Text>{data.asset_name}</Card.Text>
                            </Col>
                            <Col xs={4} className="quantity-wapper-right">
                              <span>
                                QTY <br /> {data.items}
                              </span>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <Row className="mid-section">
                            <Col xs={5} className="price-wapper">
                              <div className="price-heading">
                                <p>Starting Price</p>
                              </div>
                              <div className="price">
                                {/* <span>$</span> */}
                                <strong>
                                  {data.view_currency} {data.asset_price}
                                </strong>
                              </div>
                            </Col>
                            <Col xs={7} className="time-wapper">
                              <div className="price-heading">
                                <p>Auction Time</p>
                              </div>
                              <div className="auction-time">
                                <time>
                                  {dateDifference === 0 ? (
                                    <CountdownDate
                                      time={data?.global_start_time}
                                    />
                                  ) : (
                                    <>
                                      <p>
                                        {SetDate(data.global_start_time)}
                                        &nbsp;&nbsp;
                                        {SetTime(data.global_start_time)}
                                      </p>
                                    </>
                                  )}
                                </time>
                              </div>
                            </Col>
                          </Row>
                        </Card.Footer>
                        <div className="button-wapper">
                          {props.type === "Myauction" ? null : (
                            <>
                              <Button
                                variant="primary"
                                className="mt-2 joinnow"
                                onClick={() => handleJoin(data)}
                              >
                                Join Now
                              </Button>
                            </>
                          )}
                          <Button
                            variant="light"
                            className="mt-2 viewdetails"
                            onClick={() => {
                              navigate(`/details/${data.slug}`);
                            }}
                          >
                            View Details
                          </Button>
                        </div>
                      </Card>
                    )}
                  </Col>
                </div>
              );
            })}
          </Row>
        ) : logo ? (
          <img
            src="/images/Exciting-Auctions-Coming-Soon!-Keep-an-Eye-Out.jpg"
            style={{ width: "100%" }}
            className="no-upcomauction"
            alt="Upcoming Auction"
            loading="lazy"
          />
        ) : (
          <h2 className="text-center">No data found</h2>
        )}
        {modalVisible && (
          <ConfirmationBox
            isVisible={modalVisible}
            onChangeVisible={setModalVisible}
            auctionData={auctionData}
            onJoinAuction={handleJoinSuccess}
          />
        )}
      </div>
    </AuctionsCardStyle>
  );
};
export default AuctionsCard;
