import styled from 'styled-components';


export const EditProfileMainStyle = styled.section`

    .editpagemain-page-title{
        background-color: #EDF9FE;
        .page-title-wapper{
            padding-right: 20rem;
            
            .page-title{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 34px;
                    color: #3D3838;
                }
            }
            .page-breadcrumb-wapper{
                .breadcrumb {
                    a{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 30px;
                    }
                }
            }
       
            @media screen and (max-width: 1400px) {
                padding-right:4rem;}
        }       
       
        @media screen and (max-width: 767px) {
            display:none;}
  

    }
    .editpagemain-main{
        padding-right: 16rem;
        padding-left: 1rem;
        .image-change-wapper{
            height: 180px;
            width: 180px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            img{
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
            svg{
                font-size: 35px;
                position: absolute;
                background-color: #0180FF;
                padding: 5px;
                color: white;
                border-radius: 50%;
                bottom: 2rem;
                right: 0;
            }
        }
        .editpage-main-wapper{
            padding: 20px;
            .info-change-wapper{
                .phone-input{
                    .input-color{
                        background: #FAFCFF;
                        border: 1px solid #ced4da !important;
                        height: 50px;
                        .PhoneInput{
                            background: transparent;
                            border: none;
                            
                            .PhoneInputCountryIcon{
                                width: 40px;
                                height: auto;
                                border: none;
                            }
                            .PhoneInputInput{
                                pointer-events: none;
                                display: none;
                            }
                        }
                        input{
                            border: 0px;
                            background-color: transparent;
                        }
                    }
                }
                .form-label{
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 19px;
                    color: #313131;
                    width: 100%;
                    .verified-wapper{
                          float: right;
                        .unverify{
                            color: #c1a006;
                            font-style: italic;
                            svg{
                                font-size: 20px;
                                margin-right: 5px;
                            }
                        }
                        .verify{
                            color: #0180ff;
                            font-style: italic;
                            svg{
                                font-size: 20px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .form-control{
                    background-color: #FAFCFF;
                    border: 1px solid #B1C0CC;
                    border-radius: 4px;
                    height: 52px;
                    margin-bottom: 15px;
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 21px;
                    color: #040404;
                }
            }
            button{
                background-color: #0180FF;
                border-radius: 4px;
                font-family: 'Plus Jakarta Sans';
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                color: #FFFFFF;
                padding: 15px 20px;
            }
        }
        
        @media screen and (max-width: 1600px) {
            padding-right: 9rem;

        }
       
        @media screen and (max-width: 1400px) {
            padding-right: 6rem;

        }
        @media screen and (max-width: 1024px) {
            padding-right: 1rem;

        }
   
    }
    
`;