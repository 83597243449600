import styled from 'styled-components';


export const PrivacyPolicyStyle = styled.section`

     .privacypolicy-wapper{
          background-color: #fff;
          .privacypolicy-title{
               background-color: #EDF9FE;
          }
     }


`;