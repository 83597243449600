import styled from 'styled-components';


export const NoLiveAuctionStyle = styled.section`

.nolive-auction-wapper{
    .nolive-auction-alert{
        background-color: #f8f3d6;
        border-color: #f8f3d6;
        display: flex;
        align-items: center;
        padding: 20px 2rem;
        .icon-wapper{
            display: inline;
            float: left;
            margin-right: 10px;
            svg{
                color: #997537;
                font-size: 35px;
            }
        }
        .content-wapper{
            display: inline;
            float: left;
            width: 65%;
            .alert-heading{
                color: #997537;
                margin-bottom: 0;
            }
            p{
                color: #997537;
                font-size: 13px;
                margin-bottom: 0;
                font-weight: 600
            }
        }
        .button-wapper{
            display: inline;
            .btn{
                background-color: #926f33;
                border-radius: 40px;
                font-size: 13px;
                padding: 7px 30px;
                text-transform: uppercase;
                border-color: #926f33;
            }
        }
   
    }

    @media screen and (max-width: 767px) {
        .nolive-auction-alert{        
                    align-items: center;
                    padding: 20px 1rem;
                    flex-direction: column;
        }

        .content-wapper{
            width:100%;
            text-align:center;
        }

    }


}
    

`;