    import styled from "styled-components";

    export const AddFundsFormStyle = styled.section`

        .amount-currency-wapper{

            .package-type{
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                color: #0b3b71;
                font-family: 'Plus Jakarta Sans';
            }

            .input-group {
                display: flex;
                justify-content: center;

                .amount-feild input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                appearance: none;
                border-radius: 0rem 0.375rem 0.375rem 0rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }   
            }
                .package-range{
                    display:flex;
                    font-size:12px;
                    font-family: 'Plus Jakarta Sans';
                    justify-content: center;

                }
            .you-pay{
                font-family: 'Plus Jakarta Sans';
                font-size: 18px;
                text-align: center;
                margin-right: 15px;
            }

            .error-msg {
                text-align: center;
                color: red;
            }

                .winAuction_amount-currency-wapper.mt-5
                {
                    text-align: center;
                    font-family: 'Plus Jakarta Sans';
                    h4{
                        font-weight: 700;
                    }

                    .aomout_instruction {
                        display: inline-flex;
                        justify-content: space-between;
                        width: 100%;
                        border: solid 1px #AAB6C3;
                        border-radius: 6px;
                        padding: 15px;

                        h5{
                            margin-bottom: 0px;
                            font-size: 14px;
                            font-weight: 400;
                            font-famimly: "Plus Jakarta Sans";
                            color: rgb(52, 52, 52);
                            padding: 4px 0;
                        }
                        h4{
                            margin-bottom: 0px;   
                            font-size: 17px;
                            font-famimly: "Plus Jakarta Sans";
                            text-transform: uppercase;
                            font-weight: 700;
                            color: rgb(52, 52, 52);
                            padding: 2px 0;
                        }
                    }
                    .aomout_instruction.last {
                        span{
                            font-size: 17px;
                            font-famimly: "Plus Jakarta Sans";
                            text-transform: uppercase;
                            font-weight: 700;
                            color: #DF250B;
                        }

                    }
                

                }
                button{
                    margin: 0 auto;
                    height: 45px;
                    background-color: #FFD821;
                    font-family: 'Plus Jakarta Sans';   
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 33px;
                    color: #000000;
                    width: 35%;
                    border: none;
                    display: block;
                    margin-top: 20px;
               
        
                @media screen and (max-width: 767px) {
                    width:100%;
                }
            
            }
    
  
        }
    `;


    export const CheckoutFormStyle = styled.section`

    .payment-form-wapper{
            #payment-form{
                width: 50%;
                display: block;
                margin: 0 auto;
                #submit{
                    margin: 0 auto;
                    height: 45px;
                    background-color: #FFD821;
                    font-family: 'Plus Jakarta Sans';
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 33px;
                    color: #000000;
                    width: 100%;
                    border: none;
                    border-radius:6px;
                    display: block;
                    margin-top: 20px;
                }     
            @media screen and (max-width: 767px) {
                width:70%;}
            }
        }

    `;