import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Row, Col, InputGroup, Button, Modal } from "react-bootstrap";
import ExportApi from "../../../../Api/ExportApi";
import { loader } from "../../../../loader";
import PageHeading from "../../../common/PageHeading/PageHeading";
import { EditProfileMainStyle } from "./EditProfileMainStyle";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import convert from "image-file-resize";
import OTPForm from "../../../OTP/OTPForm/OTPForm";
import { useDispatch, useSelector } from "react-redux";
import { saveProfile } from "../../../../Redux/Slices/TransactionSlice";
import { useNavigate } from "react-router-dom";

const EditProfileMain = () => {
  const profile = useSelector((state) => state?.APIData?.Profiledata);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [imagep, setImagep] = useState();
  const [Fname, setFname] = useState(profile.user_details?.first_name);
  const [Lname, setLname] = useState(profile.user_details?.last_name);
  const [Mobile, setMobile] = useState(profile.user_details?.mobile);
  const [Show, setShow] = useState(false);
  const [value, setValue] = useState();
  const [custonCountryCode, setCustomCountryCode] = useState();
  const [BidderId, setBidderId] = useState(profile?.user_details?.bidding_id);
  const [Count, setCount] = useState();
  const [valueError, setValueError] = useState(false);
  const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const [Verify, VerifyPopup] = useState(false);

    const [showdelete, setShowDelete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
  const handleClosedelete = () => setShowDelete(false);
  const handleShowdelete = () => setShowDelete(true);

  const handleClose = () => VerifyPopup(false);

  const handleShow = () => {
    ExportApi.VerifyPhone(Mobile, date, localStorage.getItem("Token")).then(
      (res) => {
        if (res.data) {
          VerifyPopup(true);
        } else {
          VerifyPopup(false);
        }
      }
    );
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      UpdateProfile();
    }

    setValidated(true);
  };
  const handeleimage = async (e) => {
    if (e?.target?.files[0]) {
      if (e?.target?.files[0].type.match(/\/(jpg|jpeg|png)$/)) {
        let formData = new FormData();
        convert({
          file: e.target.files[0],
          width: 300,
          height: 300,
          type: "jpeg",
        })
          .then((respg) => {
            formData.append("image_path", respg);
            ExportApi.UpdateProfileDP(formData)
              .then((resp) => {
                setValueError(false);
                if (resp.data) {
                  if (resp.data.success === true) {
                    setImagep();
                    toast.success(resp.data.message);
                    loader("hide");
                    // window.dispatchEvent(new Event("edit"));
                    let file = respg;
                    const imgViewwHeader =
                      document.getElementById("imgViewwHeader");
                    const previewTop = document.getElementById("imgViewwTop");
                    const preview = document.getElementById("imgVieww");
                    const reader = new FileReader();
                    reader.addEventListener(
                      "load",
                      function () {
                        previewTop.src = reader.result;
                        preview.src = reader.result;
                        imgViewwHeader.src = reader.result;
                      },
                      false
                    );
                    reader.readAsDataURL(file);
                    setShow(false);
                  } else {
                    loader("hide");
                    toast.warn(resp.data.message);
                  }
                }
              })
              .catch((err) => {
                loader("hide");
                toast.error("something went wrong");
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        toast.warn("Image to be uploaded in jpg,jpeg,png format");
      }
    } else {
      toast.warn("Please choose image");
    }
  };
  const UpdateProfile = () => {
    setCount(Count + 2);
    let date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let formData = new FormData();
    //   formData.append("image_path", imagep1?imagep1:"")
    formData.append("first_name", Fname);
    formData.append("last_name", Lname);
    formData.append("mobile", Mobile);
    formData.append("date_time", date_time);
    formData.append("country_code", value);
    formData.append("country_code_symbol", custonCountryCode);
    setCount(Count + 23);
    ExportApi.UpdateProfileData(
      value.first_name,
      value.last_name,
      value.mobile,
      value.date_time,
      value.timezone
    );
    if (value === undefined) {
      setValueError("Please select country code");
    } else if (Mobile === undefined) {
      setValueError("Please enter mobile number");
    } else {
      ExportApi.UpdateProfileData(formData)
        .then((resp) => {
        //   console.log(resp)
          setValueError(false);
          if (resp.data) {
            if (resp.data.success === true) {
              toast.success(resp.data.message);
              window.dispatchEvent(new Event("EventLength"));
              loader("hide");
            }
            else {
              toast.warn(resp?.data?.data, {
                toastId: "account-update-message",
              });
              loader("hide");
            }
          }
        })
        .catch((err) => {
          console.log('err==', err)
          loader("hide");
          toast.error("something went wrong", {
            toastId: "account-update-wrong",
          });
        });
    }
  };
  const getData = () => {
    ExportApi.getProfileData()
      .then((resp) => {
        if (resp.data) {
          dispatch(saveProfile(resp.data));
          loader("hide");
          setValue(resp.data.country_code);
        }
      })
      .catch((err) => loader("hide"));
  };

    let Navigate = useNavigate();
    const CheckValidTokenn = () => {
        if (!localStorage.getItem("Token")) {
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            Navigate("/login");
        }
    };


    const deletefun = () => {
        loader("show");
        setIsOpen(false);
        let Token = localStorage.getItem("Token");
        ExportApi.UserDelete(Token)
            .then((resp) => {
                if (resp.data) {
                    if (resp.data.success === true) {
                        setShow(false);
                        localStorage.removeItem("Token");
                        localStorage.removeItem("User_ID");
                        localStorage.removeItem("email");
                        localStorage.removeItem("mobile");
                        window.dispatchEvent(new Event("EventLength"));
                        loader("hide");
                        setIsOpen(false);
                        Navigate("/login");
                    }
                }
            })
            .catch((err) => setIsOpen(false));
    };

  useEffect(() => {
    CheckValidTokenn();
    getData();
  }, [Verify]);

  const onChangeCallingCode = useCallback((text)=> {
    setCustomCountryCode(text)
     }, [value])


  return (
    <>
      <div className="profile-wrapper">
        <div className="row mx-0">
          <div className="col">
            <EditProfileMainStyle>
              <div className="editpagemain-page-title">
                <div className="pt-4">
                  <PageHeading pagetitle={`Profile `} />
                </div>
              </div>
              <div className="editpagemain-main mb-5">
                <div className="image-change-wapper">
                  <img
                    src={
                      profile.image
                        ? profile.image
                        : "https://dummyimage.com/250x250/e7e7e7/c9c9c9.jpg&text=Yolop+Dummy+image+holder.js/100px180"
                    }
                    alt=""
                    id="imgVieww"
                    loading="lazy"
                  />
                  <Icon
                    icon="fluent:edit-16-filled"
                    onClick={() => {
                      setShow(true);
                    }}
                  />
                </div>
                <div className="editpage-main-wapper">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="info-change-wapper">

                    <Row className="">
                        <Form.Group
                          as={Col}
                          sm="12"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Bidder Id</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            disabled
                            placeholder="Bidder Id"
                            value={BidderId}
                            onChange={(e) => {
                              setBidderId(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="">
                        <Form.Group
                          as={Col}
                          md="12"
                          lg="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="First Name"
                            value={Fname}
                            onChange={(e) => {
                              setFname(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          lg="6"
                          controlId="validationCustom02"
                        >
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Last Name"
                            value={Lname}
                            onChange={(e) => {
                              setLname(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="">
                        <Form.Group
                          as={Col}
                          md="12"
                          lg="6"
                          controlId="validationCustomUsername"
                        >
                          <Form.Label>
                            Email Address
                            <div className="verified-wapper">
                              <div className="verify">
                                <Icon icon="material-symbols:verified-rounded" />
                                verified
                              </div>
                            </div>
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="email"
                              disabled
                              placeholder="Email Address"
                              aria-describedby="inputGroupPrepend"
                              required
                              value={profile.user_details?.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please choose a Email Address.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          lg="6"
                          className="phone-input"
                          controlId="validationCustomUsername"
                        >
                          <Form.Label>
                            {/* <Icon icon="ion:phone-portrait-outline" /> */}
                            Phone Number
                            <div className="verified-wapper">
                              {profile.user_details?.phone_verified_at ? (
                                <div className="verify">
                                  <Icon icon="material-symbols:verified-rounded" />
                                  Verified
                                </div>
                              ) : (
                                <div className="unverify" onClick={handleShow}>
                                  <Icon icon="octicon:unverified-16" />
                                  Verify Your Number
                                </div>
                              )}
                            </div>
                          </Form.Label>
                          <div className="input-color">
                            <Row>
                              <InputGroup hasValidation>
                                <Col xs={2}>
                                  <PhoneInput
                                    international
                                    className="form-control "
                                    defaultCountry={profile?.country_code_symbol}
                                    onChange={setValue}
                                    onCountryChange={onChangeCallingCode}
                                  />
                                </Col>
                                <Col xs={10}>
                                  <Form.Control
                                    type="number"
                                    placeholder="+1 078202 7292"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    name="mobile"
                                    value={Mobile}
                                    onChange={(e) => {
                                      setMobile(e.target.value);
                                    }}
                                  />
                                  <div style={{ color: "red" }}>
                                    {valueError}
                                  </div>
                                  <Form.Control.Feedback type="invalid">
                                    Please enter mobile number
                                  </Form.Control.Feedback>
                                </Col>
                              </InputGroup>
                            </Row>
                          </div>
                        </Form.Group>
                      </Row>
                    </div>
                    <Button className="mt-3" type="submit">
                      Save Changes
                    </Button>
                   <Button style={{ float: "right" }}  className="mt-3" onClick={handleShowdelete}>
                                          Delete Account
                      </Button>
                  </Form>
                                  
                </div>
              </div>
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={Show}
                id="webinar_event"
                onHide={() => {
                  setShow(false);
                }}
              >
                <Modal.Body>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Please Upload Your Profile Picture</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        setImagep(e);
                      }}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => {
                      handeleimage(imagep);
                    }}
                  >
                    Upload
                  </Button>
                  <Button onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>
              </Modal>


                          <Modal
                              show={showdelete}
                              onHide={handleClosedelete}
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                          >
                              <Modal.Header closeButton>
                                  <Modal.Title>Delete Account</Modal.Title>
                              </Modal.Header>
                              <Modal.Body><b>Are you sure about deleting your Yolop account?</b><br></br><br></br>
                                  Please note that if you choose to delete your Yolop account, all of
                                  your data will be permanently lost and cannot be recovered.
                              </Modal.Body>
                              <Modal.Footer>
                                  <Button
                                      variant="primary"
                                      onClick={() => {
                                          deletefun();
                                      }}
                                  >
                                      OK
                                  </Button>
                              </Modal.Footer>
                          </Modal>

              <Modal
                show={Verify}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="verifypop"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Verify Your Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <OTPForm phone={Mobile} type={{type: "verifyPhone",  VerifyPopup}} />
                </Modal.Body>
              </Modal>
            </EditProfileMainStyle>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfileMain;
