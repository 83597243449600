import React, { useEffect, useState, useCallback } from "react";
import { DashboardMainStyle } from "./DashboardMainStyle";
// import { Col, Row } from "react-bootstrap";
import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "react-bootstrap";
import PageHeading from "../../common/PageHeading/PageHeading";
import DashboardAuctionsCard from "../common/DashboardAuctionsCard/DashboardAuctionsCard";
import HistoryTable from "../common/HistoryTable/HistoryTable";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import ExportApi from "../../../Api/ExportApi";
import { useDispatch } from "react-redux";
import { saveTransaction } from "../../../Redux/Slices/TransactionSlice";
import ContentLoader from "react-content-loader";
import socket from "../../../socket";
import NoLiveAuction from "../common/DashboardAuctionsCard/NoLiveAuction/NoLiveAuction";
import AuctionStartVideo from "./AuctionStartVideo/AuctionStartVideo";
import { DashboardAuctionsCardStyle } from "../common/DashboardAuctionsCard/DashboardAuctionsCardStyle";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loader } from "../../../loader";

const DashboardMainFN = () => {
    const [transaction, setTransaction] = useState([]);
    const [Live, setLive] = useState();
    const [Loader, setLoader] = useState(false);
    const [isLive, setIsLive] = useState(false);
    const [auctionStartTimeLeft, setAuctionStartTimeLeft] = useState();
    const [videoUrl, setVideoUrl] = useState();
    const [buyPopup, setBuyPopup] = useState(false);
    const [winnerData, setWinnerData] = useState();
    const [completeAuctionView, setCompleteAuctionView] = useState(false);

    let Navigate = useNavigate();

    const dispatch = useDispatch();

    const handleClose = () => setBuyPopup(false);

    const joinedUserId = useSelector(
        (state) => state.APIData.Profiledata.user_details?.user_id
    );



    useEffect(() => {
        socket.on("connect").connect();
        socket.on("preAuction", (preauction) => {
            setAuctionStartTimeLeft(preauction.diff);
            setVideoUrl(preauction?.video_url);
        });
        socket.on("liveAuctionData", (val) => {
            setLive(val);
            setAuctionStartTimeLeft();
            setIsLive(true);
            loader("hide");
        });
        socket.on("winnerdData", (winnerdata) => {
            const totalDuration = winnerdata.totalDuration - winnerdata.duration;
            const currentDate = new Date();
            const date_time = currentDate.toUTCString();
            ExportApi.BuyAuction(
                winnerdata.auctionId,
                winnerdata.Price,
                winnerdata.winning_price,
                date_time,
                winnerdata.getWinner,
                totalDuration,
                winnerdata.winning_discount
            ).then((res) => {
                setBuyPopup(true);
                setWinnerData(res.data.data);
                setIsLive();
            });
        });
        socket.on("endAuctionData", (enddata) => {
            setIsLive(false);
            setIsLive();
            setCompleteAuctionView(true);
        });
        socket.on("completeAuctionData", (complete) => {
            setIsLive(false);
            setIsLive();
            setCompleteAuctionView(true);
        });
        return () => {
            socket.disconnect()
        }
    }, [isLive]);

    const CheckValidToken = () => {
        // console.log('checking continueously for logging in')
        // ExportApi.CheckToken()
        //   .then((resp) => {
        //     if(resp.status != 200) {
        //       loader("show");
        //       localStorage.removeItem("Token");
        //       localStorage.removeItem("User_ID");
        //       window.dispatchEvent(new Event("EventLength"));
        //       Navigate("/login");
        //       loader("hide");
        //     }
        //   })
        //   .catch((err) => console.log(err));
        if (!localStorage.getItem("Token")) {
            loader("show");
            localStorage.removeItem("Token");
            localStorage.removeItem("User_ID");
            window.dispatchEvent(new Event("EventLength"));
            Navigate("/login");
            loader("hide");
        }
    };


    const DashboardTransactionData = () => {
        ExportApi.StripeTransaction()
            .then((resp) => {
                if (resp.data) {
                    setTransaction(resp.data.data);
                    dispatch(saveTransaction(resp.data.data));
                    setLoader(false);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        CheckValidToken()
        DashboardTransactionData();
        setLoader(true);
    }, []);

    const remainingTimeLeft = () => {
        const minutes = Math.floor(auctionStartTimeLeft / 60);
        const seconds = auctionStartTimeLeft % 60;
        return `${minutes}:${seconds}`;
    };
    return (
        <>
            {/* <div><PageHeading pagetitle={`Dashboard`} /></div> */}
            <DashboardMainStyle>
                <div className="dashboard-page-title">
                    <div className="pt-4">
                        <PageHeading pagetitle={`Dashboard`} />
                    </div>
                </div>
                <div className="dashboard-main">
                    <div className="dashboard-liveauctions-cart">
                        {!isLive && (
                            <NoLiveAuction />
                        )}
                        {auctionStartTimeLeft > 15 && videoUrl && (
                            <AuctionStartVideo id={videoUrl} />
                        )}
                        {auctionStartTimeLeft < 16 && auctionStartTimeLeft > 1 && (
                            <DashboardAuctionsCardStyle>
                                <h1>
                                    <center>Auction Starts in </center>
                                </h1>
                                <center>
                                    <CountdownCircleTimer
                                        isPlaying
                                        duration={15}
                                        initialRemainingTime={auctionStartTimeLeft}
                                        colors={["#f31616", "#f7e92f", "#f8ea25", "#fc1b1b"]}
                                        colorsTime={[7, 5, 5, 0]}
                                    // onComplete={() => setLive(true)}
                                    >
                                        {remainingTimeLeft}
                                    </CountdownCircleTimer>
                                </center>
                            </DashboardAuctionsCardStyle>
                        )}

                        {auctionStartTimeLeft === 1 && !isLive && loader("show")}

                        {isLive && <DashboardAuctionsCard userId={joinedUserId} />}
                    </div>
                    <div className="dashboard-upcomingauctions-list mt-5">
                        <div className="dashboard-historytable-title">
                            <div className="dashboard-title-wapper">
                                <h2>Recently Activity</h2>
                            </div>
                        </div>
                        {Loader ? (
                            <div className="row mb-5">
                                {["1", "2", "3", "4"].map((index) => (
                                    <ContentLoader
                                        className="col-md-12"
                                        style={{ width: "100%" }}
                                        height={62}
                                        key={index}
                                    >
                                        <rect y="17" rx="4" ry="4" width="100%" height="62" />
                                    </ContentLoader>
                                ))}
                            </div>
                        ) : (
                            <HistoryTable type={"Dashboard"} data={transaction} />
                        )}
                    </div>

                    <div className="dashboard-main-item-wapper mb-4">
                        <div className="dashboard-main-inner">
                            <Row>
                                <Col xs={12} md={12} lg={6} xl={4}>
                                    <Link to={"/upcoming-auctions"}>
                                        <div className="dashboard-main-item active mb-3">
                                            <div className="dashboard-main-item-main-icon mb-4">
                                                <Icon icon="ri:auction-line" />
                                            </div>
                                            <div className="dashboard-main-item-title">
                                                <h2 className="item-title">My Upcoming Auctions</h2>
                                                <div className="dashboard-main-item-icon">
                                                    <Icon icon="cil:arrow-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={4}>
                                    <Link to={"/transactionhistory"}>
                                        <div className="dashboard-main-item mb-3">
                                            <div className="dashboard-main-item-main-icon mb-4">
                                                <Icon icon="ri:exchange-dollar-line" />
                                            </div>
                                            <div className="dashboard-main-item-title">
                                                <h2 className="item-title">Transaction History</h2>
                                                <div className="dashboard-main-item-icon">
                                                    <Icon icon="cil:arrow-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={4}>
                                    <Link to={"/editprofile"}>
                                        <div className="dashboard-main-item mb-3">
                                            <div className="dashboard-main-item-main-icon mb-4">
                                                <Icon icon="bx:user" />
                                            </div>
                                            <div className="dashboard-main-item-title">
                                                <h2 className="item-title">My Profile</h2>
                                                <div className="dashboard-main-item-icon">
                                                    <Icon icon="cil:arrow-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={4}>
                                    <Link to={"/notifications"}>
                                        <div className="dashboard-main-item mb-3">
                                            <div className="dashboard-main-item-main-icon mb-4">
                                                <Icon icon="ion:notifications-outline" />
                                            </div>
                                            <div className="dashboard-main-item-title">
                                                <h2 className="item-title">Notifications </h2>
                                                <div className="dashboard-main-item-icon">
                                                    <Icon icon="cil:arrow-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={4}>
                                    <Link to={"/helpsupport"}>
                                        <div className="dashboard-main-item mb-3">
                                            <div className="dashboard-main-item-main-icon mb-4">
                                                <Icon icon="humbleicons:support" />
                                            </div>
                                            <div className="dashboard-main-item-title">
                                                <h2 className="item-title">Help & Support</h2>
                                                <div className="dashboard-main-item-icon">
                                                    <Icon icon="cil:arrow-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={4}>
                                    <Link to={"/referEarn"}>
                                        <div className="dashboard-main-item mb-3">
                                            <div className="dashboard-main-item-main-icon mb-4">
                                                <Icon icon="ci:share" />
                                            </div>
                                            <div className="dashboard-main-item-title">
                                                <h2 className="item-title"> Referal & Earn</h2>
                                                <div className="dashboard-main-item-icon">
                                                    <Icon icon="cil:arrow-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Modal
                    show={buyPopup}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    className="congratulationspop"
                >
                    <Modal.Header closeButton> </Modal.Header>
                    <Modal.Body>
                        <div className="text-center cong-inner-content">
                            <img src="/images/congratulations-1.png" alt="" />
                            <h3>Congratulations!</h3>
                            <p>
                                You Won
                                <strong> {winnerData?.name}</strong> bid successfully!
                            </p>
                            <div className="save-outer-wapper">
                                <div className="save-inner-wapper">
                                    <p>You only pay</p>
                                    <h2>
                                        {winnerData?.currency} &nbsp;
                                        {winnerData?.Amount?.toFixed(2)}
                                    </h2>
                                </div>
                            </div>
                            <div className="pay-outer-wapper">
                                <p>You saved  <span>{winnerData?.currency} {(winnerData?.auction_price - winnerData?.Amount).toFixed(2)}</span></p>
                            </div>
                            <div className="button-wapper">
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        Navigate("/addfunds", {
                                            state: {
                                                amount: winnerData.Amount,
                                                auction_id: winnerData.id,
                                                currency_name: winnerData.currency_name,
                                                currencySymbol: winnerData.currency,
                                                vat_price: winnerData.vat_price,
                                                total_price: winnerData.total_price,
                                            },
                                        })
                                    }
                                >
                                    Checkout
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={completeAuctionView}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    className="congratulationspop"
                >
                    <Modal.Header
                        closeButton
                        onClick={() => setCompleteAuctionView(false)}
                    >
                        {" "}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center cong-inner-content">
                            <img src="/images/congratulations.png" alt="" />
                            <h3>Auction Completed</h3>
                            <div className="button-wapper">
                                {/* <Button variant="primary" onClick={() => Navigate( "/dashboard")}></Button> */}
                                <Button
                                    variant="primary"
                                    onClick={() => setCompleteAuctionView(false)}
                                >
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </DashboardMainStyle>
        </>
    );
};

export default DashboardMainFN;