import React from "react";
import { GettingStartedCardStyle } from "./GettingStartedCardStyle";
import { CardGroup, Card } from "react-bootstrap";

import { Icon } from "@iconify/react";

const GettingStartedCard = () => {
  return (
    <GettingStartedCardStyle>
      <div className="gettingstarted-card-wapper">
        <CardGroup>
          <Card className="first-card">
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className="icon-wapper">
              <Icon icon="bx:user-plus" />
            </div>
            <Card.Body>
              <Card.Title>01. Create an Account</Card.Title>
              <Card.Text>
              Join the Yolop community by creating an account in just a few clicks. Simply add your details and start bidding on exclusive commodities.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mid-card">
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className="icon-wapper">
              <Icon icon="mingcute:auction-line" />
            </div>
            <Card.Body>
              <Card.Title>02. Place Your Bid</Card.Title>
              <Card.Text>
              Ready to win big? Place your bid now and seize the chance to win big with Yolop! 
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="last-card">
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className="icon-wapper">
              <Icon icon="typcn:tick-outline" />
            </div>
            <Card.Body>
              <Card.Title>03. Cheers! You Won The Auction</Card.Title>
              <Card.Text>
              Woohoo! you’ve won the auction. Winning an auction on Yolop is an exciting experience every time.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    </GettingStartedCardStyle>
  );
};
export default GettingStartedCard;
