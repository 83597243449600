import styled from 'styled-components';


export const AuctionsCardStyle = styled.section`

    .search-auctions-list-inner{
        .search-auctions-list{
            .search-auctions-item{
                border-bottom: 1px solid #ADC3CD;
                padding: 5px 30px;
                background-color: white;
                margin-bottom: 5px;
                
                .search-auctions-item-image{
                    .search-auctions-img{
                        width: 100%;
                        border-radius: 6px;
                        height: 40px;
                        object-fit: contain;
                    }
                }
                .search-auctions-item-card{
                    
                    .search-auctions-title-wapper{
                        .search-auctions-title{
                            span{
                                font-family: 'Plus Jakarta Sans';
                                font-weight: 600;
                                font-size: 19px;
                                line-height: 15px;
                                color: #252525;
                                text-decoration: none;
                            }
                        }
                        .search-auctions-disc{
                            p{
                                font-family: 'Plus Jakarta Sans';
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 13px;
                                color: #615F5F;
                            }
                        }
                    }
                    .search-auctions-info-inner{
                        .info-heading{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 12px;
                            color: #373737;
                            text-transform: uppercase;
                        }
                        .price{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 20px;
                            color: #565656;
                            margin-top: 5px;
                        }
                        .auction-time{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            align-items: center;
                            color: #E90E00;
                            margin-top: 5px;
                        }
                    }
                    .search-auctions-buttom-wapper{
                        .search-auctions-buttom{
                            /* a{
                                text-decoration: none; */
                                button{
                                    margin-top: 10px;   
                                    width: 100%;
                                    background-color: #FFFFFF;
                                    border: 1px solid #A0A0A0;
                                    border-radius: 4px;
                                    height: 38px;
                                    font-family: 'Plus Jakarta Sans';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 16px;
                                    text-align: center;
                                    text-transform: uppercase;
                                    color: #000000;
                                    display: block;
                                    padding: 9px;
                                }
                            /* } */
                        }
                    }
                }
            }
        }
    }


`;