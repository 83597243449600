import Container from "react-bootstrap/Container";
import { Modal, Button, Col } from "react-bootstrap";
import SectionTitle from "../../common/SectionTitle/SectionTitle";
import { useEffect, useState } from "react";
import ExportApi from "../../../Api/ExportApi";
import Paginations from "../../common/Pagination/Paginations";
import MyAuctionsList from "../../Dashboard/common/MyAuctionsList/MyAuctionsList";
import ContentLoader, { List } from "react-content-loader";
import { useNavigate } from "react-router-dom";

const WonAuction = ({ type, wonData }) => {
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState([]);
  const [loader, setLoader] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [profile, setProfile] = useState();
  const [wonauctiondata, setWonauctiondata] = useState([]);
  const [wonLastAuction, setWonLastAuction] = useState([]);
  const [winPreviousAuction, setWinPreviousAuction] = useState([]);

  let Navigate = useNavigate();
  const WonAuctionDetail = () => {
    ExportApi.WonAuctions(currentPage, itemsPerPage)
      .then((resp) => {
        if (resp.data) {
          setWonauctiondata(resp.data.data);
          setWonLastAuction(resp.data.data.slice(0, 2));
          setTotalCount(resp.data.meta);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const WonPreviousDetail = () => {
    ExportApi.PrevWonAuctions(timeZone)
      .then((resp) => {
        setWinPreviousAuction(resp.data.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };

  const profileData = () => {
    ExportApi.getProfileData()
      .then((resp) => {
        setProfile(resp.data);
      })
      .catch((err) => console.log(err));
  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
  };

  const handleClose = () => {
    setCloseModal(false);
  };

  useEffect(() => {
    WonAuctionDetail();
    profileData();
  }, [currentPage]);

  useEffect(() => {
    profile?.due_payment?.length > 0 && setCloseModal(true);
    WonPreviousDetail();
  }, [profile]);

  return (
    <>
      {wonData ? (
        <>
          <Modal
            show={closeModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="wonauctionpop"
          >
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body>
              <div className="text-center cong-inner-content innerbox">
                <img src="/images/reminder.png" alt="" loading="lazy" />
                <h3>Reminder!</h3>
                <p>
                  Payment of
                  <b>
                    {""} {profile?.due_payment[0]?.custom_currency_symbol}&nbsp;
                    {profile?.due_payment[0]?.winning_price}{" "}
                  </b>
                  pending for the Auction
                  <strong> {profile?.due_payment[0]?.name}</strong> <br />
                  If you don’t pay the pending amount, you will not be able to
                  join the next auction. Kindly checkout to pay.
                </p>
                <div className="button-wapper">
                  <Button
                    variant="primary"
                    onClick={() =>
                      Navigate("/addfunds", {
                        state: {
                          amount: profile?.due_payment[0]?.winning_price,
                          auction_id: profile?.due_payment[0]?.auction_id,
                          currency_name: profile?.due_payment[0]?.currency_name,
                          currencySymbol:
                            profile?.due_payment[0]?.custom_currency_symbol,
                          vat_price: profile?.due_payment[0]?.vat_price,
                          total_price: profile?.due_payment[0]?.total_price,
                        },
                      })
                    }
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {wonauctiondata?.length > 0 && (
            <section className="auctions-main-wapper pt-5 pb-5">
              <Container>
                <div className="auctions-inner">
                  <SectionTitle
                    sectiontitle={"Won Auctions "}
                    sectionlinktext={"View All Auctions"}
                    sectionlink={"myauctions"}
                    tabs={"won"}
                  />
                  {loader ? (
                    <div className="row">
                      {["1", "2", "3", "4", "5", "6"].map((variant, index) => (
                        <div className="col-sm-4" key={index}>
                          <ContentLoader style={{ width: "100%" }} height={275}>
                            <rect
                              y="17"
                              rx="4"
                              ry="4"
                              width="100%"
                              height="275"
                            />
                          </ContentLoader>
                          <List className="mt-3" />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <MyAuctionsList
                        AuctionsData={type ? wonLastAuction : wonauctiondata}
                        type="won"
                      />
                      {!wonData && (
                        <Paginations
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          totalItems={totalCount?.total}
                          paginate={paginate}
                        />
                      )}
                    </>
                  )}
                  {/* {data ? <AuctionsCard AuctionsData = {data.slice(0,8)} /> : null} */}
                </div>
              </Container>
            </section>
          )}
          {wonauctiondata?.length <= 0 && (
            <Container>
              <div className="auctions-inner">
                <SectionTitle
                  sectiontitle={"Won Auctions "}
                  sectionlinktext={"View All Auctions"}
                  sectionlink={"myauctions"}
                  tabs={"won"}
                />
                <img
                  src="/images/Sorry,-no-WON-Auctions-so-far.jpg"
                  style={{ width: "100%" }}
                  className="no-upcomauction"
                  alt="Upcoming Auction"
                  loading="lazy"
                />
              </div>
            </Container>
          )}
        </>
      ) : (
        <section className="auctions-main-wapper pt-5 pb-5">
          <Container>
            <div className="auctions-inner">
              <h3 className="mb-4">
                <b>Latest Won Auctions</b>
              </h3>
              {loader ? (
                <Col lg={6}>
                  <div className="row ">
                    {["1", "2"].map((variant, index) => (
                      <div className="col-sm-6 " key={index}>
                        <ContentLoader style={{ width: "100%" }} height={275}>
                          <rect
                            y="17"
                            rx="4"
                            ry="4"
                            width="100%"
                            height="275"
                          />
                        </ContentLoader>
                        <List className="mt-3" />
                      </div>
                    ))}
                  </div>
                </Col>
              ) : winPreviousAuction.length <= 0 ? (
                <img
                  src="/images/Sorry,-no-WON-Auctions-so-far.jpg"
                  style={{ width: "100%" }}
                  className="no-auction"
                  alt="No Auction"
                  loading="lazy"
                />
              ) : (
                <MyAuctionsList
                  withoutLoginData={winPreviousAuction}
                  type="won"
                />
              )}
            </div>
          </Container>
        </section>
      )}
    </>
  );
};

export default WonAuction;
