import React from 'react'
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container, Row, Col} from "react-bootstrap";

import PageHeading from "../../Components/common/PageHeading/PageHeading";
import PageContent from "../../Components/common/PageContent/PageContent";
import { HowItWorksStyle } from './HowItWorksStyle';
import MetaTags from '../../Components/common/MetaTags';

const HowItWorks = () => {
  return (
    <HowItWorksStyle>
    <MetaTags
      title={'Yolop Support - Get Help and Assistance for Your Bidding Experience'}
      description={"Need assistance with your Yolop bidding experience? Contact our support team for prompt help and guidance. Get answers to your questions, resolve issues, and receive personalized support to enhance your bidding journey."}
      focusKeyphrase={'Support'}
              canonicalURL={'https://yolop.net/how-it-works'}
/>
    <div className="howitwork-wapper ">
    <div className="">
      <PageHeading pagetitle={"How It Works"} />
    </div>

    <Container className='content-wrapper'>
    <Row className='row-wrapper-1'>
      <p>Get started in a few minutes In three easy steps!</p>

    <Col sm={12} md={4}  className="top-img-sec justify-content-center">
                          <img className="about-img" src="/images/SignUp-2.png" alt="" loading="lazy" />
      <h2>Sign Up</h2>
      <p>Easy & quick sign up </p>
    </Col>

    <Col sm={12} md={4}  className="top-img-sec">
                          <img className="about-img" src="/images/Bid.png" alt="" loading="lazy" />
      <h2>Bid</h2>
      <p>Multiple bidding options </p>
    </Col>

    <Col sm={12} md={4}  className="top-img-sec">
                          <img className="about-img" src="/images/Win.png" alt="" loading="lazy" />  
      <h2>Win</h2>
      <p>Cheers! You won auction!</p>
    </Col> 
    </Row>

 

      <h2 className='mid-heading mt-5 mb-5'>Bid Securely and Transparently with Yolop</h2>
   

    <Row className='row-wrapper-2'style={{ backgroundImage: "url(/images/BG-1.png)",backgroundRepeat: 'no-repeat',objectFit:'cover' }}>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Sign Up</h3>
    <p>To join Yolop simply download the app from the App Store or the Play store. To become a Yolopian, create an account; registration is quick and easy, and only takes a few minutes. Click on the sign up option and register with your email id.</p>
    </Col>
    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/(Sign-Up)Bid-Securely-and-Transparently-with-Yolop.png" alt="" loading="lazy"/>  
    </Col>
    </Row>

    <Row className='row-wrapper-2' style={{ backgroundImage: "url(/images/BG-2.png)",backgroundRepeat: 'no-repeat', objectFit:'cover', backgroundPosition: 'right', backgroundPositionY:'top' }}>

    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/(Start-Browsing-The-Bids-Bid)-Securely-and-Transparently-with-Yolop.png" alt="" loading="lazy"/>  
    </Col>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Start Browsing The Bids</h3>
    <p>Once you're in, you can start browsing auctions that have a predetermined start price and time frame within which the auction must end. Yolop offers a variety of reverse-bidding auctions for items ranging from luxury or branded products to daily-use groceries.</p>
    </Col>
    </Row>

    <Row className='row-wrapper-2' style={{ backgroundImage: "url(/images/BG-3.png)",backgroundRepeat: 'no-repeat',objectFit:'cover', backgroundPosition: 'bottom', backgroundPositionX:'left' }}>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Pay & Join The Auction</h3>
    <p>Simply choose the auction you’re interested in. To join the auction  you will have to pay a small registration fee. The fee helps to keep the auction fair and ensures that only serious bidders are participating.</p>
    </Col>
    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/(Pay-&-Join-The-Auction)-Bid-Securely-and-Transparently-with-Yolop.png" alt="" loading="lazy"/>  
    </Col>
    </Row>

    <Row className='row-wrapper-2' style={{ backgroundImage: "url(/images/BG-4.png)",backgroundRepeat: 'no-repeat', objectFit:'cover', backgroundPosition: 'right', backgroundPositionY:'top' }}>

    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/(Bid-and-Watch-The-Price-Drop)-Bid-Securely-and-Transparently-with-Yolop.png" alt="" loading="lazy"/>  
    </Col>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Bid and Watch The Price Drop</h3>
    <p>Before starting the auction you will get notified through your registered mail id. Once the auction starts, the price of the item will drop with each passing second. Watch the price drop in real time and wait for the perfect moment to make your  move.</p>
    </Col>
    </Row>

    <Row className='row-wrapper-2' style={{ backgroundImage: "url(/images/BG-5.png)",backgroundRepeat: 'no-repeat',objectFit:'cover', backgroundPosition: 'bottom', backgroundPositionX:'left' }}>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Be The First to Click “Buy Now”</h3>
    <p>To Win the auction, you need to be the first to click the “Buy Now” button. The bidder who presses the button first will be declared the winner and can purchase the item at the current price. And for the other users the auction will be stopped.</p>
    </Col>
    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/Buy-Now.png" alt="" loading="lazy"/>  
    </Col>
    </Row>

    <Row className='row-wrapper-2' style={{ backgroundImage: "url(/images/BG-6.png)",backgroundRepeat: 'no-repeat', objectFit:'cover', backgroundPosition: 'right', backgroundPositionY:'top' }} >

    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/You-Won.png" alt="" loading="lazy"/>  
    </Col>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Congrats!  You Won</h3>
    <p>The winner will receive a congratulation message in a pop up notification recently after winning. From There you’ll need to proceed to checkout and pay for the item to complete the purchase.</p>
    </Col>
    </Row>

    
    <Row className='row-wrapper-2'  style={{ backgroundImage: "url(/images/BG-7.png)",backgroundRepeat: 'no-repeat',objectFit:'cover', backgroundPosition: 'bottom', backgroundPositionX:'left' }}>

    <Col sm={12} md={6}  className="text-sec">
    <h3>Checkout And Payments</h3>
    <p>Yolop accepts payments through Stripe, a secure and trusted payment platform. Once you've completed the payment the item will be delivered directly to your registered address.</p>
    </Col>
    <Col sm={12} md={6}  className="img-sec">
    <img className="about-img" src="/images/(Checkout-And-Payments)-Bid-Securely-and-Transparently with Yolop.png" alt="" loading="lazy"/>  
    </Col>
    </Row>
    <p className='mt-5 mb-5 last-text '>That's all! Participating in auctions has become simpler and more exciting with Yolop's reverse-bidding option. Sign up now to start bidding on high-value commodities at lower prices.</p>

    </Container>
  </div>
  </HowItWorksStyle>
  )
}

export default HowItWorks