import { ForgotPasswordMainStyle } from "./ForgotPasswordMainStyle";
import PageTitle from "../common/PageHeading/PageHeading";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm/ForgotPasswordForm";

const ForgotPasswordMain = () => {
  return (
    <ForgotPasswordMainStyle>
      <div className="forgotpassword-main-wapper pt-4 pb-5">
        <PageTitle pagetitle={"Forgot Password"} />
        <ForgotPasswordForm />
      </div>
    </ForgotPasswordMainStyle>
  );
};

export default ForgotPasswordMain;
