import styled from "styled-components";

export const DashboardStyle = styled.section`

 .dashboard-mob-breadcrumbs{
  display:none;
  background-color: #EDF9FE;
 }


  .dashboard-wapper {
    display: flex;
    .dashboard-siderbar-wapper {
      width: 30%;
      background-color: #ecf2f9;
      padding-left: 12rem;
    }
    .dashboard-main-wapper {
      width: 70%;
    }
  }
  @media screen and (max-width: 1600px) {
    .dashboard-wapper {
      .dashboard-siderbar-wapper {
        padding-left: 9rem;
      }
    }

  }
  
  
  @media screen and (max-width: 1400px) {
    .dashboard-wapper {
      .dashboard-siderbar-wapper {
        padding-left: 6rem;
      }
    }

  }
    
  @media screen and (max-width: 1024px) {
    .dashboard-wapper {
      .dashboard-siderbar-wapper {
        padding-left: 1rem;
      }
    }

  }

  

  @media screen and (max-width: 767px) {
    .dashboard-mob-breadcrumbs{
      display:block;}

      .dashboard-wapper {
        display: block;
        .dashboard-siderbar-wapper {
          width: 100%;
          padding-left: 0rem;
        }
        .dashboard-main-wapper {
          width: 100%;
        }
      }

  }


`;
