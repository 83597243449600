import styled from 'styled-components';


export const BitCoinsStyle = styled.section`

    .nft-main-wapper{
       background-color: #FFF;

       .page-title-wapper{
            background-color: #EDF9FE;
            padding: 2rem 0px;
       }
       

    }


`;