import React, { useEffect, useState } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";

import HeaderMain from "./Components/common/Header/HeaderMain";
import FooterMain from "./Components/common/Footer/FooterMain";
import Home from "./Pages/Home/Home";
import UpcomingAuctions from "./Pages/UpcomingAuctions/UpcomingAuctions";
import UpcomingBagAuctions from "./Pages/UpcomingCategoryAuctions/UpcomingBagAuctions";

import Login from "./Pages/Login/Login";
import SignUp from "./Pages/SignUp/SignUp";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import PaymentPolicy from "./Pages/PaymentPolicy/PaymentPolicy";
import Blog from "./Pages/Blog/Blog";
import BitCoins from "./Pages/BitCoins/BitCoins";
import NFT from "./Pages/NFT/NFT";
import Proctected from "./Auth/Proctected";
import DashboardMain from "./Pages/Dashboard/DashboardMain/DashboardMain";
import OTPMain from "./Components/OTP/OTPMain";
import ResetMainFN from "./Auth/ResetMainFN";
import AboutMainFN from "./Pages/About/About";
import BlogDetailsSingle from "./Components/common/BlogDetails/BlogDetailsSingle";
import CareersFN from "./Pages/Careers/Careers";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import AuctionsCardList from "./Components/common/AuctionsCardList/AuctionsCardList";
import AuctionsDetailsSingle from "./Components/common/AuctionsDetails/AuctionsDetailsSingle";
import Question from "./Pages/F&Q/Question";
import HowItWorks from "./Pages/HowItWorks/HowItWorks";
import ReferEarnHome from "./Pages/ReferEarn/ReferEarnHome";
import PrivacyPolicyMob from "./Pages/PrivacyPolicy copy/PrivacyPolicyMob";
import SupportFN from "./Pages/Support/Support";
import CookiesFN from "./Pages/CookiesPolicy/Cookies";
import GdprpolicyFN from "./Pages/GdprPolicy/Gdprpolicy";
import WearehiringFN from "./Pages/Hiring/Wearehiring";
import ContactFN from "./Pages/Contact/Contact";
import PackageshomeFN from "./Pages/Packages/Packages";
import { onMessageListener, notificationBrowserSupportCheck } from "./Firebase";
import RaffelModal from "./Components/RaffelModal.js/RaffelModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteAccount from "./Pages/DeleteAccount/DeleteAccount";

const App = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    notificationBrowserSupportCheck();
    onMessageListener({ dispatch: dispatch, onChangeValue: setVisible });
  }, []);

  useEffect(() => {
    const branch = window.branch;
    if (!branch) {
      console.error("Branch SDK not available");
      return;
    }

    branch.init("key_test_dvinsUGuztdU2PVTaV9VgcaozBkXbIZu", (err, data) => {
      if (err) {
        console.error("Error initializing Branch SDK:", err);
      } else {
        console.log("Branch SDK initialized successfully");
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <HeaderMain />
      {visible && <RaffelModal onChangeVisible={setVisible} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upcoming-auctions" element={<UpcomingAuctions />} />
        <Route path="/bag-auctions" element={<UpcomingBagAuctions />} />
        <Route path="/laptop" element={<NFT />} />
        <Route path="/mobile" element={<BitCoins />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verification/:id" element={<OTPMain />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/refunds-and-returns" element={<PaymentPolicy />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/details/:slug" element={<AuctionsDetailsSingle />} />
        <Route path="/blogs/:id" element={<BlogDetailsSingle />} />
        <Route path="/resetpassword/:id" element={<ResetMainFN />} />
        <Route path="/about" element={<AboutMainFN />} />
        <Route path="/faq" element={<Question />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/careers" element={<CareersFN />} />
        <Route path="/searchitem" element={<AuctionsCardList />} />
        <Route path="/refer-and-earn" element={<ReferEarnHome />} />
        <Route path="/support" element={<SupportFN />} />
        <Route path="/cookies-policy" element={<CookiesFN />} />
        <Route path="/gdpr-policy" element={<GdprpolicyFN />} />
        <Route path="/we-are-hiring" element={<WearehiringFN />} />
        <Route path="/contact-us" element={<ContactFN />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/our-packages" element={<PackageshomeFN />} />
        <Route
          path="privacy-policy-mobile-app"
          element={<PrivacyPolicyMob />}
        />
        <Route path="/delete-account" element={<DeleteAccount />} />

        <React.Fragment>
          <Route path="/" element={<Proctected />}>
            <Route path="/dashboard" element={<DashboardMain />} />
            <Route path="/addfunds" element={<DashboardMain />} />
            <Route path="/dashboard" element={<DashboardMain />} />
            <Route path="/myauctions" element={<DashboardMain />} />
            <Route path="/transactionhistory" element={<DashboardMain />} />
            <Route path="/editprofile" element={<DashboardMain />} />
            <Route path="/changepassword" element={<DashboardMain />} />
            <Route path="/notifications" element={<DashboardMain />} />
            <Route path="/helpsupport" element={<DashboardMain />} />
            <Route path="/packages" element={<DashboardMain />} />
            <Route path="/referEarn" element={<DashboardMain />} />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </React.Fragment>
      </Routes>
      <FooterMain />
    </BrowserRouter>
  );
};

export default App;
