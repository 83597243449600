import styled from 'styled-components';


export const PackageStyle = styled.section`

     .packages-wapper{
          padding-right: 0rem;
          background-color: #fff;

          .page-title-wapper {
               padding-top:1.5rem;
               padding-bottom:1.5rem;
          }
  
         .inner-wrapper{
          margin-bottom: 80px;

          .Packages-page-title{
               display: none;
          }

          .packages-wrapper{
               padding-right: 0rem;
               padding-top: 0rem;
               padding-left: 0rem;;
          }



          }




     }


`;