import styled from 'styled-components';


export const ReferEarnHomeStyle = styled.section`

    .refer-wapper{
        .refer-wapper-heading{
            background-color: #EDF9FE;
            margin-bottom: 2rem;
        }
    }

    .content-wrapper{

        .row-wrapper{
            align-items: center;
            .text-sec{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 60px;
                    line-height: 76px;
                    color: #000000;
                }

                p{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 37px;
                    color: #000000;

                    span{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 55px;
                        line-height: 55px;
                        color: #0070E0;
                    }

                }
            }
                .img-sec img{
                    float:right;
                    max-width:100%;
                }
        }
        
        .row-wrapper-1{
            padding-top: 50px;
            padding-bottom:50px;
            background-image: url(/images/refer&earnbg.png);
            background-repeat: no-repeat;
            object-fit: cover;
            background-position: 100% bottom;

            .img-sec-1 {
                img.refer-img {
                    max-width: 100%;
                }
            }

            .text-sec-1{
                // margin-left:-100px;

                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 44px;
                    color: #000000;
                }
                
                p{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    color: #585353;
                }
            
            }

        }
  
            .row-wrapper-2{
                padding-top: 50px;
                padding-bottom: 50px;
                background-image: url(/images/refer&earnbg2.png);
                background-repeat: no-repeat;
                object-fit: cover;
                background-position: 100% bottom;
        
                .text-sec-2{

                    h2{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        line-height: 44px;
                        color: #000000;
                    }
                    
                    p{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        color: #585353;
                    }
            
                }
                
                .img-sec-2 {

                    img.share-img {
                        max-width: 100%;
                    }
                }
        
            }
  
            .row-wrapper-3{
                padding-top: 50px;
                padding-bottom:50px;
                background-image: url(/images/refer&earnbg.png);
                background-repeat: no-repeat;
                object-fit: cover;
                background-position: 100% bottom;
    
                .img-sec-3 {
                    img.refer-img {
                        max-width: 100%;
                    }
                }
    
                .text-sec-3{
                    // margin-left:-100px;
    
                    h2{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        line-height: 44px;
                        color: #000000;
                    }
                    
                    p{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        color: #585353;
                    }
                
                }
    
            }
    
    }
 
    @media screen and (max-width: 1400px) {

        .content-wrapper{

            .row-wrapper-1{
                background-image:none;
            }

            .row-wrapper-2{
                background-image:none;

                .text-sec-2 {
                    order:2;
                    -webkit-order: 2;
                }

            }


            .row-wrapper-3{
                background-image:none;
            }

        }


    }

    @media screen and (max-width: 1024px) {

.content-wrapper{

    .row-wrapper{
            align-items: center;
            .text-sec{
                h2{
                    font-size: 40px;
                }

                p{
                    font-size: 25px;
                    line-height: 37px;

                    span{
                        font-size: 45px;
                        line-height: 45px;
                    }

                }
            }

        }

}


}

    @media screen and (max-width: 768px) {

.content-wrapper{

    .row-wrapper-2{

        .text-sec-2 {
            order:2;
            -webkit-order: 2;
        }

    }

}


}



`;