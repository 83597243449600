import * as React from "react";
import { NFTStyle } from "./NFTStyle";
import PageTitle from "../../Components/common/PageHeading/PageHeading";
import AuctionsMain from "../../Components/Auctions/AuctionsMain";

const NFTMainFN = () => {
    return (
        <NFTStyle>
            <div className="nft-main-wapper pb-5">
                <PageTitle
                    pagetitle={"Laptop"}
                // pageslug={''}
                />
                <AuctionsMain />
                
            </div>
        </NFTStyle>
    );
}

export default NFTMainFN;
