import styled from 'styled-components';


export const BlogCardStyle = styled.section`

    .blog-card-wapper{
        .card-group{
            .blog-card-item{
                width: 33.3%;
                .card{
                    background-color: transparent;
                    border: none;
                    padding: 10px;
                    img{
                        min-height: 332px;
                        object-fit: cover;
                    }
                    .card-body{
                        padding: 15px 0px!important;
                        .card-title{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 32px;
                            color: #000000;
                            min-height: 64px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            }
                       
                        a{
                            text-underline-offset: 0.5em;
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 25px;
                            letter-spacing: 0.01em;
                            color: #0180FF;

                        }
                    }
                }   
            }
        }
    }

    @media screen and (max-width: 767px){
        .blog-card-wapper{
            .card-group{
                .blog-card-item{
                    width: 50%;
                    margin: 0 auto;
                    .card{
                        .card-title{
                            min-height: auto !important;
                            }

                    }
                
                }
            }
        }
    }
    @media screen and (max-width: 576px){
        .blog-card-wapper{
            .card-group{
                .blog-card-item{
                    width: 100%;
                }
            }
        }
    }
`;