import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container } from "react-bootstrap";
import PageHeading from '../../Components/common/PageHeading/PageHeading';
import PageContent from '../../Components/common/PageContent/PageContent';
import { CareersStyle } from './CareersStyle';
import MetaTags from "../../Components/common/MetaTags";


function CareersFN() {
  return (
    <CareersStyle>
      <MetaTags
      title={'Join Our Team - Exciting Career Opportunities at Yolop'}
      description={"Explore the exciting career opportunities at Yolop. We are hiring talented individuals who are passionate about auctions, technology, and delivering exceptional experiences. Apply now and join our team!"}
      focusKeyphrase={'Career Opportunities'}
        canonicalURL={'https://yolop.net/careers'}
/>
      <div className='about-wapper pb-5'>
        <div className="pt-4">
          <PageHeading 
            pagetitle={`About Yolop`}
          />
        </div>       
        <Container>
          <PageContent pagecontent={`
            <h4>About Yolop </h4>
            <p> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
            sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
            veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis
            autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem
            eum fugiat quo voluptas nulla pariatur? </p>
            <h4> Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC </h4>
            <p> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
            sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
            veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis
            autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem
            eum fugiat quo voluptas nulla pariatur? </p>
          `}/>        
        </Container>

      </div>
    </CareersStyle>
  );
}

export default CareersFN;
