import styled from "styled-components";

export const ReferEarnStyle = styled.section`
  .referearn-page-title {
    background-color: #edf9fe;
    .page-title-wapper {
      padding-right: 20rem;

      .page-title {
        h2 {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          line-height: 34px;
          color: #3d3838;
        }
      }
      .page-breadcrumb-wapper {
        .breadcrumb {
          a {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      @media screen and (max-width: 1400px) {
        padding-right: 4rem;
      }
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .referearn-history-wapper {
    padding-right: 16rem;
    padding-left: 1.5rem;

    .row-wrapper-inner {
      background: linear-gradient(101.55deg, #0b4d8e 41.52%, #0862bc 85.02%);
      border-radius: 10px;
      padding: 60px 0px 0px 60px;

      .left-sec {
        h2 {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          color: #ffffff;
        }

        p {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #ffffff;

          span {
            color: #ffd821;
          }
        }
      }

      .right-sec {
        img.about-img {
          max-width: 100%;
        }
      }
    }

    .row-wrapper-reffer {
      background: #f3f3f3;
      border-radius: 10px;
      padding: 40px;

      P {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        color: #343a40;
        margin-bottom: 8px;
      }

      .outer {
        border: 2px solid #4f6e8d;
        border-radius: 4px;
        padding: 7px;
        background: #fff;

        .input-group {
          .form-control {
            border: 1px solid #ffffff00;
            height: 50px;
          }
          .referbtn {
            background: #0180ff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
            border-radius: 4px !important;
            color: #fff;
            border: none;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding: 0px 30px;
          }
        }
      }

      .termtext {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #565656;
        float: right;
        margin-top: 13px;
      }
    }

    @media screen and (max-width: 1600px) {
      padding-right: 9rem;
    }

    @media screen and (max-width: 1400px) {
      padding-right: 1.5rem;
      .row-wrapper-inner {
        padding: 20px 0px 0px 20px;
      }
    }
    @media screen and (max-width: 1024px) {
      padding-right: 1rem;
    }

    @media screen and (max-width: 768px) {
      .row-wrapper-reffer {
        padding: 20px;
        margin-bottom:40px;
      }
    }
  }
`;
