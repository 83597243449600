import styled from 'styled-components';


export const GettingStartedTitleStyle = styled.section`


 
    
    .gettingstarted-title-wapper{
        .gettingstarted-title{
            h2{
                font-family: 'Plus Jakarta Sans';
                font-weight: 700;
                font-size: 37px;
                line-height: 47px;
                color: #000000;
            }
            p{
                font-family: 'Plus Jakarta Sans';
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                color: #1E354D;
            }
        }
        .gettingstarted-button{
            .btn{
                font-family: 'Plus Jakarta Sans';
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: #FFFFFF;
                background: #0180FF;
                border-color: #0180FF;
                border-radius: 4px;

                :hover{
                    background-color: #FFDE26;
                    border-color: #FFDE26;
                    color: #000;
                }
            }
        }
    }



`;