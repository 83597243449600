import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import { Container } from "react-bootstrap";
import { TermsConditionsStyle } from './TermsConditionsStyle';

import PageHeading from '../../Components/common/PageHeading/PageHeading';
import PageContent from '../../Components/common/PageContent/PageContent';
import MetaTags from "../../Components/common/MetaTags";
import ExportApi from "../../Api/ExportApi";
import { loader } from "../../loader";


function TermsConditionsMainFN() {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        getTerms();
    }, []);

    const getTerms = () => {
        loader('show')
        ExportApi.termsCondition()
            .then((res: any) => {
                loader('hide')
                if (res?.data) {
                    setData(res?.data?.data);
                }
            })
            .catch((err) => {
                loader('hide')
                console.log(err)
            });
    };

    return (
        <TermsConditionsStyle>
            <MetaTags
                title={data[0]?.seo_title}
                description={data[0]?.meta_description}
                focusKeyphrase={data[0]?.focus_keyphrase}
                canonicalURL={data[0]?.canonical}
            />
            <div className='termsconditions-wapper pb-5'>
                <div className=" termsconditions-title  pt-4">
                    <PageHeading
                        pagetitle={`Terms & Conditions`}
                    />
                </div>

                <Container>
                    <PageContent pagecontent={data[0]?.content} />
                </Container>

            </div>
        </TermsConditionsStyle>
    );
}

export default TermsConditionsMainFN;
