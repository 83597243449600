import React from "react";
import { Row, Col, Card, Button, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { MyAuctionsListStyle, WithoutloginWonListStyle } from "./MyAuctionsListStyle";
import { toast } from "react-toastify";
import { SetDate, SetTime } from "../../../common/DateFormat/DateFormate";

const MyAuctionsList = (props) => {
  let Navigate = useNavigate();
  const { AuctionsData, type, withoutLoginData } = props;
  // const auction_id = props.AuctionsData[0]?.id
  return (
    <>
      {withoutLoginData ? (
        <WithoutloginWonListStyle>
          
          <div className="myauctions-list-wapper">
              {withoutLoginData?.map((data, key) => {   
                return (
                  <div className={"myauctions-item auctions-item-" + data.id} key={key} >
                    <Row>
                      <Col xs={12}  className="mb-2">
                        <Row>
                          <Col xs={6}>
                            <div className="myauctions-item-image">
                              <img
                                className="myauctions-img"
                                src={
                                  data.AuctionImages[0]?.image_url +
                                  data.AuctionImages[0]?.image[0]
                                }
                                loading="lazy"
                              />
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="myauctions-item-card">
                              <Row className="mid-section mt-3">
                                <Col
                                  xs={12}
                                  className="myauctions-title-wapper"
                                >
                                  <div className="myauctions-title">
                                    <p>{data.name}</p>
                                  </div>
                                  <div className="myauctions-disc">
                                    <span>{data.asset_name}</span>
                                  </div>
                                  <div className="myauctions-value">
                                    <span>
                                      Value: {data.currency}
                                      {data.asset_price}
                                    </span>
                                  </div>
                                </Col>
                                {type && (
                                  <Col className="myauctions-price-inner">
                                    <div className="info-heading">
                                      {data?.payment_status == 1 ||
                                      data?.payment_status !== null ? (
                                        ""
                                      ) : (
                                        <div className="myauctions-details-wapper-element">
                                          Payment Pending
                                        </div>
                                      )}
                                      <span>Winning Bid Price</span>
                                    </div>
                                    <div className="price">
                                      <strong>
                                        {data?.currency} &nbsp;
                                        {data?.winning_price}
                                      </strong>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )
              })}
          </div>
          
        </WithoutloginWonListStyle>
      ) : (
        <MyAuctionsListStyle>
          <div className="myauctions-list-wapper">
            {AuctionsData?.length > 0 ? (
              AuctionsData?.map((data, key) => {
                return (
                  <div
                    className={"myauctions-item auctions-item-" + data.id}
                    key={key}
                  >
                    <Row>
                      <Col xs={12} sm={12} md={7} lg={7} className="mb-2">
                        <Row>
                          <Col xs={6}>
                            <div className="myauctions-item-image">
                              <img
                                className="myauctions-img"
                                src={
                                  data.AuctionImages[0]?.image_url +
                                  data.AuctionImages[0]?.image[0]
                                }
                                loading="lazy"
                              />
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="myauctions-item-card">
                              <Row className="mid-section mt-3">
                                <Col
                                  xs={12}
                                  className="myauctions-title-wapper"
                                >
                                  <div className="myauctions-title">
                                    <p>{data.name}</p>
                                  </div>
                                  <div className="myauctions-disc">
                                    <span>{data.asset_name}</span>
                                  </div>
                                  <div className="myauctions-value">
                                    <span>
                                      Value: {data.currency}
                                      {data.asset_price}
                                    </span>
                                  </div>
                                </Col>
                                {type && (
                                  <Col className="myauctions-price-inner">
                                    <div className="info-heading">
                                      {data?.payment_status == 1 ? (
                                        ""
                                      ) : (
                                        <div className="myauctions-details-wapper-element">
                                          Payment Pending
                                        </div>
                                      )}
                                      <span>Winning Bid Price</span>
                                    </div>
                                    <div className="price">
                                      <strong>
                                        {data?.currency}  &nbsp;    
                                        {data?.winning_price}
                                      </strong>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5}>
                        <div className="myauctions-details-wapper">
                          <Card>
                            <div className="myauctions-details-title">
                              <h4>Auction Details</h4>
                              <hr />
                            </div>
                            <div className="myauctions-details-data">
                              <Table>
                                <tbody>
                                  {data?.no_of_participants && (
                                    <tr>
                                      <td>Number of Participants:</td>
                                      <td>{data?.no_of_participants}</td>
                                    </tr>
                                  )}
                                  {data?.items && (
                                    <tr>
                                      <td>No of Items:</td>
                                      <td>{data?.items}</td>
                                    </tr>
                                  )}
                                  {data.duration && (
                                    <tr>
                                      <td>Auction Duration :</td>
                                      <td>{data.duration}</td>
                                    </tr>
                                  )}
                                  {data?.participation_fees && (
                                    <tr>
                                      <td>Participation Fee :</td>
                                      <td>
                                        {data?.view_currency}{" "}
                                        {data.participation_fees}
                                      </td>
                                    </tr>
                                  )}
                                  {data.fee_charged && (
                                    <tr>
                                      <td>Fee Charged :</td>
                                      <td>
                                        {data?.view_currency} {data.fee_charged}
                                      </td>
                                    </tr>
                                  )}
                                  {data.participation_time && (
                                    <tr>
                                      <td>Participation Duration  :</td>
                                      <td>{data.participation_time}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                          <div className="myauctions-detail-section">
                              <div className="upcoming-auctions-buttom">
                              <Link to={`/details/${data.slug}`}>
                                <Button variant="light"
                                >
                                  View Details
                                </Button>
                              </Link>
                            </div>
                              {data?.payment_status == 0 && (
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    Navigate("/addfunds", {
                                      state: {
                                        amount: data.winning_price,
                                        auction_id: data.id,
                                        currency_name: data.currency_name,
                                        currencySymbol: data.view_currency,
                                        vat_price: data.vat_price,
                                        total_price: data.total_price,
                                      },
                                    })
                                  }
                                >
                                  Checkout
                                </Button>
                              )}
                                </div>
                         
 
                              
                            </div>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <h1 className="text-center"></h1>
            )}
          </div>
        </MyAuctionsListStyle>
      )}
    </>
  );
};

export default MyAuctionsList;
