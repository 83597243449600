import styled from "styled-components";

export const PackageStyle = styled.section`
  .package-section-wapper {
    .card {
      border-radius: 0px;
      img {
      }
      .card-img-overlay {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        background-color: #08080826;

        .package-content {
          width: 45%;
          float: left;
          text-align: left;

          .card-title {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 48px;
            margin-bottom: 20px;
            color:#ffc00d;
          }
          p{
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 400;
            font-size:18px;

          }
          button {
            border: 2px solid #ffff;
            border-radius: 4px;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 32px;
            color: #ffff;
            padding: 10px 35px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1550px) {
    .package-section-wapper {
      .card {
        border-radius: 0px;
        img {    
          height: 450px;
          object-fit: cover;

      }
    }
  }

  }
  @media screen and (max-width: 1350px) {
    .package-section-wapper {
      .card {
        border-radius: 0px;
        img {    
          height: 450px;
          object-fit: cover;
        }
        .card-img-overlay {

          .package-content {
            width: 55%;
            button {
                text-align:center;
            }
          }
        }
      }
    }


  }

  
  @media screen and (max-width: 991px) {
    .package-section-wapper {
      .card {
        border-radius: 0px;
        img {height: 450px;
        }
        .card-img-overlay {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: #08080826;

          .package-content {
            width: 55%;
            float: left;
            text-align: center;

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 800;
              font-size: 23px;
              line-height: 35px;
              margin-bottom: 0px;
            }
            button {
              border: 2px solid #fff;
              border-radius: 4px;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #fff;
              padding: 8px 32px;
              margin-top:20px;
            }
          }
        }
      }
    }


  }

  @media screen and (max-width: 767px) {
    .package-section-wapper {
      .card {
        border-radius: 0px;
        img {
          height: 365 px;
        }
        .card-img-overlay {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: #08080826;

          .package-content {
            width: 100%;
            float: right;
            text-align: center;
            padding-bottom: 10px;

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 800;
              font-size: 22px;
              line-height: 38px;
              text-align: center;
            }
            button {
              border: 2px solid #fff;
              border-radius: 4px;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #fff;
              padding: 8px 32px;
            }



          }
        }
      }
    }
  }
  @media screen and (max-width: 539px) {
    .package-section-wapper {
      .card {
        border-radius: 0px;
        img {
        }
        .card-img-overlay {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: #08080826;

          .package-content {
            width: 100%;
            float: right;
            text-align: center;
            padding-bottom: 10px;

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 800;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              margin-bottom: 10px;
            }
            button {
              border: 2px solid #fff;
              border-radius: 4px;
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 22px;
              text-align: center;
              color: #fff;
              padding: 5px 35px;
            }


          }
        }
      }
    }
  }
`;
