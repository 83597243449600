import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import PageHeading from "../../../common/PageHeading/PageHeading";
import { ChangePasswordMainStyle } from "./ChangePasswordMainStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import ExportApi from "../../../../Api/ExportApi";
import { loader } from "../../../../loader";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePasswordMain = () => {
  const [validated, setValidated] = useState(false);
  const [type, setType] = useState("Dictamen");
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newpassword: "",
      conpassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "Password must be 8 characters long")
        .required("Enter your password"),
      newpassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(20, "Password must be at most 20 characters")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      conpassword: Yup.string()
        .oneOf(
          [Yup.ref("newpassword"), null],
          "Confirm and new password should be same"
        )
        .required("Enter your confirm  password"),
    }),
    onSubmit: (values) => {
      loader("show");
      let timezone = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let data = {
        current_password: values.oldPassword,
        new_password: values.newpassword,
        confirm_password: values.conpassword,
        date_time: timezone,
      };
      ExportApi.ChangePassword(data)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.success == true) {
              toast.success(resp.data.message);
              // localStorage.removeItem("active")
              // Navigate("/login")
            } else {
              toast.error(resp.data.data.error);
            }
          }
        })
        .catch((err) => console.log(err));
      loader("hide");
    },
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // alert(1)
      form.checkValidity();
    } else {
      // alert(2)

      formik.handleSubmit();
    }
    setValidated(true);
  };

  const handleClick = (id) => {
    // 👇️ toggle isActive state on click
    if (id === 1) {
      setShowPass((current) => !current);
    } else {
      setShowPassConfirm((current) => !current);
    }
  };

  let Navigate = useNavigate();
  const CheckValidTokenn = () => {
    if (!localStorage.getItem("Token")) {
      localStorage.removeItem("Token");
      localStorage.removeItem("User_ID");
      window.dispatchEvent(new Event("EventLength"));
      Navigate("/login");
    }
  };
  useEffect(() => {
    CheckValidTokenn();
  }, []);
  return (
    <ChangePasswordMainStyle>
      <div className="editpagemain-page-title">
        <div className="pt-4">
          <PageHeading pagetitle={`Change Password`} />
        </div>
      </div>
      <div className="editpagemain-main mb-5">
        <div className="editpage-main-wapper">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="info-change-wapper">
              <Row className="mb-3">
                <Form.Group as={Col} md="12" lg="7">
                  <Form.Label>
                    Current Password <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="password"
                    name="oldPassword"
                    placeholder="************"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.oldPassword}
                    autoComplete="on"
                  />
                  {formik.touched.oldPassword && formik.errors.oldPassword ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.oldPassword}
                    </div>
                  ) : (
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="12" lg="7" className=" password">
                  <Form.Label>
                    New Password <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type={showPass ? "text" : "password"}
                    name="newpassword"
                    placeholder="************"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newpassword}
                    autoComplete="on"
                  />
                  <Icon
                    className="passshow"
                    onClick={() => handleClick(1)}
                    icon={
                      showPass ? "akar-icons:eye" : "akar-icons:eye-slashed"
                    }
                  />
                  {formik.touched.newpassword && formik.errors.newpassword ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.newpassword}
                    </div>
                  ) : (
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="12" lg="7" className=" password">
                  <Form.Label>
                    Confirm New Password <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type={showPassConfirm ? "text" : "password"}
                    name="conpassword"
                    placeholder="************"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conpassword}
                    autocomplete="on"
                  />
                  <Icon
                    className="passshow"
                    onClick={() => handleClick(2)}
                    icon={
                      showPassConfirm
                        ? "akar-icons:eye"
                        : "akar-icons:eye-slashed"
                    }
                  />
                  {formik.touched.conpassword && formik.errors.conpassword ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.conpassword}
                    </div>
                  ) : (
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              {/* <Index/> */}
            </div>
            <Button type="submit">Update Password</Button>
          </Form>
        </div>
      </div>
    </ChangePasswordMainStyle>
  );
};

export default ChangePasswordMain;
