import styled from 'styled-components';


export const TransactionHistoryStyle = styled.section`


    .transaction-history-page-title{
        background-color: #EDF9FE;
        .page-title-wapper{
            padding-right: 20rem;
            
            .page-title{
                h2{
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 34px;
                    color: #3D3838;
                }
            }
            .page-breadcrumb-wapper{
                .breadcrumb {
                    a{
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 30px;
                    }
                }
            }
            @media screen and (max-width: 1400px) {
                padding-right:4rem;}
        
        }
        @media screen and (max-width: 767px) {
            display:none;}
        }
   
    }
    .transaction-history-main{
        padding-right: 16rem;
        padding-left: 1rem;
        
        .transaction-history-history-wapper{
            .nav-tabs{
                border: none;
                .nav-item{
                    padding: 5px;
                    .nav-link{
                        border: 1px solid #0180FF;
                        height: 55px;
                        background-color: #F1F8FF;
                        border-radius: 4px;
                        ont-family: 'Plus Jakarta Sans';
                        font-weight: 700;
                        font-size: 17px;
                        line-height: 20px;
                        letter-spacing: -0.01em;
                        color: #000000;

                    }
                    .active{
                        background-color: #0180FF;
                        color: #FFFFFF;
                    }

                }
            }
        }
        @media screen and (max-width: 1600px) {
            padding-right: 9rem;

        }
       
        @media screen and (max-width: 1400px) {
            padding-right: 6rem;

        }
        @media screen and (max-width: 1024px) {
            padding-right: 1rem;

        }

    }

`;