import styled from "styled-components";

const color = [
  '#EC7B7B',
  '#FCCE71',
  '#17C2A9',
  '#6ECEFE',
];
const buttonColor = [
  '#FCCE71',
  '#17C2A9',
  '#6ECEFE',
  '#EC7B7B',
];

export const PackagesStyle = styled.section`
  .Packages-page-title {
    background-color: #edf9fe;
    .page-title-wapper {
      padding-right: 20rem;

      .page-title {
        h2 {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          line-height: 34px;
          color: #3d3838;
        }
      }
      .page-breadcrumb-wapper {
      .breadcrumb {
        a {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 30px;
        }
      }
      }
      @media screen and (max-width: 1400px) {
        padding-right:4rem;
      }       

      @media screen and (max-width: 767px) {
        display:none;
      }
        
    } 
      
  }

  .packages-wrapper {
    padding-right: 16rem;
    padding-left: 5rem;

    .package-wrapper-inner{

      .package-outer:nth-last-child(1){
  
  margin-bottom:70px;}

      .package-outer{
        padding:10px 30px 40px 30px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
        margin-right:2%;
        margin-top: 50px;
        width: 47%;



        .package-heading{
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          color: #FFFFFF;
        }

          .inner-sec{

            .left-sec {
              background: #F6F2E4;
              box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.12);
              border-radius: 10px 0px 0px 10px;
              z-index:10;
              display: flex;
              justify-content: center;
              align-items: center;

              .package-price{
                font-family: 'Plus Jakarta Sans';
                font-style: normal;
                font-weight: 200;
                font-size: 45px;
                color: #343A40;
                margin-bottom: 0px;
              }

              
            
            }

            .right-sec{
              background: #F7FBFF;
              box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.12);
              border-radius: 0px 10px 10px 0px;
              padding:17px 17px 17px 17px;

                .package-value{
                  font-family: 'Plus Jakarta Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 31px;
                  line-height: 39px;
                  text-align: center;
                  padding-bottom: 10px;
                  border-bottom: 1px solid #000;
                  color: #343A40;
                }
            
                .discount-value{
                  font-family: 'Plus Jakarta Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #343A40;
                }

            }

              .btnouter {
                display: flex;
                justify-content: center;

                button.buy-package-btn{
                  border: 1px solid #EC7B7B;
                  box-shadow: 0px 4px 20px rgba(0,0,0,0.18);
                  border-radius: 41px;
                  color: #fff;
                  width: 185px;
                  height: 47px;
                  font-family: 'Plus Jakarta Sans';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  margin-bottom: -65px;
                  margin-top: 30px;
                }

              }
        
          }

      }


    }





  
@media screen and (max-width: 1600px) {
  padding-right: 9rem;
}

@media screen and (max-width: 1400px) {
  padding-right: 6rem;

}

@media screen and (max-width: 1300px) {
  padding-right: 3rem;


  .package-wrapper-inner {

    .package-outer:nth-last-child(1){
  
            margin-bottom:50px;}

              .package-outer{
                width:100%;
                margin-right:0px;
                margin-bottom: 50px;
              }


            }

        
                

}


@media screen and (max-width: 576px) {
  .package-wrapper-inner {
              .package-outer{

                .inner-sec{
                  .left-sec{
                    border-radius: 10px 10px 0px 0px;
                  }
                  .right-sec{
                    border-radius: 0px 0px 10px 10px;
                  }
                }
              }

            }
}




}



















`;
