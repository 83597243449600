import React from "react";
import { useLocation } from "react-router-dom";
import AddFundsMainFN from "../../../Components/Dashboard/AddFunds/AddFundsMain";

import DashboardSideBarMain from "../../../Components/Dashboard/common/DashboardSideBar/DashboardSideBar";
import DashboardMainFN from "../../../Components/Dashboard/DashboardMain/DashboardMain";
import HelpSupportFN from "../../../Components/Dashboard/HelpSupport/HelpSupport";
import MyAuctionsFN from "../../../Components/Dashboard/MyAuctions/MyAuctions";
import NotificationsFN from "../../../Components/Dashboard/Notifications/Notifications";
import ChangePasswordMain from "../../../Components/Dashboard/Profile/ChangePassword/ChangePasswordMain";
import EditProfileMain from "../../../Components/Dashboard/Profile/EditProfile/EditProfileMain";
import TransactionHistoryFN from "../../../Components/Dashboard/TransactionHistory/TransactionHistory";
import { DashboardStyle } from "./DashboardMainStyle";
import PageTitle from "../../../Components/common/PageHeading/PageHeading";
import ReferEarnFN from "../../../Components/Dashboard/ReferEarn/ReferEarn";
import PackagesFN from "../../../Components/Dashboard/Packages/Packages";

const DashboardMain = () => {
  const location = useLocation();

  const Location = (location) => {
    const type = location.pathname;
    switch (type) {
      case "/dashboard":
        return <DashboardMainFN />;
      case "/myauctions":
        return <MyAuctionsFN />;
      case "/transactionhistory":
        return <TransactionHistoryFN />;
      case "/editprofile":
        return <EditProfileMain />;
      case "/notifications":
        return <NotificationsFN />;
      case "/changepassword":
        return <ChangePasswordMain />;
      case "/helpsupport":
        return <HelpSupportFN />;
      case "/addfunds":
        return <AddFundsMainFN />;
        case "/referEarn":
          return <ReferEarnFN />;
          case "/packages":
            return <PackagesFN />;
      default:
        break;
    }
  };

  return (
    <DashboardStyle>
      <div className="dashboard-mob-breadcrumbs pt-4">
      <PageTitle pagetitle={"Dashboard"} /> </div>
      <div className="dashboard-wapper">
        <div className="dashboard-siderbar-wapper">
          <DashboardSideBarMain />
        </div>
        <div className="dashboard-main-wapper">{Location(location)}</div>
      </div>
    </DashboardStyle>
  );
};

export default DashboardMain;
