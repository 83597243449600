import styled from 'styled-components';


export const LoginFormStyle = styled.section`

     .login-form-wapper{
          width: 55%;
          margin: 0 auto;

          .form-sidebar{
               background: #1E5CAC;
          }

          .login-form{
               .login-form-inner{
                    form{
                         label{
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 500;
                              font-size: 16px;
                              line-height: 20px;
                              color: #131313;
                              svg{
                                   color: #93999F;
                                   font-size: 22px;
                                   margin-right: 5px;
                              }
                         }
                         input.form-control{
                              height: 50px;
                              border-radius: 0px;
                              background-color: #EAEDF2;

                         }
                         .form-control{
                              .PhoneInputInput{
                                   box-shadow: none !important;
                                   border: none !important;
                                   outline: none;
                              }
                         }
                         .login-button{
                              height: 50px;
                              border-radius: 4px;
                              background-color: #0180FF;
                              border: 0px;
                              width: 100%;
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 17px;
                         }
                         .password{
                              label{
                                   display: flex;
                                   flex-direction: row;
                                   align-items: flex-end;
                                   svg{
                                        font-size: 20px;
                                   }
                              }
                              position: relative;
                              .passshow{
                                   position: absolute;
                                   right: 10px;
                                   top: 50px;
                              }
                         }
                         
                         .remember-forgot {
                              display: flex;
                              flex-direction: row;
                              justify-content: space-between;
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 500;
                              font-size: 15px;
                              line-height: 22px;
                              color: #252525;

                              a{
                                   text-decoration: none;
                                   font-family: 'Plus Jakarta Sans';
                                   font-style: normal;
                                   font-weight: 500;
                                   font-size: 16px;
                                   line-height: 20px;
                                   color: #131313;
                                   :hover{
                                        color: #000;
                                   }
                              }
                         }
                         .loginphone-button{
                              height: 50px;
                              border-radius: 4px;
                              background-color: #FFDE26;
                              color: #000;
                              border: 0px;
                              width: 100%;
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 15px;

                              svg{
                                   font-size: 25px;
                                   margin-right: 5px;
                              }
                         }
                         .divider {
                              position: relative;
                              display: flex;
                              flex-direction: column-reverse;
                              justify-content: space-evenly;
                              align-items: center;
                              color: #555555;
                              :before,
                              :after {
                                   content: "";
                                   position: absolute;
                                   height: 1px;
                                   width: 43%;
                                   background-color: #B6C4D1;
                              }
                              :after {
                                   left: 0;
                              }
                              :before {
                                   right: 0;
                              }
                         }
                         .login-signup{
                              font-family: 'Plus Jakarta Sans';
                              font-style: normal;
                              font-weight: 500;
                              font-size: 16px;
                              line-height: 20px;
                              text-align: center;
                              letter-spacing: 0.01em;
                              color: #000000;
                              a{
                                   text-decoration: none;
                                   :hover{
                                        color: #000;
                                   }
                                   svg{
                                        font-size: 22px;
                                   }
                              }
                         }
                    }
               }
          }
    
          @media screen and (max-width: 1200px){
               width: 100%;



          }
    
     }


`;