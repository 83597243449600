import React from "react";
import { FooterBottomStyle } from "./FooterBottomStyle";

import { Container } from "react-bootstrap";

function FooterBottom() {
  return (
    <FooterBottomStyle>
      <div className="footer-bottom-wapper">
        <Container>
          <div className="footer-bottom-content pt-3">
            {<p>
              Powered by <a href="https://www.oneworldtechnologies.com/">One World Technologies</a>
            </p> }
          </div>
        </Container>
      </div>
    </FooterBottomStyle>
  );
}

export default FooterBottom;
