
import styled from "styled-components";

export const JoinButtonstyle = styled.section`

.modal-content{
    width:65%;}
.modal-body{
    .outer-wrapper{
        .join-auction{

            button.btn.btn-primary.btn-filled.no-btn {
                width:100px;
                background-color:#dde7f2;
                margin-right:30px;
                border:none;
                color:#000;
            }
            button.btn.btn-primary.btn-filled.yes-btn {
                width:100px;
                margin-left:30px;
                border:none;
            }
        }
    }

}



`