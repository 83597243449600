import {Container, Row, Col} from 'react-bootstrap';
// import SectionTitle from '../SectionTitle/SectionTitle';
import {GettingStartedStyle} from './GettingStartedStyle';
import GettingStartedCard from './GettingStartedCard/GettingStartedCard';
import GettingStartedTitle from './GettingStartedTitle/GettingStartedTitle';



function GettingStarted() {
    return (
        <GettingStartedStyle>
            {localStorage.getItem("Token")?null:
            <section className='gettingstarted-main-wapper pt-5 pb-5'>
                <Container>
                    <div className='gettingstarted-main-inner'>
                        <Row> 
                            <Col className='gettingstarted-main-title' xs={12} md={12} lg={4}>
                                <GettingStartedTitle/>
                              
                            </Col>
                            <Col xs={12} md={12} lg={8}>
                                <GettingStartedCard/>
                            </Col>
                        </Row>
                    </div>               
                </Container>
            </section>
            }
        </GettingStartedStyle>
    );
}

export default GettingStarted;  