import React from "react";
import "./UpcomingAuctionsStyle.tsx";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import AuctionsMain from "../../Components/Auctions/AuctionsMain";
import PageTitle from "../../Components/common/PageHeading/PageHeading";
import { UpcomingAuctionsStyle } from "./UpcomingAuctionsStyle";
import MetaTags from "../../Components/common/MetaTags";
import { Container } from "react-bootstrap";

const UpcomingAuctionsMainFN = () => {
  return (
    <UpcomingAuctionsStyle>
      <MetaTags
              title={'Upcoming Auction Alert: Exclusive Deals and Discounts Await You!'}
              description={"Save the date for our exclusive upcoming auction at YOLOP! Discover unbeatable deals on top brands and luxury items. Register now for a thrilling bidding experience! "}
      focusKeyphrase={'Upcoming Auctions'}
    canonicalURL={'https://yolop.net/upcoming-auctions'}
/>
      <div className="upcomingAuctions-main-wapper">
        <PageTitle pagetitle={"Upcoming Auctions"} />
       
              {/*     <div className="yolop-upcoming-text">
            <Container>
              <h2>Explore the Excitement of Upcoming Auctions on Yolop.net </h2>

              <input type="checkbox" class="read-more-state" id="post-2" />

                <p class="read-more-wrap">
                  <p>Are you ready for the thrill of upcoming auctions? At Yolop, we are constantly curating an extraordinary lineup of auctions that will captivate your bidding spirit. Stay ahead of the curve and be one of the pioneering explorers to embark on the exhilarating journey of endless opportunities.</p>
 
                  <p class="read-more-target">
                  <p>At Yolop, our commitment lies in delivering unmatched online auction experiences. We take great pride in presenting a diverse range of upcoming auctions meticulously tailored to satisfy a wide array of interests and preferences. Whether you're seeking an Online Auctions UK platform or beyond, our carefully curated collection showcases the finest items sourced from around the globe. </p> 

                  <p>Keep an eye on our platform as we reveal an exciting lineup of upcoming auctions showcasing exclusive and highly coveted items. Whether you're passionate about fine art, collectibles, fashion, or tech gadgets, our upcoming auctions have something to pique your interest. Discover rare treasures, limited-edition releases, and extraordinary finds that will elevate your collection.
                  At Yolop, we prioritize transparency and trust. Our team takes great care in meticulously verifying the authenticity and quality of every item showcased in our upcoming auctions. Bid with complete confidence, knowing that each item has undergone rigorous vetting to meet our stringent standards of excellence.
                  </p>

                  <p>Prepare yourself for an immersive and exhilarating bidding experience on extraordinary items. Our intuitive platform offers seamless navigation through upcoming auctions, providing detailed item descriptions and real-time bid tracking. Stay connected and fully engaged with our auction alerts and notifications, ensuring that you never miss a chance to bid on your desired items.
                  </p> <br/>

                 <b>Upcoming Auctions:</b> Experience the thrill of upcoming auctions where you can find incredible deals and unique treasures. Stay up-to-date with the latest auctions taking place in your vicinity or engage in online auctions from anywhere across the globe. Our platform presents a carefully curated collection of forthcoming auctions, guaranteeing that you never miss out on the thrill and anticipation.<br/> <br/>


                 <b>Upcoming Auctions Near Me:</b> Looking for auctions happening near your location? Yolop.net provides a convenient way to find upcoming auctions in your vicinity. Discover local auctions and engage with a community of bidders who share your passion for finding extraordinary items.<br/> <br/>

                 <b>Upcoming Online Auctions:</b> Explore the convenience of upcoming online auctions. With just a few clicks, you can access a wide range of products and engage in thrilling bidding wars from the comfort of your own home. Stay connected to the latest online auctions and seize the opportunity to win remarkable items.<br/> <br/>

                 Discover a world of upcoming auctions right at your fingertips with Yolop.net. Whether you're looking for upcoming auctions in your vicinity or eager to explore the latest online auctions, we've got you covered. Our platform caters to all your auction needs with a diverse range of product categories to choose from.<br/> <br/>


                <h4><b>Product Categories:</b></h4> 
                 <b>Designer Bags:</b>Indulge in luxury with our exquisite collection of designer bags. Discover coveted brands and timeless styles that add a touch of elegance to any ensemble. Whether you're seeking a classic tote, a chic clutch, or a statement handbag, our upcoming auctions feature an array of designer bags to suit every taste.
                <br/> <br/>

                <b>Designer Jewellery and Fashion:</b>Elevate your fashion game with our selection of designer jewelry and fashion. Enhance your appearance with exquisite necklaces, earrings, bracelets, and rings meticulously crafted by acclaimed designers. Embark on a journey through the latest fashion trends and discover exclusive pieces that perfectly embody your distinct style.
                <br/> <br/>

                <b>Electronics:</b>Stay ahead of the technological curve with our range of cutting-edge electronics. From smartphones and tablets to home entertainment systems and smart devices, our upcoming auctions showcase top-quality electronics to enhance your digital lifestyle. Bid on the latest gadgets and upgrade your tech collection.
                <br/> <br/>

                <b>Cars:</b>Get ready to hit the road in style with our exceptional car auctions. Whether you're seeking a sleek sedan, a powerful SUV, or a classic vintage car, our upcoming auctions feature a diverse range of vehicles to suit different preferences. Bid confidently on reliable automobiles and fulfill your automotive dreams.
                <br/> <br/>

                <b>Vouchers (Groceries and Fuel):</b>Save big on everyday essentials with our vouchers for groceries and fuel. Bid on vouchers that offer discounts at leading supermarkets, allowing you to stock up on groceries while keeping your budget intact. Additionally, fuel vouchers provide an opportunity to save on your next refueling, making each trip to the pump more cost-effective.<br/>
                Don't miss out on the thrilling bidding action. Join Yolop.net today and be part of the excitement as you explore upcoming auctions, near and far, from the comfort of your own home. Start bidding now and secure the best deals on the products you love!

                <br/> <br/>

                Ensure you don't miss out on the chance to join our upcoming auctions. Register today to receive timely notifications about the latest auctions, secure your place, and get ready for an exceptional bidding adventure.Experience the excitement and anticipation as you anticipate the opening bids and get ready to make your mark at Yolop's upcoming auctions.

                </p>


                </p>
               

                
                <label for="post-2" class="read-more-trigger">
                </label>
               

                </Container>
                
              
            </div>     */ }
       
       
        <AuctionsMain />
      </div>
    </UpcomingAuctionsStyle>
  );
};

export default UpcomingAuctionsMainFN;
