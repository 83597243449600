import styled from 'styled-components';


export const MyAuctionsListStyle = styled.section`
    .myauctions-list-wapper{
        .myauctions-item{
            background: #FFFFFF;
            border: 1px solid #BABABA;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
            border-radius: 4px;
            padding: 10px;
            margin-bottom: 20px;

            .myauctions-item-image{
                .myauctions-img{
                    border-radius: 6px;
                    width: 100%;
                    height: 220px;
                    object-fit: contain;
                }
            }
            .myauctions-item-card{
                height: 100%;
                .mid-section{
                    display: flex;
                        flex-direction: row;
                        height: 100%;
                        align-content: space-between;
                    .myauctions-title-wapper{
                        .myauctions-title{
                            p{
                                font-family: 'Plus Jakarta Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 25px;
                                color: #000000;
                                margin-bottom: 0px;
                            }
                        }
                        .myauctions-disc{
                            span{
                                font-family: 'Plus Jakarta Sans';
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 19px;
                                color: #323131;
                            }
                        }
                        .myauctions-value{
                            span{
                                font-family: 'Plus Jakarta Sans';
                                font-weight: 700;
                                font-size: 15px;
                                line-height: 19px;
                                color: #323131; 
                            }
                        }
                    }
                    .myauctions-price-inner{
                        .info-heading {
                            text-transform: uppercase;
                            color: #323131;
                            font-weight: 500;
                            font-family: 'Plus Jakarta Sans';
                        }
                
                    
                        .price{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 31px;
                            line-height: 39px;
                            letter-spacing: -0.04em;
                            color: #0961B9;

                            @media screen and (max-width: 1024px) {
                                font-size: 22px;}
                            }
    
                        }

                    
                    }
                }
            }
            .myauctions-details-wapper{
                background-color: #F4F7F9;
                border-radius: 4px;
                padding: 15px 20px;

                

                .card {
                    border: none;
                    background-color: #F4F7F9;
                    .myauctions-details-title{
                        h4{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 700;
                            font-size: 17px;
                            line-height: 20px;
                            letter-spacing: -0.01em;
                            color: #0B3B71;
                            display: inline-block;
                        }
                        hr{
                            width: 8rem;
                            border: 1px solid #B7B9BB;
                            opacity: 1;
                            margin-top: 0px;
                        }

                    }
                    .myauctions-detail-section { 
                        display: inline-flex;
                        justify-content: flex-start;
                        width: 100%;
                        gap: 5%;
                        padding: 1%;

                        button.btn.btn-light {
                            border: 1px solid rgb(160, 160, 160);
                        }
                     }
                    .myauctions-details-data{
                        table{
                            tr{
                                border-color: transparent;
                                td{
                                    padding: 3px 0;
                                    color: #101010;
                                    font-family: 'Plus Jakarta Sans';
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 19px;
                                }
                            }
                        }
                    }
                }
            }
    }

`;


export const WithoutloginWonListStyle = styled.section`
    .myauctions-list-wapper{
        .myauctions-item{
            background: #FFFFFF;
            border: 1px solid #BABABA;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
            border-radius: 4px;
            padding: 20px;
            margin-bottom: 20px;
            width: 48%;
            float: left;
            margin-right: 20px;

            .myauctions-item-image{
                .myauctions-img{
                    border-radius: 6px;
                    width: 100%;
                    height: 220px;
                    object-fit: contain;
                }
            }
            .myauctions-item-card{
                height: 100%;
                .mid-section{
                    display: flex;
                        flex-direction: row;
                        height: 100%;
                        align-content: space-between;
                    .myauctions-title-wapper{
                        .myauctions-title{
                            p{
                                font-family: 'Plus Jakarta Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 25px;
                                color: #000000;
                                margin-bottom: 0px;
                            }
                        }
                        .myauctions-disc{
                            span{
                                font-family: 'Plus Jakarta Sans';
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 19px;
                                color: #323131;
                            }
                        }
                        .myauctions-value{
                            span{
                                font-family: 'Plus Jakarta Sans';
                                font-weight: 700;
                                font-size: 15px;
                                line-height: 19px;
                                color: #323131; 
                            }
                        }
                    }
                    .myauctions-price-inner{
                        .info-heading {
                            text-transform: uppercase;
                            color: #323131;
                            font-weight: 500;
                            font-family: 'Plus Jakarta Sans';
                        }
                
                    
                        .price{
                            font-family: 'Plus Jakarta Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 31px;
                            line-height: 39px;
                            letter-spacing: -0.04em;
                            color: #0961B9;

                            @media screen and (max-width: 1024px) {
                                font-size: 22px;}
                            }
    
                        }

                    
                    }
                }
            }
            .myauctions-details-wapper{
                background-color: #F4F7F9;
                border-radius: 4px;
                padding: 15px 20px;

                

                .card {
                    border: none;
                    background-color: #F4F7F9;
                    .myauctions-details-title{
                        h4{
                            font-family: 'Plus Jakarta Sans';
                            font-weight: 700;
                            font-size: 17px;
                            line-height: 20px;
                            letter-spacing: -0.01em;
                            color: #0B3B71;
                            display: inline-block;
                        }
                        hr{
                            width: 8rem;
                            border: 1px solid #B7B9BB;
                            opacity: 1;
                            margin-top: 0px;
                        }

                    }
                    .myauctions-details-data{
                        table{
                            tr{
                                border-color: transparent;
                                td{
                                    padding: 3px 0;
                                    color: #101010;
                                    font-family: 'Plus Jakarta Sans';
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 19px;
                                }
                            }
                        }

                       

                    }
                }
            }
   
   
            @media screen and (max-width: 1024px) {
                .myauctions-item{

            width: 100%;


            }
   
   
   
   
        }
    }
`;