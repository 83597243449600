import styled from "styled-components";

export const ForgotPasswordFormStyle = styled.section`
  .forgotpassword-main-wapper {
    width: 55%;
    margin: 0 auto;

    .form-sidebar {
    }

    .forgotpassword-form {
      .forgotpassword-form-inner {

        .forgetpasswrod-text{
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #403E3E;
          padding:0px 60px 20px 60px;
          text-align:center;
        }


        form {
          .phone-input {
            .input-color {
              background: #eaedf2;
              border: 1px solid #ced4da;
              height: 50px;
              .PhoneInput {
                background: transparent;
                border: none;
                .PhoneInputCountryIcon {
                  width: 40px;
                  height: auto;
                  border: none;
                }
                .PhoneInputInput {
                  pointer-events: none;
                  display: none;
                }
              }
              input {
                border: 0px;
              }
            }
          }
          label {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #131313;
            svg {
              color: #93999f;
              font-size: 22px;
              margin-right: 5px;
            }
          }
          input.form-control {
            height: 50px;
            border-radius: 0px;
            background-color: #eaedf2;
          }
          .form-control {
            .PhoneInputInput {
              box-shadow: none !important;
              border: none !important;
              outline: none;
            }
          }
          .login-button {
            height: 50px;
            border-radius: 4px;
            background-color: #0180ff;
            border: 0px;
            width: 100%;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
          }
          .password {
            label {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              svg {
                font-size: 20px;
              }
            }
            position: relative;
            .passshow {
              position: absolute;
              right: 10px;
              top: 50px;
            }
          }

          .remember-forgot {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #252525;

            a {
              text-decoration: none;
              color: #252525;
              :hover {
                color: #000;
              }
            }
          }
          .loginphone-button {
            height: 50px;
            border-radius: 4px;
            background-color: #ffde26;
            color: #000;
            border: 0px;
            width: 100%;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 15px;

            svg {
              font-size: 25px;
              margin-right: 5px;
            }
          }
          .divider {
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-evenly;
            align-items: center;
            color: #555555;
            :before,
            :after {
              content: "";
              position: absolute;
              height: 1px;
              width: 43%;
              background-color: #b6c4d1;
            }
            :after {
              left: 0;
            }
            :before {
              right: 0;
            }
          }
          .login-signup {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #000000;
            a {
              text-decoration: none;
              :hover {
                color: #000;
              }
              svg {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
                @media screen and (max-width: 1200px){
                  width: 100%;

                  .forgotpassword-form {
                    .forgotpassword-form-inner {
              
                      .forgetpasswrod-text{
                        padding:0px;
                      }

                    }
                  }
                  
              }
  
  
  
  
  }
`;
