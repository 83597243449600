import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HistoryTableStyle } from "./HistoryTableStyle";

const AuctionPurchasesHistory = (props) => {
  const transaction = props?.data;
  return (
    <>
      {transaction?.length <= 0  || !transaction ? (
         <h1 className="text-center">No Data Found</h1>
      ) : (
        <HistoryTableStyle>
          <div className="history-table-wapper">
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transaction?.map((i, index) => {
                  return (
                    <tr key={index}>
                      <td className="date">
                        {i?.date_time ? i?.date_time : i?.updated_at}
                      </td>
                      <td className="transaction-name">{i.name}</td>
                      <td className="id">{i?.transaction_id}</td>
                      <td className="amount">
                        <strong>
                          {i.currency}
                          {i?.total ? i?.total : i.auction_fees}
                        </strong>
                
                      </td>
                      {i.payment_status && (
                        <td className="action-wapper">
                          <span
                            className={
                              i.payment_status.toLowerCase() + " " + "status"
                            }
                          >
                            {i.payment_status === "succeeded"
                              ? "Success"
                              : i.payment_status === "Pending"
                              ? "Pending"
                              : i.payment_status === "processing"
                              ? "canceled"
                              : i.payment_status === "processing"
                              ? "Cancelled Payment"
                              : "Payment Failed"}
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </HistoryTableStyle>
      )}
    </>
  );
};

export default AuctionPurchasesHistory;
