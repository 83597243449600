import React from "react";
import { BlogCardStyle } from "./BlogCardStyle";
import { CardGroup, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const BlogCard = ({ BlogData }: any) => {
    return (
        <BlogCardStyle>
            <div className="blog-card-wapper">
                <CardGroup>
                    {BlogData?.map((data: any, key: any) => {
                        return (
                            <div
                                className={"blog-card-item blog-item-" + key.toString()}
                                key={key}
                            >
                                <Link to={`/blogs/${data.slug}`}>
                                    <Card>
                                        <Card.Img variant="top" src={data.image} />
                                        <Card.Body>
                                            <Card.Title>{data.title}</Card.Title>
                                            <Link to={`/blogs/${data.slug}`}>Learn More</Link>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        );
                    })}
                </CardGroup>
            </div>
        </BlogCardStyle>
    );
};
export default BlogCard;
