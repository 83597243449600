import styled from "styled-components";

export const HelpSupportStyle = styled.section`
  .helpsupport-page-title {
    background-color: #edf9fe;
    .page-title-wapper {
      padding-right: 20rem;

      .page-title {
        h2 {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          line-height: 34px;
          color: #3d3838;
        }
      }
      .page-breadcrumb-wapper {
        .breadcrumb {
          a {
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      @media screen and (max-width: 1400px) {
        padding-right:4rem;}
    }
    @media screen and (max-width: 767px) {
      display:none;
  }
 
 
  }
  .helpsupport-main {
    padding-right: 16rem;
    padding-left: 1.5rem;

    .helpsupport-history-wapper {
      background-color: #ffffff;
      text-align: center;

      img.support-img{
        max-width: 100%;
      }

      .accordion {
        .accordion-item {
          background-color: #ffffff;
          border: 1px solid #bbbbbb;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
          margin-bottom: 15px;
          .accordion-header {
            .accordion-button {
              background-color: #ffffff;
              font-family: "Plus Jakarta Sans";
              font-weight: 600;
              font-size: 17px;
              line-height: 28px;
              color: #023c7d;
            }
          }
          .accordion-collapse {
            .accordion-body {
              font-family: "Plus Jakarta Sans";
              font-weight: 400;
              font-size: 17px;
              line-height: 28px;
              color: #585353;
            }
          }
        }
      }
      .read {
        background-color: #f3f7fc;
      }
      .support-wapper {
        .support-wapper-inner {
          .card {
            background: linear-gradient(
              101.55deg,
              #0b4d8e 41.52%,
              #0862bc 85.02%
            );
            border-radius: 10px;
            border: none;
            padding: 4rem;
            .card-body {
              .card-title {
                font-family: "Plus Jakarta Sans";
                font-weight: 700;
                font-size: 30px;
                line-height: 38px;
                color: #ffffff;
              }
              .card-text {
                font-family: "Plus Jakarta Sans";
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
              }
              .content-button {
                background-color: #ffd821;
                font-family: "Plus Jakarta Sans";
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                padding: 15px 40px;
                text-decoration: none;
                display: block;
                width: 30%;
                margin: 0 auto;
                margin-top: 25px;
                border-radius: 5px;

                @media screen and (max-width: 1200px) {
                  width: 50%;
               }

                @media screen and (max-width: 768px) {
                  width: 100%;
                  padding: 15px 10px
               }
              
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1600px) {
      padding-right: 9rem;
  }
 
  @media screen and (max-width: 1400px) {
      padding-right: 6rem;

  }
  @media screen and (max-width: 1024px) {
      padding-right: 1rem;
  }
 
  }
`;
