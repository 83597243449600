import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/plus-jakarta-sans";
import ContactMain from "../../Components/Contact/ContactMain";
import MetaTags from "../../Components/common/MetaTags";

function ContactFN() {
  return (
    <div className='body-wapper'>
      <MetaTags
          title={'Contact Yolop - Get in Touch for Support, Inquiries, and Feedback'}
          description={"Contact Yolop for any support, inquiries, or feedback. Our team is here to assist you and provide prompt responses to your questions. Reach out to us through our contact form or find our contact details below."}
          focusKeyphrase={'Contact Yolop'}
              canonicalURL={'https://yolop.net/contact-us'}
        />
        <ContactMain />
    </div>
  );
}

export default ContactFN;
