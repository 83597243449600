import BannerSlider from "./BannerSlider/BannerSlider";
import UpcomingAuctions from "./UpcomingAuctions/UpcomingAuctions";
import GettingStarted from "./GettingStarted/GettingStarted";
import Blog from "./Blog/Blog";
import BiddingSection from "./Bidding/Bidding";
import WonAuction from "./WonAuction/WonAuction";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PackageSection from "./Package/Package";
import ExportApi from "../../Api/ExportApi";

// saveUserLogin
const HomeMain = () => {
  const [wonData, setWonData] = useState()
  const isLogin = useSelector((state) => state.APIData.Profiledata);
  const WonAuctionDetail = () => {
    ExportApi.WonAuctions(1, 2)
      .then((resp) => {
       setWonData(resp.data.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(()=> {
    WonAuctionDetail()
  }, [])


  return (
    <main className="main-wapper">
      <BannerSlider />
      {/* {(isLogin?.due_payment?.length > 0 || wonData ) &&  */}
      <WonAuction type={'homePage'} wonData={wonData} />
      
      <UpcomingAuctions />
      <GettingStarted />
      <PackageSection />
      <Blog />
      <BiddingSection />
    </main>
  );
};

export default HomeMain;
