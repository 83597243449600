import styled from 'styled-components';


export const ContactMainStyle = styled.section`

    .contact-main-wapper{
       background-color: #E7EAF0;
       
       .page-title-wapper{
        background:#E7EAF0;
       }

       .form-sidebar{
            padding: 0px;
       }
       .contact-form{
            background-color: #fff;
            padding: 40px;
        }
    }

`;  