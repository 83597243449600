import styled from "styled-components";

export const AuctionsCardStyle = styled.section`
  .auctions-card-wapper {
    .auctions-card-item {
      .card {
        border: none;
        .card-img-top {
          height: 263px;
          border-radius: 6px;
          object-fit: cover;
        }
        .card-body {
          padding-left: 0px;
          padding-right: 0px;
          .text-live{
              font-family: "Plus Jakarta Sans";
              font-weight: 600;
              color: #e90e00;
              margin-bottom: 4px;
              padding: 4px;
              text-align: center;
            }

          .title-wapper-left {

            .card-title {
              font-family: "Plus Jakarta Sans";
              font-weight: 600;
              font-size: 18px;
              color: #252525;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              min-height:42px;
            }
            .card-text {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              line-height: 13px;
              margin-bottom: 0px;
              color: #615f5f;
            }

          }
          .quantity-wapper-right {
            span {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 17px;
              color: #252525;
              text-align: right;
              display: block;
            }
          }
        }
        .card-footer {
          padding-left: 0px;
          padding-right: 0px;

          border-color: #adc3cd;
          background-color: transparent;
          .price-wapper {
            padding-right: 0px;
            .price-heading {
              p {
                font-family: "Plus Jakarta Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #373737;
                text-transform: uppercase;
                margin-bottom: 6px;
                margin-top: 10px;
              }
            }
            .price {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #565656;
            }
          }
          .time-wapper {
            text-align: right;
            .price-heading {
              p {
                font-family: "Plus Jakarta Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #373737;
                text-transform: uppercase;
                margin-bottom: 6px;
                margin-top: 10px;
              }
            }
            .auction-time p{
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #565656;
              margin-bottom: 4px;
            }
            .price {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 17px;
              line-height: 20px;
              color: #e90e00;
            }
          }
        }
        .button-wapper {
          button {
            :focus {
              box-shadow: none;
            }
          }
          .joinnow {
            width: 100%;
            height: 43px;
            background: #0180ff;
            border-radius: 4px;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            :hover {
              background-color: #ffde26;
              border-color: #ffde26;
              color: #000;
            }
          }
          .viewdetails {
            border: 1px solid #a0a0a0;
            border-radius: 4px;
            width: 100%;
            height: 43px;
            background: transparent;
            font-family: "Plus Jakarta Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
          }
        }
      }
    }
  }
`;
