import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { CheckoutFormStyle } from "./AddFundsFormStyle";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { savejoinAuction } from "../../../../Redux/Slices/TransactionSlice";
import moment from "moment";
import { useDispatch } from "react-redux";
import ExportApi from "../../../../Api/ExportApi";

export default function CheckoutForm({ joinAuctionId, currency, coupon_code }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentid, setPaymentId] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }
  //   const clientSecret = paymentid;

  //   if (!clientSecret) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     switch (paymentIntent.status) {
  //       case "succeeded":
  //         localStorage.removeItem("LastFillAmmount");
  //         localStorage.removeItem("StripeKey");
  //         toast.success("Payment succeeded", { toastId: "payment_succeeded" });
  //         break;
  //       case "processing":
  //         localStorage.setItem("StripeKey", "");
  //         localStorage.setItem("LastFillAmmount", "");
  //         toast.info("Your payment is processing", { toastId: "processing" });
  //         break;
  //       case "requires_payment_method":
  //         localStorage.setItem("StripeKey", "");
  //         localStorage.setItem("LastFillAmmount", "");
  //         toast.warn("Your payment was not successful", {
  //           toastId: "requires_payment_method",
  //         });
  //         break;
  //       case "fraudulent":
  //         localStorage.setItem("StripeKey", "");
  //         localStorage.setItem("LastFillAmmount", "");
  //         toast.warn("Your card has been declined.", { toastId: "fraudulent" });
  //         break;
  //       default:
  //         localStorage.setItem("StripeKey", "");
  //         localStorage.setItem("LastFillAmmount", "");
  //         toast.error("Something went wrong", { toastId: "payment_default" });
  //         break;
  //     }
  //   });
  // }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });
    if (result.error) {
      toast.warning(result.error.message);
    } else {
      const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (joinAuctionId) {
        ExportApi.JoinAuction(joinAuctionId, date, coupon_code)
          .then((resp) => {
            if (resp.data.success) {
              toast.success("Joined Succesfully");
              localStorage.setItem("Auctionid", "");
              dispatch(savejoinAuction(resp));
            } else {
              toast.error(resp.data.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch(savejoinAuction(null));
          });
      }
      {
        joinAuctionId ? navigate("/myauctions") : navigate("/dashboard");
      }
      // setPaymentId(result.paymentIntent.id);
      toast.success(`Payment Succeeded: ${result.paymentIntent.id}`);
      localStorage.removeItem("LastFillAmmount");
      localStorage.removeItem("StripeKey");
      setPaymentId(true);
    }
    setIsLoading(false);
  };

  return (
    <CheckoutFormStyle>
      <div className="payment-form-wapper mt-5">
        <form id="payment-form" onSubmit={handleSubmit}>
          {localStorage.getItem("StripeKey") ? (
            <PaymentElement id="payment-element" />
          ) : null}

          {localStorage.getItem("StripeKey") ? (
            <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                <span>
                  Pay ({currency}
                  {localStorage.getItem("LastFillAmmount")})
                </span>
              </span>
            </button>
          ) : null}
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </CheckoutFormStyle>
  );
}
