import React from "react";
import { FooterMainStyle } from "./FooterMainStyle";
import FooterTop from "./FooterTop/FooterTop";
import FooterBottom from "./FooterBottom/FooterBottom";

import { Container } from "react-bootstrap";

function FooterMain() {
  return (
    <FooterMainStyle>
      {/* <div className='footer-border-wapper'>
        <Container>
          <div className="footer-border"></div>
        </Container>
      </div> */}
      <footer className="footer-main-wapper">
        <Container>
          <FooterTop />
          <FooterBottom />
        </Container>
      </footer>
    </FooterMainStyle>
  );
}

export default FooterMain;
